import React, { useContext } from "react";
import PropTypes from "prop-types";
import UserPrefsContext from "../../../../context/UserPrefs/UserPrefsContext";

import { FormatAsInt } from "../../../../utils/Helpers/Format";
import Range from "../../../../components/shared/InputElements/Range";
import { useIntl } from 'react-intl';

const descriptionFn = (val) => FormatAsInt(val).toString() + " mpg";

const SlideEquivalentMilesPerGallon = ({
  id = "equivalent-miles-per-gallon",
  label,
  description = descriptionFn,
  ...rest
}) => {
  const intl = useIntl();
  const userPrefs = useContext(UserPrefsContext);
  let val = userPrefs.get("equivalentGasVehicleRateComparison")
    ? userPrefs.get("equivalentGasVehicleRateComparison")
    : userPrefs.get("equivalentMilesPerGallon");

  return (
    <Range
      id={id}
      value={val}
      label={intl.formatMessage
       ? intl.formatMessage({ id: "conventionalVehicleEfficiency", defaultMessage: "Conventional Vehicle Efficiency (mpg)" })
       : "Conventional Vehicle Efficiency (mpg)"}
      rangeMin={5}
      rangeMax={40}
      rangeStep={1}
      description={description}
      tooltip={
       intl.formatMessage({ id: "conventionalVehicleEfficiencyTooltip", defaultMessage: "To calculate possible savings, enter the miles per gallon (MPG) of the gasoline vehicle that you want to replace with an all-electric or Plug-In Hybrid EV." })
      }
      handler={(e) =>
        userPrefs.set({ equivalentMilesPerGallon: e.target.value })
      }
      {...rest}
    />
  );
};

export default SlideEquivalentMilesPerGallon;

SlideEquivalentMilesPerGallon.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  description: PropTypes.func,
  tooltip: PropTypes.string,
};
