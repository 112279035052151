import React, { useContext } from "react";
import PropTypes from "prop-types";
import UserPrefsContext from "../../../context/UserPrefs/UserPrefsContext";

import { FormatAsThousands } from "../../../utils/Helpers/Format";

import Range from "../../shared/InputElements/Range";
import { useIntl } from "react-intl";

const SlideMilesDrivenAnnually = ({
  id = "miles-driven-annually-range",
  noTooltip,
  addAverage,
  ...rest
}) => {
  const intl = useIntl();
  const userPrefs = useContext(UserPrefsContext);
  let label = `${
       process.env.REACT_APP_METRIC_SYSTEM
       ? intl.formatMessage({
              id: "ev.kilometersDrivenAnnually",
              defaultMessage: "Kilometers Driven Per Year",
            })
          : intl.formatMessage({
              id: "ev.milesDrivenAnnually",
              defaultMessage: "MILES DRIVEN PER YEAR",
       })
    }`;


  const description = (val) => {
    return `${FormatAsThousands(val)} ${
      process.env.REACT_APP_METRIC_SYSTEM
        ? intl.formatMessage({
            id: "vehicle.kilometers",
            defaultMessage: "kilometers",
          })
        : intl.formatMessage({ id: "vehicle.miles", defaultMessage: "miles" })
    }`;
  };

  intl.formatMessage({ id: "milesDrivenAnnually_tooltip", defaultMessage: "Based on the Department of Transportation's estimates of the average annual miles driven in 2020, a typical driver logs about 16,000 miles per year." })

  return (
    <Range
      id={id}
      value={userPrefs.get("milesDrivenAnnually")}
      label={label}
      rangeMin={1000}
      rangeMax={100000}
      rangeStep={1000}
      description={description}
      tooltip={
        noTooltip
          ? ""
          :   intl.formatMessage({ id: "milesDrivenAnnually_tooltip", defaultMessage: "Based on the Department of Transportation's estimates of the average annual miles driven in 2020, a typical driver logs about 16,000 miles per year." })
      }
      handler={(e) => userPrefs.set({ milesDrivenAnnually: e.target.value })}
      {...rest}
    />
  );
};

export default SlideMilesDrivenAnnually;

SlideMilesDrivenAnnually.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  description: PropTypes.func,
};
