import React from "react";
import PropTypes from "prop-types";
import DealerTabbedMaps from "../../components/DealerTabbedMaps/DealerTabbedMaps";
import useMapTabs from "../../hooks/useMapTabs";

const DealerMapPage = ({
  userLocation,
  ip,
  uuid,
  history,
  dealerLocations,
  tabId,
  title,
  zipcode,
}) => {
  const { activeTabId, toggle, findTab } = useMapTabs(tabId);

  const toggleWithHistory = (newTabId) => {
    const newTab = findTab(newTabId);
    history.push(newTab.url);
    toggle(newTab.id);
  };

  return (
    <section>
      <h1 className="hide-offscreen">Map</h1>

      <DealerTabbedMaps
        toggle={toggleWithHistory}
        activeTabId={activeTabId}
        userLocation={userLocation}
        dealerLocations={dealerLocations}
        zipcode={zipcode}
      />
    </section>
  );
};

export default DealerMapPage;

DealerMapPage.propTypes = {
  userLocation: PropTypes.object,
  ip: PropTypes.string,
  uuid: PropTypes.string,
};
