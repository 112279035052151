import React from "react";
import PropTypes from "prop-types";
import ToolTip from "../ToolTip/ToolTip";
import "./Select.scss";

// TODO : consider refactoring to a bootstrap dropdown. Make sure to test for ADA first.

const Select = React.forwardRef((props, ref) => {
  const {
    id,
    value,
    handler,
    optionNames,
    optionValues,
    label,
    description,
    disabled,
    tooltip,
    ariaControls,
    hasSupportingInfoBelow,
    isLarge,
    optionDataValues,
    isSpecial,
    ariaLabel,
  } = props;

  const idAria = id + "-help";

  return (
    <div
      className="form-group"
      style={{
        marginTop: "8px",
        marginBottom: hasSupportingInfoBelow ? "4px" : "1rem",
      }}
    >
      {label && (
        <label
          htmlFor={id}
          id={idAria}
          className={
            isLarge ? "col-form-label-lg label-select" : "label-select"
          }
        >
          {label}
          {tooltip && <ToolTip message={tooltip} id={id + "_tooltip"} />}
        </label>
      )}
      <div className="input-group">
        <select
          id={id}
          value={value}
          disabled={disabled}
          className={[
            "form-control",
            isLarge ? "large" : "",
            isSpecial ? "form-control-special" : "",
          ].join(" ")}
          aria-describedby={idAria}
          aria-label={ariaLabel}
          aria-controls={ariaControls}
          onChange={(e) => handler(e)}
        >
          {}
          {optionNames.map((option, i) => {
            return (
              <option
                key={i}
                value={optionValues ? optionValues[i] : option}
                data={optionDataValues ? optionDataValues[i] : ""}
              >
                {option}
              </option>
            );
          })}
        </select>
      </div>
      <small id={idAria} className="form-text text-muted">
        {description}
      </small>
    </div>
  );
});

export default Select;

Select.propTypes = {
  id: PropTypes.string,
  value: PropTypes.string,
  handler: PropTypes.func,
  optionNames: PropTypes.array,
  optionValues: PropTypes.array,
  description: PropTypes.string,
  disabled: PropTypes.bool,
  tooltip: PropTypes.string,
  isLarge: PropTypes.bool,
  ariaControls: PropTypes.string,
  hasSupportingInfoBelow: PropTypes.bool,
  label: PropTypes.string,
  optionDataValues: PropTypes.array,
};
