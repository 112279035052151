import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";

import InputWorkingZipcodeForChargingMap from "../InputComponents/InputZipcode/InputZipcodeForChargingMap";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";

import "./LocationMap.scss";
import { FormattedMessage } from "react-intl";
import useChargingStations from "../../hooks/useChargingStations";
import filterWithinBounds from "../../utils/Helpers/filterWithinBounds";
import BaseGoogleMapReact from "./BaseGoogleMapReact/BaseGoogleMapReact";
import useMappedClusters from "../../hooks/useMappedClusters";
import ChargingStationsMapMarker from "./ChargingStationsMapMarker/ChargingStationsMapMarker";
import ChargingStationClusterMarker from "./ChargingStationClusterMarker/ChargingStationClusterMarker";
import isSuperChargingStation from "../../utils/predicates/isSuperChargingStation";
import useSelectedStation from "../../hooks/useSelectedStation";
import MapControlPanel from "./MapControlPanel/MapControlPanel";
import SelectedChargingStationCard from "../LocationMap/SelectedChargingStationCard/SelectedChargingStationCard"

const ChargingMap = ({
  userLocation,
  ignoreSuperchargerStations,
  isVisible = true,
  hideList,
}) => {
  const [bounds, setBounds] = useState({
    nw: undefined,
    ne: undefined,
    sw: undefined,
    se: undefined,
  });
  const [center, setCenter] = useState();
  const [zoom, setZoom] = useState(10);
  const mapRef = useRef();

  useEffect(() => {
    if (!userLocation) return;
    setCenter({
      lat: parseFloat(userLocation.latitude),
      lng: parseFloat(userLocation.longitude),
    });
  }, [userLocation]);
  const { chargingStations, fetchChargingStations } = useChargingStations();
  const [selectedStation, selectStation] =
    useSelectedStation(chargingStations);
  const visibleChargingStations = filterWithinBounds(chargingStations, bounds);
  const filteredChargingStations = ignoreSuperchargerStations
    ? visibleChargingStations.filter(
        (station) => !isSuperChargingStation(station)
      )
    : visibleChargingStations;

  const { clusteredPoints, individualPoints, supercluster } = useMappedClusters(
    {
      data: filteredChargingStations,
      bounds,
      zoom,
    }
  );

  const onMapChange = ({
    center: newCenter,
    bounds: newBounds,
    zoom: newZoom,
  }) => {
    setBounds(newBounds);
    setZoom(newZoom);
    const { lat: latitude, lng: longitude } = newCenter;
    fetchChargingStations({ latitude, longitude }, newBounds);
  };

  const saveMapRefs = ({ map }) => {
    mapRef.current = map;
  };


  return (
    <div className="ChargingMap">
      <div className="row hide-offscreen">
        <div className="col-sm-12 text-center">
          <h2>
            <FormattedMessage
              id="chargingStations"
              defaultMessage="Charging Stations"
              description="Charging Stations"
            />
          </h2>
        </div>
      </div>
      <div className={hideList ? "row charger-background " : "row padding"}>
        <div className="col-sm-3">
          <MapControlPanel
            chargingStations={filteredChargingStations}
          >
            <InputWorkingZipcodeForChargingMap />
          </MapControlPanel>
        </div>
        <div className="col-sm-9">
          {!userLocation && (
            <div className="spinner-container">
              <LoadingSpinner />
            </div>
          )}
          {userLocation && (
            <div className="map-container">
              <div className="sidebar-container">
                <SelectedChargingStationCard station={selectedStation} />
              </div>
              {isVisible && (
                <BaseGoogleMapReact
                  center={center}
                  zoom={zoom}
                  onChange={onMapChange}
                  onGoogleApiLoaded={saveMapRefs}
                >
                  {individualPoints.map((point) => (
                    <ChargingStationsMapMarker
                      key={point.properties.data.id}
                      station={point.properties.data}
                      lat={point.geometry.coordinates[1]}
                      lng={point.geometry.coordinates[0]}
                      onMouseOver={() => {
                        selectStation(point.properties.data.id);
                      }}
                      onClick={() => selectStation(point.properties.data.id)}
                    />
                  ))}
                  {clusteredPoints.map((cluster) => (
                    <ChargingStationClusterMarker
                      key={cluster.id}
                      cluster={cluster}
                      supercluster={supercluster}
                      map={mapRef.current}
                      lat={cluster.geometry.coordinates[1]}
                      lng={cluster.geometry.coordinates[0]}
                    />
                  ))}
                </BaseGoogleMapReact>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ChargingMap;

ChargingMap.propTypes = {
  userLocation: PropTypes.object,
  ignoreSuperchargerStations: PropTypes.bool,
};
