import React from "react";

import homeIcon1 from "../../../assets/images/homeIcon1.png";
import homeIcon2 from "../../../assets/images/homeIcon2.png";
import homeIcon3 from "../../../assets/images/homeIcon3.png";
import homeIcon4 from "../../../assets/images/homeIcon4.png";
import homeIcon5 from "../../../assets/images/car-icon.png";
import { FormattedMessage } from "react-intl";

const HomepageCards = () => {
  let cards = [
       {
              id:"card-1",
              src: homeIcon5,
              label: (
                <FormattedMessage
                  id="header.electricVehicles.v2"
                  defaultMessage="Explore EVs"
                  description="Electric Vehicles Header"
                />
              ),
              link: "/vehicles",
              extraClass: "ev-vehicles",
            },
    {
       id:"card-2",
      src: homeIcon1,
      label: (
        <FormattedMessage
          id="header.cards.incentives"
          defaultMessage="Search Incentives"
          description="Search Incentives"
        />
      ),
      link: "/incentives",
      extraClass: "first",
    },
    {
       id:"card-3",
      src: homeIcon2,
      label: (
        <FormattedMessage
          id="header.cards.electricians"
          defaultMessage="Find Certified Electricians"
          description="Find Certified Electricians"
        />
      ),
      link: "/electricians",
      extraClass: "",
    },
    {
       id:"card-4",
      src: homeIcon3,
      label: (
        <FormattedMessage
          id="header.cards.home-chargers"
          defaultMessage="Shop Home Chargers"
          description="Shop Home Chargers"
        />
      ),
      link: "/home-chargers",
      extraClass: "fourth",
    },
    {
       id:"card-5",
      src: homeIcon4,
      label: (
        <FormattedMessage
          id="header.cards.rates"
          defaultMessage="View Savings Calculator"
          description="View Savings Calculator"
        />
      ),
      link: "/savingscalculator",
      extraClass: "last",
    },
  ];

  return (
    <section id="HomepageCards">
      <div className="container">
        {cards.map((card) => {
          return (
            <a key={card.id} href={card.link} className={`card-link ${card.extraClass}`}>
              <img alt={`Homepage card icon ${card.link}`} src={card.src} />
              <p>{card.label}</p>
            </a>
          );
        })}
      </div>
    </section>
  );
};

export default HomepageCards;
