import React, { useContext } from "react";
import UserPrefsContext from "../../../../context/UserPrefs/UserPrefsContext";
import Range from "../../../../components/shared/InputElements/Range";
import { useIntl } from 'react-intl';

const SlideCurrentMonthlyBill = (id) => {
  const intl = useIntl();
  const userPrefs = useContext(UserPrefsContext);
  return (
    <Range
      id={id.id}
      value={userPrefs.get("currentMonthlyBill")}
      handler={(e) => userPrefs.set({ currentMonthlyBill: e.target.value })}
      label={intl.formatMessage
       ? intl.formatMessage({ id: "averageMonthlyCharge", defaultMessage: "AVERAGE MONTHLY CHARGE" })
       : "Yes"}
      rangeMin={10}
      rangeMax={600}
      rangeStep={10}
      description={(val) => `$${val} / ` + intl.formatMessage({ id: "monthWord", defaultMessage: "month" })}
      tooltip={
       intl.formatMessage({ id: "averageMonthlyCharge_tool", defaultMessage: "Select the amount of total electric charges (not including gas charges) on a typical monthly bill, regardless of season. We use this amount to estimate a baseline for your current energy consumption." })
      }
    />
  );
};

export default SlideCurrentMonthlyBill;
