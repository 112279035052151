import React from "react";
import PropTypes from "prop-types";
import ZipcodeErrorState from "./ZipcodeErrorState";
import IconZip from "../../../client_customizations/assets/images/icons/icon-zip.png";
import { ValidateFiveDigitCode } from "../../../utils/Helpers/PostalCodeValidator";
import { FormattedMessage } from "react-intl";

import "./ControlledInputZipcode.scss";

const ControlledInputZipcode = ({
  zipcode,
  setZipcode,
  handleSubmit,
  workingZipcode,
  id,
  isUpdating,
  isNotFound,
  showButtonBelow,
  showButtonInline,
  hideValidateRequest,
  isLarge,
}) => {
  const zipcodeIsValid = ValidateFiveDigitCode(workingZipcode);
  const zipcodeIsSynced = workingZipcode === zipcode;
  const isInvalid =
    !isUpdating && (!zipcodeIsValid || (zipcodeIsSynced && isNotFound));
  const idAria = id + "-help";
  const showButton = showButtonBelow || showButtonInline;

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleSubmit();
    }
  };

  let inputClassName = isUpdating
    ? "form-control"
    : `form-control${isInvalid ? " is-invalid" : ""}${
        zipcodeIsSynced && !isNotFound ? " is-valid" : ""
      }`;

  inputClassName = isLarge ? inputClassName + " isLarge" : inputClassName;

  const renderButton = (
    <button
      type="button"
      className="btn btn-default mb-2"
      onClick={setZipcode}
      disabled={isUpdating}
      id="update-zipcode-button"
    >
       <FormattedMessage
              id="updateZipcode"
              defaultMessage="Update Zipcode"
              description="Update Zipcode"
       />
    </button>
  );

  const renderError = (
    <ZipcodeErrorState
      zipcode={zipcode}
      workingZipcode={workingZipcode}
      isValid={zipcodeIsValid}
      isSynced={zipcodeIsSynced}
      isUpdating={isUpdating}
      isNotFound={isNotFound}
      hideValidateRequest={hideValidateRequest}
    />
  );

  return (
    <>
      <div className={showButtonInline ? "form-inline" : ""}>
        {!showButtonInline && (
          <label style={{ display: "flex" }} htmlFor={id} id={idAria}>
              <FormattedMessage
                     id="zipCode"
                     defaultMessage="ZIP Code"
                     description="Zip Code"
              />
          </label>
        )}
        <div
          className={`input-group mb-2${showButtonInline ? " mr-sm-2" : ""}`}
        >
          <div className="input-group-prepend">
            <div className="input-group-text" style={{ borderRadius: "0" }}>
              <img src={IconZip} alt="" style={{ width: 15 }} />
            </div>
          </div>
          <input
            id={id}
            className={inputClassName}
            aria-describedby={idAria}
            value={workingZipcode}
            disabled={isUpdating}
            onChange={setZipcode}
            onBlur={setZipcode}
            onKeyDown={(e) => handleKeyDown(e)}
          />
        </div>
        {(showButtonBelow || !showButtonInline) && renderError}
        {showButton && renderButton}
      </div>
      {showButtonInline && renderError}
    </>
  );
};

export default ControlledInputZipcode;

ControlledInputZipcode.propTypes = {
  zipcode: PropTypes.string,
  setZipcode: PropTypes.func,
  handleSubmit: PropTypes.func,
  workingZipcode: PropTypes.string,
  id: PropTypes.string,
  isUpdating: PropTypes.bool,
  isNotFound: PropTypes.bool,
  showButtonBelow: PropTypes.bool,
  showButtonInline: PropTypes.bool,
  hideValidateRequest: PropTypes.bool,
};
