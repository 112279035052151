import calcResaleFactor from "./calcResaleFactor"

const calcResidualValue = (lifetimeMiles, vehicle, purchaseMethod) => {
  const resaleFactor = calcResaleFactor(purchaseMethod, vehicle)
  const logNumber = Math.log(resaleFactor)
  const depreciationCoefficient = logNumber / 36000;

  return Math.pow(Math.E, depreciationCoefficient * lifetimeMiles);
};

export default calcResidualValue;
