export const coastal = [
  32108, 82656, 82677, 91910, 91911, 91912, 91932, 91950, 92007, 92008, 92009,
  92010, 92011, 92014, 92024, 92032, 92037, 92049, 92054, 92055, 92056, 92057,
  92058, 92067, 92068, 92075, 92081, 92083, 92084, 92085, 92091, 92092, 92093,
  92101, 92102, 92103, 92104, 92106, 92107, 92108, 92109, 92110, 92111, 92112,
  92113, 92116, 92117, 92118, 92121, 92122, 92123, 92126, 92129, 92130, 92132,
  92133, 92134, 92135, 92136, 92138, 92147, 92152, 92154, 92155, 92161, 92173,
  92624, 92625, 92629, 92649, 92651, 92653, 92656, 92672, 92673, 92674, 92675,
  92676, 92677, 92679, 92688, 92690, 92691, 92692, 92693, 92694, 92697,
];

export const mountain = [
  91905, 91906, 91916, 91931, 91948, 91962, 91963, 91980, 92036, 92060, 92066,
  92070, 92086, 92125, 92536,
];

export const desert = [91934, 92004];

export const inland = [
  69000, 77777, 91901, 91902, 91913, 91914, 91915, 91917, 91935, 91941, 91942,
  91945, 91970, 91977, 91978, 92003, 92019, 92020, 92021, 92023, 92025, 92026,
  92027, 92028, 92029, 92040, 92041, 92045, 92050, 92059, 92061, 92062, 92064,
  92065, 92069, 92071, 92072, 92078, 92079, 92082, 92096, 92105, 92114, 92115,
  92119, 92120, 92124, 92127, 92128, 92131, 92139, 92145, 92150, 92158, 92179,
  92182, 92199, 92701, 99000,
];
