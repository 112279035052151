import React from "react";
import PropTypes from "prop-types";

import ChargingPinHigh from "./../../../client_customizations/assets/images/icons/High Power Stations.svg";
import ChargingPinPublic from "./../../../client_customizations/assets/images/icons/Public Stations.svg";
import ChargingPinPrivate from "./../../../client_customizations/assets/images/icons/Other Types of Stations.svg";

import "./ChargingStationsMapLegend.scss";
import { FormattedMessage } from "react-intl";

const ChargingStationsMapLegend = ({ chargingStations }) => {
  return (
    <div className="ChargingStationsMapLegend">
      <div className="charger-type">
        <img src={ChargingPinPublic} alt="" />
        <b>
          <FormattedMessage
            id="evs.public.legend"
            defaultMessage="Low Power Stations (Level 2)"
            description="Low Power Stations (Level 2)"
          />
        </b>
      </div>

      <div className="charger-type">
        <img src={ChargingPinHigh} alt="" />
        <b>
          <FormattedMessage
            id="evs.highPower.legend"
            defaultMessage="High Power Stations (DCFC)"
            description="High Power Stations (DCFC)"
          />
        </b>
      </div>
      <div className="charger-type">
        <img src={ChargingPinPrivate} alt="" />
        <b>
          <FormattedMessage
            id="evs.other.legend"
            defaultMessage="Other Types of Stations"
            description="Other Types of Stations"
          />
        </b>
      </div>
    </div>
  );
};

ChargingStationsMapLegend.propTypes = {
  chargingStations: PropTypes.array,
};

ChargingStationsMapLegend.defaultProps = {
  chargingStations: [],
};

export default ChargingStationsMapLegend;
