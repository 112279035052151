import React from "react";
import PropTypes from "prop-types";
import "../../../pages/UsedEVs/usedEVs.scss";

import UsedEVCatalog from "../../../components/UsedEVCatalog/UsedEVCatalog";
import MatchScoreOptions from "../MatchScoreOptions/MatchScoreOptions";
import EvSortControls from "../../../components/EvSortControls/EvSortControls";
import NewUsedEVFilter from "../../components/NewUsedEVFilter/NewUsedEVFilter";
import ModalComponent from "../../../components/ModalUIComponent/ModalUIComponent";

import { FormattedMessage, useIntl } from "react-intl";

const UsedEVs = ({ usedElectricVehicles, ip, uuid, incentives, location }) => {
  const intl = useIntl();

  const time = new Date().toLocaleString();
  let size = window.innerWidth > 600;
  const renderOptions = (
    <>
      {size ? (
        <div id="VehicleTypeFilter" style={{ display: "inline" }}>
          <NewUsedEVFilter value={"used"} />
        </div>
      ) : null}
      <MatchScoreOptions location={location} incentives={incentives} />
      <br />
    </>
  );

  return (
    <section className="container UsedEVsPage" id="EVs">
      <div className="row mb-3">
        <div className="col-sm-12 text-center">
          <h1 className="page-title">
            <FormattedMessage
              id="header.usedVehicles"
              defaultMessage="Used Electric Vehicles"
              description="Used Electric Vehicles"
            />
          </h1>
          <p className="lead subtitle">
            {size ? (
              <FormattedMessage
                id="evs.welcomeSub"
                defaultMessage="Compare electric cars by EV range, price, or your personalized Match Score. {lineBreak} Click on the EV for more details, including total cost compared to a similar gas vehicle."
                description="Description of Colton Recharged"
                values={{
                  lineBreak: <br />,
                }}
              />
            ) : (
              <FormattedMessage
                id="evs.welcomeSubMobile"
                defaultMessage="Compare electric cars by EV range, price, or your personalized Match Score. Click on the EV for more details, including total cost compared to a similar gas vehicle."
                description="Description of Colton Recharged"
              />
            )}
          </p>
        </div>
      </div>
      <div className="row">
        <div className="col">
          {size ? null : (
            <div style={{ display: "inline" }}>
              <NewUsedEVFilter value={"used"} />
            </div>
          )}
          <div className="pull-right">
            <EvSortControls />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12 d-block d-lg-none text-center">
          <ModalComponent
            buttonText={
              intl.formatMessage
                ? intl.formatMessage({
                    id: "evs.buttonMatchScoreAndFilters",
                    defaultMessage: "Match Score and Filters",
                  })
                : "Match Score and Filters"
            }
            titleText={
              intl.formatMessage
                ? intl.formatMessage({
                    id: "evs.matchScoreAndFilters",
                    defaultMessage: "Refine Match Score and Filters",
                  })
                : "Refine Match Score and Filters"
            }
          >
            {renderOptions}
          </ModalComponent>
          <br />
        </div>
        <div className="col-xl-3 col-lg-4 d-none d-lg-block">
          {renderOptions}
        </div>
        <div
          id="electric-vehicles-catalog"
          aria-live="polite"
          aria-atomic="true"
          role="region"
          style={{ position: "absolute", top: "-9999px" }}
        >
          <span>
            <FormattedMessage
              id="results.updated"
              defaultMessage="Results have been updated as of "
              description="Results have been updated as of "
            />
            {time}.
          </span>
        </div>
        <div
          className="col-xl-9 col-lg-8 col-sm-12"
          style={{ paddingLeft: 10 }}
        >
          <UsedEVCatalog
            vehicles={usedElectricVehicles}
            hasEvDetails
            hasIncentivesAndMatchScore
          />
        </div>
      </div>
    </section>
  );
};

export default UsedEVs;

UsedEVs.propTypes = {
  electricVehicles: PropTypes.array,
  ip: PropTypes.string,
  uuid: PropTypes.string,
  incentives: PropTypes.array,
  location: PropTypes.object,
};
