import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
// import UserPrefsContext from "../../context/UserPrefs/UserPrefsContext";
import EVIncentives from "../../components/EVIncentives/EVIncentives";
import EVJumbotron from "../../components/EVJumbotron/EVJumbotron";
import ChargingMap from "../../components/LocationMap/ChargingMap";
import CostOfOwnership from "../../components/CostOfOwnership/CostOfOwnership";
// import getPaymentDetails from "../../functions/vehicle/getPaymentDetails";

import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import isPHEV from "../../functions/vehicle/isPHEV";
// import { FormatCarName } from "../../utils/Helpers/Format";
// import { set } from "react-ga";

const UsedEV = ({
  match,
  electricVehicles,
  userLocation,
  ip,
  uuid
}) => {
  // const userPrefs = useContext(UserPrefsContext);
  const [usedEv, setUsedEv] = useState(null)
  const [usedEvs, setUsedEvs] = useState(null)

  let car

  useEffect(() => {

    const setElectricVehicles = () => {
      if (electricVehicles) {
      const evId = match ? match.params["evId"].split(":") : "";
      const make = evId[0].replace(/_/g, ' ')
      const model = evId[1].replace(/_/g, ' ')
      const trim = evId[2].replace(/_/g, ' ')
  
      const allUsedEvs = electricVehicles.filter(ev => {
        return ev.make === make && ev.model === model && ev.trim === trim
      })
      setUsedEv(allUsedEvs[0])
      setUsedEvs(allUsedEvs)

      document.title = make
      ? `Used Vehicle: ${make} ${model}`
      : "";
      }
    } 
    setElectricVehicles()
  }, [electricVehicles, match]);
  
  const ignoreSuperchargerStations = car && isPHEV(car);

  const renderEV = usedEv ? (
    <>
      <EVJumbotron
        car={usedEv}
        incentives={usedEv.incentives}
        cars={usedEvs}
        setUsedEv={setUsedEv}
      />
      <CostOfOwnership
        cars={[usedEv, usedEv.equivalent_gas_vehicle]}
        usedEv={true}
      />
      <EVIncentives incentives={usedEv.incentives} car={usedEv} usedEv={true} />
      <section className="container-fluid charging-map">
        <section className="container">
          <ChargingMap
            userLocation={userLocation}
            ignoreSuperchargerStations={ignoreSuperchargerStations}
            hideList={true}
          />
        </section>
      </section>
    </>
  ) : (
    <div className="container text-center">
      <LoadingSpinner />
    </div>
  );

  return (
    <>
     { renderEV }
    </>
  );
};

export default UsedEV;

UsedEV.propTypes = {
  match: PropTypes.object,
  electricVehicles: PropTypes.array,
  userLocation: PropTypes.object,
  ip: PropTypes.string,
  uuid: PropTypes.string
};
