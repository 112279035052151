import React, { useState } from "react";
import PropTypes from "prop-types";
import { FormattedMessage, useIntl } from "react-intl";
import downArrowBlack from "../../client_customizations/assets/images/Down_Arrow_Black.svg"
import downArrowBlue from "../../client_customizations/assets/images/Down_Arrow_Blue.svg";

const ShowHideButton = ({
  children,
  buttonText,
  displayChildrenOnlyOnTablet,
  displayChildrenOnlyOnMobile,
  displayAlertOnlyOnMobile,
  extraClasses,
  style1 = false,
  style2 = false
}) => {
  const [showChildren, setShowChildren] = useState(false);

  const toggleButton = () => {
    setShowChildren(!showChildren);
  };
  const intl = useIntl();

  let renderChildren = showChildren ? children : null;
  let renderButtonText = showChildren
    ? intl.formatMessage
      ? intl.formatMessage({ id: "graph.hide", defaultMessage: "Hide " }) +
        buttonText
      : "Hide " + buttonText
    : intl.formatMessage
    ? intl.formatMessage({ id: "graph.show", defaultMessage: "Show " }) +
      buttonText
    : "Show " + buttonText;

  let button;
  if (style1) {
    button = (
      <button
        type="button"
        className="btn-ae-custom-three"
        onClick={toggleButton}
      >
        {renderButtonText}
        <img alt="downArrowBlue" src={downArrowBlue} className="left-margin" />
      </button>
    );
  } else if (style2) {
      button = (
        <button type="button" className="btn-ae-custom-four" onClick={toggleButton}>
          <img alt="downArrowBlack" src={downArrowBlack} className="right-margin"/>
          {renderButtonText}
        </button>
      );
  } else {
    button = (
      <button
        type="button"
        className="btn-ae-custom"
        onClick={toggleButton}
      >
        {renderButtonText}
      </button>
    );
  }

  let desktopClasses =
    "show-full-list-container d-md-block" + extraClasses;
  let mobileClasses = "d-block d-md-none";
  if (displayChildrenOnlyOnTablet) {
    desktopClasses = "show-full-list-container d-none d-lg-block";
    mobileClasses = "d-block d-lg-none";
  }

  return displayChildrenOnlyOnTablet ||
    displayChildrenOnlyOnMobile ||
    displayAlertOnlyOnMobile ? (
    <>
      <div className={desktopClasses}>
        {button}
        {renderChildren}
      </div>
      <div className={mobileClasses}>
        {displayChildrenOnlyOnMobile && children}
        {displayChildrenOnlyOnTablet && children}
        {displayAlertOnlyOnMobile && (
          <div className="input-well">
            <p>
              <FormattedMessage
                id="ev.carDetails.mobile"
                defaultMessage="For more details including charts {amp} calculations, please view this page on a desktop."
                description="Car Details Mobile subtitle"
                values={{
                  amp: "&",
                }}
              />
            </p>
          </div>
        )}
      </div>
    </>
  ) : (
    <div className="show-full-list-container">
      {button}
      {renderChildren}
    </div>
  );
};

export default ShowHideButton;

ShowHideButton.propTypes = {
  children: PropTypes.node.isRequired,
  buttonText: PropTypes.string,
  displayChildrenOnlyOnTablet: PropTypes.bool,
  displayChildrenOnlyOnMobile: PropTypes.bool,
  displayAlertOnlyOnMobile: PropTypes.bool,
};
