const ValidateFiveDigitCode = postcode => {
    let codePattern =   /^\d{5}$/ ;
    return codePattern.test(postcode);
};
const ValidateNineDigitCode = postcode => {
    let codePattern = /^\d{ 5}$|^\d{ 5 } -\d{ 4 } $ /;
    return codePattern.test(postcode);
};
const ValidateCAPostCode = postcode => {
    let codePattern = /^[ABCEGHJKLMNPRSTVXY]{1}[0-9]{1}[ABCEGHJKLMNPRSTVWXYZ]{1} ?[0-9]{1}[ABCEGHJKLMNPRSTVWXYZ]{1}[0-9]{1}$/i;
    return codePattern.test(postcode);
};

export{
    ValidateFiveDigitCode,
    ValidateNineDigitCode,
    ValidateCAPostCode
};
