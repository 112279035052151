import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import UserPrefsContext from "../../../../context/UserPrefs/UserPrefsContext";
import Select from "../../../../components/shared/InputElements/Select";
import { useIntl } from "react-intl";

const SelectVehicleYearFilter = ({
  id = "select-vehicle-age-filter",
  vehicles,
  makeOption,
  modelOption,
  trimOption,
  ...rest
}) => {
  const intl = useIntl();
  const userPrefs = useContext(UserPrefsContext);
  const label = intl.formatMessage
    ? intl.formatMessage({ id: "model.year", defaultMessage: "Model Year" })
    : "Model Year";
  const [modelYear, setModelYear] = useState();

  useEffect(() => {
    let filteredVehicles = vehicles;
    if (makeOption && modelOption) {
      filteredVehicles = filteredVehicles.filter(
        (vehicle) =>
          vehicle.make === makeOption && vehicle.model === modelOption
      );
    }
    if (trimOption && filteredVehicles.length > 0) {
      filteredVehicles = filteredVehicles.filter(
        (vehicle) => vehicle.trim === trimOption
      );
    }
    if (filteredVehicles.length > 0) {
      setModelYear(filteredVehicles[0].model_year);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [makeOption, modelOption, trimOption]);

  const setVehicleYear = (e) => {
    setModelYear(e.target.value);
    let vehicleHandleForIncentives = "";
    if (!makeOption) {
      vehicles = vehicles.filter((vehicle) => {
        return (
          vehicle.model === modelOption &&
          vehicle.model_year === parseInt(e.target.value)
        );
      });
      vehicleHandleForIncentives = vehicles[0].handle;
    } else {
      vehicles = vehicles.filter((vehicle) => {
        return (
          vehicle.model === modelOption &&
          vehicle.make === makeOption &&
          vehicle.model_year === parseInt(e.target.value)
        );
      });
      vehicleHandleForIncentives = vehicles[0].handle;
    }
    userPrefs.set({
      vehicleModelFilter: modelOption,
      vehicleYearFilter: e.target.value,
      vehicleHandleForIncentives: vehicleHandleForIncentives,
    });
  };

  const vehicleYearOptions = (
    vehicles,
    makeOption,
    modelOption,
    trimOption
  ) => {
    if (modelOption && makeOption) {
      let filteredVehicles = vehicles.filter((vehicle) => {
        return (
          vehicle.make === makeOption &&
          vehicle.model === modelOption &&
          (!trimOption || vehicle.trim === trimOption)
        );
      });
      if (filteredVehicles.length === 0) return [];
      const years = filteredVehicles.map((vehicle) => vehicle.model_year);
      const uniqueYears = [...new Set(years)].sort((a, b) => a - b);
      return uniqueYears.map((year) => year.toString());
    }
    return [];
  };

  const options = vehicleYearOptions(vehicles, makeOption, modelOption);

  return (
    <Select
      id={id}
      isLarge={true}
      isSpecial={true}
      value={modelYear}
      label={label}
      optionNames={options}
      optionValues={options}
      handler={setVehicleYear}
    />
  );
};

export default SelectVehicleYearFilter;

SelectVehicleYearFilter.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
};
