const DRSES = (territory) => {
  return {
    name: "DR-SES",
    title: "DR-SES",
    rateElements: [
      {
        name: "Energy Charges",
        rateElementType: "EnergyTimeOfUse",
        rateComponents: [
          {
            charge: 0.49617,
            months: [0, 1, 2, 3, 4, 10, 11],
            hourStarts: [16, 17, 18, 19, 20],
            daysOfWeek: [0, 1, 2, 3, 4, 5, 6],
            name: "Winter On Peak",
          },
          {
            charge: 0.43243,
            months: [0, 1, 4, 10, 11],
            hourStarts: [6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 21, 22, 23],
            daysOfWeek: [1, 2, 3, 4, 5],
            name: "Winter Off Peak Weekdays",
          },
          {
            charge: 0.43243,
            months: [2, 3],
            hourStarts: [6, 7, 8, 9, 14, 15, 21, 22, 23],
            daysOfWeek: [1, 2, 3, 4, 5],
            name: "Winter Off Peak Weekdays Part 2",
          },
          {
            charge: 0.43243,
            months: [0, 1, 2, 3, 4, 10, 11],
            hourStarts: [14, 15, 21, 22, 23],
            daysOfWeek: [0, 6],
            name: "Winter Off Peak Weekend",
          },
          {
            charge: 0.35432,
            months: [0, 1, 4, 10, 11],
            hourStarts: [0, 1, 2, 3, 4, 5],
            daysOfWeek: [1, 2, 3, 4, 5],
            name: "Winter Super Off Peak Weekdays",
          },
          {
            charge: 0.35432,
            months: [2, 3],
            hourStarts: [0, 1, 2, 3, 4, 5, 10, 11, 12, 13],
            daysOfWeek: [1, 2, 3, 4, 5],
            name: "Winter Super Off Peak Weekdays Part 2",
          },
          {
            charge: 0.35432,
            months: [0, 1, 2, 3, 4, 10, 11],
            hourStarts: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13],
            daysOfWeek: [0, 6],
            name: "Winter Super Off Peak Weekend",
          },
          {
            charge: 0.80097,
            months: [5, 6, 7, 8, 9],
            hourStarts: [16, 17, 18, 19, 20],
            daysOfWeek: [0, 1, 2, 3, 4, 5, 6],
            name: "Summer On Peak",
          },
          {
            charge: 0.46597,
            months: [5, 6, 7, 8, 9],
            hourStarts: [6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 21, 22, 23],
            daysOfWeek: [1, 2, 3, 4, 5],
            name: "Summer Off Peak Weekdays",
          },
          {
            charge: 0.46597,
            months: [5, 6, 7, 8, 9],
            hourStarts: [14, 15, 21, 22, 23],
            daysOfWeek: [0, 6],
            name: "Summer Off Peak Weekend",
          },
          {
            charge: 0.36263,
            months: [5, 6, 7, 8, 9],
            hourStarts: [0, 1, 2, 3, 4, 5],
            daysOfWeek: [1, 2, 3, 4, 5],
            name: "Summer Super Off Peak Weekdays",
          },
          {
            charge: 0.36263,
            months: [5, 6, 7, 8, 9],
            hourStarts: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13],
            daysOfWeek: [0, 6],
            name: "Summer Super Off Peak Weekend",
          },
        ],
      },
    ],
  };
};

export default DRSES;
