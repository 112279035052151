import React from "react";
import PropTypes from "prop-types";
import ElectricianTabbedMaps from "../../components/ElectricianTabbedMaps/ElectricianTabbedMaps";
import useMapTabs from "../../hooks/useMapTabs";

const ElectricianMapPage = ({
  userLocation,
  ip,
  uuid,
  history,
  dealerLocations,
  tabId,
  title,
  zipcode,
}) => {
  const { activeTabId, toggle, findTab } = useMapTabs(tabId);

  const toggleWithHistory = (newTabId) => {
    const newTab = findTab(newTabId);
    history.push(newTab.url);
    toggle(newTab.id);
  };

  return (
    <section>
      <h1 className="hide-offscreen">Map</h1>

      <ElectricianTabbedMaps
        toggle={toggleWithHistory}
        activeTabId={activeTabId}
        userLocation={userLocation}
        dealerLocations={dealerLocations}
        zipcode={zipcode}
      />
    </section>
  );
};

export default ElectricianMapPage;

ElectricianMapPage.propTypes = {
  userLocation: PropTypes.object,
  ip: PropTypes.string,
  uuid: PropTypes.string,
};
