import React from "react";
import PropTypes from "prop-types";
import { TabContent, TabPane } from "reactstrap";

import { ELECTRICIANS_TAB_ID } from "../../constants/mapTabs";
import ElectriciansMap from "../LocationMap/ElectriciansMap";

import "./ElectricianTabbedMaps.scss";
import { FormattedMessage } from "react-intl";

const TabbedMaps = ({ activeTabId, userLocation, zipcode }) => {
  return (
    <div className="ElectricianTabbedMaps">
      <div className="container">
        <div className="titleContainer">
          <p className="electricianTitle">
            <FormattedMessage
              id="electricians.title"
              defaultMessage="Certified Electricians"
              description="Certified Electricians"
            />
          </p>
          <p className="electricianSubtitle">
            <FormattedMessage
              id="electricians.subtitle"
              defaultMessage="Enter your zip code to find certified electricians in your area. Click on each result to view location and contact information."
              description="Enter your zip code to find certified electricians in your area. Click on each result to view location and contact information."
            />
          </p>
        </div>

        <div className="tabbed-maps-panel">
          <TabContent activeTab={activeTabId}>
            <TabPane tabId={ELECTRICIANS_TAB_ID}>
              <ElectriciansMap
                userLocation={userLocation}
                zipcode={zipcode}
                isVisible={activeTabId === ELECTRICIANS_TAB_ID}
              />
            </TabPane>
          </TabContent>
        </div>
        <p className="electricianCaption">
          <FormattedMessage
            id="electricians.map.caption"
            defaultMessage="Are you a certified EV charging station installer in the San Diego area? Please contact {email} to be considered for inclusion in our database of certified electricians. The electricians listed on this site are not endorsed, sponsored or employed by SDG&E."
            description="Are you a certified EV charging station installer in the San Diego area? Please contact {email} to be considered for inclusion in our database of certified electricians. The electricians listed on this site are not endorsed, sponsored or employed by SDG&E."
            values={{
              email: (
                <a href="mailto:qualifiedelectriciansdatabase@jdpa.com">
                  qualifiedelectriciansdatabase@jdpa.com
                </a>
              ),
            }}
          />
        </p>
        {/* WE MAY ENABLE THIS SECTION BACK SO KEEP THIS UNTIL WE HAVE A FINAL DECISION FROM THE CLIENT */}
        {/* <div className="map-header-title">
          
        </div>
        <ul className="map-header">
          <li className="left wide">
            <FormattedMessage
              id="electricians.map.value"
              defaultMessage="Value: We offer competitive installation prices."
              description="Value: We offer competitive installation prices."
              values={{
                title: <strong> Value:</strong>,
              }}
            />
          </li>
          <li className="left wide">
            <FormattedMessage
              id="electricians.map.personalizedExperience"
              defaultMessage="Personalized Experience: Our EV Specialists can help guide you through the entire process."
              description="Personalized Experience: Our EV Specialists can help guide you through the entire process."
              values={{
                title: <strong> Personalized Experience:</strong>,
              }}
            />
          </li>
          <li className="left wide">
            <FormattedMessage
              id="electricians.worldClass"
              defaultMessage="World Class and/or Hometown Service: We partner with experienced electricians who are vetted to ensure a safe and professional installation."
              description="World Class and/or Hometown Service: We partner with experienced electricians who are vetted to ensure a safe and professional installation."
              values={{
                title: <strong> World Class and/or Hometown Service:</strong>,
              }}
            />
          </li>
          <li className="left wide">
            <FormattedMessage
              id="electricians.fastEfficient"
              defaultMessage="Fast and Efficient: Our install timelines are secured and expedited to help get you up and running sooner."
              description="Fast and Efficient: Our install timelines are secured and expedited to help get you up and running sooner."
              values={{
                title: <strong> Fast and Efficient:</strong>,
              }}
            />
          </li>
        </ul>
        <table>
          <tr className="">
            <th className="left">
              <FormattedMessage
                id="Preferredpartner"
                defaultMessage="Preferred Partner"
                description="Preferred Partner"
              />
            </th>
            <th>
              <FormattedMessage
                id="CountiesCovered"
                defaultMessage="Counties Covered"
                description="Counties Covered"
              />
            </th>
            <th>
              <FormattedMessage
                id="ContactInformation"
                defaultMessage="Contact Information"
                description="Contact Information"
              />
            </th>
            <th className="right">
              <FormattedMessage
                id="Website"
                defaultMessage="Website"
                description="Website"
              />
            </th>
          </tr>
          <tr className="white">
            <td>State Electric Company</td>
            <td>All Counties</td>
            <td>
              <a href="tel: +1-248-807-8878">248-807-8878</a>
            </td>
            <td>
              {size ? (
                <a href="http://www.stateelectriccompany.net">
                  http://www.stateelectriccompany.net
                </a>
              ) : (
                <a href="http://www.stateelectriccompany.net">link</a>
              )}
            </td>
          </tr>
          <tr class="">
            <td>Myarc Charging Solutions</td>
            <td>All Counties</td>
            <td>
              <a href="tel: +1-734-620-7301">734-620-7301</a>
            </td>
            <td>
              {size ? (
                <a href="http://www.myarccharging.com">www.myarccharging.com</a>
              ) : (
                <a href="http://www.myarccharging.com">link</a>
              )}
            </td>
          </tr>
          <tr className="white">
            <td>Income Power</td>
            <td>All Counties</td>
            <td>
              <a href="tel: +1-248-592-3232">248-592-3232</a>
            </td>
            <td>
              {size ? (
                <a href="http://www.incomepowerllc.com/">
                  www.incomepowerllc.com/
                </a>
              ) : (
                <a href="http://www.incomepowerllc.com/">link</a>
              )}
            </td>
          </tr>
          <tr className="">
            <td>McWire Electric</td>
            <td>Livingston, Macomb, Oakland, St. Clair, Wayne</td>
            <td>
              <a href="tel: +1-586-563-3539">586-563-3539</a>
            </td>
            <td>
              {size ? (
                <a href="https://www.mcwireelectric.com/">
                  www.mcwireelectric.com/
                </a>
              ) : (
                <a href="https://www.mcwireelectric.com/">link</a>
              )}
            </td>
          </tr>
        </table> */}
      </div>
    </div>
  );
};

TabbedMaps.propTypes = {
  chargingStationsFilterFn: PropTypes.func,
  electricVehicles: PropTypes.array,
  oems: PropTypes.array,
  activeTabId: PropTypes.string,
  toggle: PropTypes.func,
  canIgnoreLowPowerStations: PropTypes.bool,
};

export default TabbedMaps;
