import React, { useState, useContext } from "react";
import { FormattedMessage } from 'react-intl';
import { NavLink } from "react-router-dom";
import ControlledInputZipcode from "../../../../components/InputComponents/InputZipcode/ControlledInputZipcode";
import getUserPref from "../../../../context/UserPrefs/getUserPref";
import UserPrefsContext from "../../../../context/UserPrefs/UserPrefsContext";
import { ValidateFiveDigitCode } from "../../../../utils/Helpers/PostalCodeValidator";

const HomepageZipCode = () => {
       const userPrefs = useContext(UserPrefsContext);
       const [workingZipcode, setWorkingZipcode] = useState(
              userPrefs.get("zipcode")
       );

       const handleFormSubmit = () => {
              if (ValidateFiveDigitCode(workingZipcode)) {
                     userPrefs.syncWorkingZipcode();
              }
       };

       return (
              <section id="HomepageZipCode">
                     <div className="container">
                            <div className="zipcode-content">
                                   <h2><FormattedMessage
                                          id="homepage.zipcode.title"
                                          defaultMessage="Did you know that we offer a variety of electric pricing plans? Find the best EV pricing plan for you."
                                          description="Did you know that we offer a variety of electric pricing plans? Find the best EV pricing plan for you."
                                   /></h2>
                                   <p><FormattedMessage
                                          id="homepage.zipcode.subtitle"
                                          defaultMessage="This tool will clearly identify the lowest cost EV pricing plan."
                                          description="This tool will clearly identify the lowest cost EV pricing plan."
                                   /></p>
                            </div>
                            <div className="zipcode-form-container">
                                   <ControlledInputZipcode
                                          isLarge
                                          zipcode={getUserPref("zipcode")}
                                          handleSubmit={(e) => {
                                          handleFormSubmit();
                                          }}
                                          workingZipcode={workingZipcode}
                                          setZipcode={(e) => {
                                          setWorkingZipcode(e.target.value);
                                          if (ValidateFiveDigitCode(e.target.value)) {
                                                 userPrefs.set({ workingZipcode: e.target.value });
                                          }
                                          }}
                                          id="input-zipcode-for-rate-comp-launch"
                                          isUpdating={userPrefs.zipcodeIsUpdating}
                                          isNotFound={userPrefs.zipcodeIsNotFound}
                                          hideValidateRequest
                                   />
                                   <NavLink
                                          to="/savingscalculator"
                                          onClick={() => handleFormSubmit()}
                                          className="zipcode-form-button"
                                   >
                                          <FormattedMessage
                                                 id="homepage.zipcode.getStarted"
                                                 defaultMessage="Get Started"
                                                 description="Get Started"
                                          />
                                   </NavLink>
                                   <p className="disclaimer">
                                          <FormattedMessage
                                                 id="homepage.zipcode.disclaimer"
                                                 defaultMessage="Personalized pricing plans in less than 1 minute. No signup required."
                                                 description="Personalized pricing plans in less than 1 minute. No signup required."
                                          />
                                   </p>
                            </div>
                     </div>
              </section>
       );
};

export default HomepageZipCode;
