import {
  HOME_ENERGY_COST_ID,
  EV_ENERGY_COST_ID,
  GAS_ENERGY_COST_ID,
} from "../constants/";

const rateComparisonChartData = ({ rateTotals, labelHome, labelElectric, labelGasoline }) => {
  return {
    datasets: [
      {
        label: labelHome,
        id: HOME_ENERGY_COST_ID,
        data: rateTotals.map((rateTotal) => rateTotal[HOME_ENERGY_COST_ID]),
        backgroundColor: "#FED600",
        barPercentage: 0.5,
        categoryPercentage: 1,
      },
      {
        label: labelElectric,
        id: EV_ENERGY_COST_ID,
        data: rateTotals.map((rateTotal) => rateTotal[EV_ENERGY_COST_ID]),
        backgroundColor: "#58B947",
        barPercentage: 0.5,
        categoryPercentage: 1,
      },
      {
        label: labelGasoline,
        id: GAS_ENERGY_COST_ID,
        data: rateTotals.map((rateTotal) => rateTotal[GAS_ENERGY_COST_ID]),
        backgroundColor: "#666366",
        barPercentage: 0.5,
        categoryPercentage: 1,
      },
    ],
    labels: [...rateTotals.map((rate) => rate.title)],
  };
};

export default rateComparisonChartData;
