import React, { useContext } from "react";
import PropTypes from "prop-types";
import UserPrefsContext from "../../../context/UserPrefs/UserPrefsContext";

import { FormatAsThousands } from "../../../utils/Helpers/Format";

import Range from "../../shared/InputElements/Range";
import { useIntl } from 'react-intl';

const SlideMilesDrivenDaily = ({
  id = "miles-driven-daily-range",
  ...rest
}) => {
  const intl = useIntl();
  const label = intl.formatMessage
    ? intl.formatMessage({
        id: "evs.slideMilesDrivenDaily",
        defaultMessage: "Minimum Range",
      })
    : "Minimum Range";
  const userPrefs = useContext(UserPrefsContext);

  const descriptionFn = val => {
    return `${FormatAsThousands(val)} ${
      intl.formatMessage
        ? process.env.REACT_APP_METRIC_SYSTEM
          ? intl.formatMessage({
              id: "vehicle.kilometersCapital",
              defaultMessage: "Kilometers",
            })
          : intl.formatMessage({ id: "milesWord", defaultMessage: "Miles" })
        : "Miles"
    }`;
  };

  return (
    <Range
      id={id}
      value={userPrefs.get("milesDrivenDaily")}
      label={label}
      rangeMin={1}
      rangeMax={150}
      rangeStep={1}
      description={descriptionFn}
      ariaControls="electric-vehicles-catalog"
      handler={e => userPrefs.set({ milesDrivenDaily: e.target.value })}
      {...rest}
    />
  );
};

export default SlideMilesDrivenDaily;

SlideMilesDrivenDaily.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  description: PropTypes.func
};
