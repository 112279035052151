import UsedVehicle from "../utils/Helpers/UsedVehicle";

const fetchElectricVehicles = async (params) => {
  // Remove null params
  Object.keys(params).forEach(
    (key) => params[key] == null && delete params[key]
  );

  let url = new URL(`${process.env.REACT_APP_EV_INFO_API_HOST}/vehicles`);

  let searchParams = new URLSearchParams(params);

  url.search = searchParams;

  url.search += `&include_used_vehicles`;

  const response = await window.fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${process.env.REACT_APP_EV_INFO_TKN}`,
    },
  });

  const json = await response.json();
  const electricVehicles = json.vehicles;

  const newElectricVehicles = [];
  let usedElectricVehicles = [];

  electricVehicles.forEach((ev) => {
    if (ev.form_factor === "Station Wagon") {
      ev.form_factor = "wagon";
    }
    if (ev.form_factor === "pickup_truck") {
      ev.form_factor = "Truck";
    }
    ev.images.forEach((image) => {
      if (image.legal_info_required === 1) {
        image.legal_info = ev.legal_info;
      }
    });
    if (ev.availability !== "upcoming") {
      if (ev.available_for_purchase_as_new) {
        newElectricVehicles.push(ev);
      }

      usedElectricVehicles.push(UsedVehicle({ ...ev }));
    }
  });

  // Removes any vehicles that have an electric range of NaN
  // usedElectricVehicles = usedElectricVehicles.filter( uev => !Number.isNaN(uev.electric_range))

  return {
    newElectricVehicles: newElectricVehicles,
    usedElectricVehicles: usedElectricVehicles,
  };
};

export default fetchElectricVehicles;
