import React, { useContext } from "react";
import PropTypes from "prop-types";
import UserPrefsContext from "../../../context/UserPrefs/UserPrefsContext";
import { useIntl } from 'react-intl'

import Range from "../../shared/InputElements/Range";



const SlideHomeChargerCordLength = ({
  id = "charger-cord-range",
  ...rest
}) => {
  const userPrefs = useContext(UserPrefsContext);
  const intl = useIntl();
  const label = intl.formatMessage({ id: "homeChargers.cordLength", defaultMessage: "Cord Length"})
  const descriptionFn = val => {
    return `< ${val} ${process.env.REACT_APP_METRIC_SYSTEM ? intl.formatMessage({ id: "m", defaultMessage: "m" }) : intl.formatMessage({ id: "ft", defaultMessage: "ft" })}`;
  };
  return (
    process.env.REACT_APP_METRIC_SYSTEM ? 
    <Range
        id={id}
        value={userPrefs.get("chargerCordLength")}
        label={label}
        rangeMin={4}
        rangeMax={10}
        rangeStep={1}
        description={descriptionFn}
        ariaControls="home-chargers-catalog"
        handler={e => userPrefs.set({ chargerCordLength: e.target.value })}
        {...rest}
    /> : 
  <Range
      id={id}
      value={userPrefs.get("chargerCordLength")}
      label={label}
      rangeMin={15}
      rangeMax={30}
      rangeStep={5}
      description={descriptionFn}
      ariaControls="home-chargers-catalog"
      handler={e => userPrefs.set({ chargerCordLength: e.target.value })}
      {...rest}
    />
    
  );
};

export default SlideHomeChargerCordLength;

SlideHomeChargerCordLength.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  description: PropTypes.func
};
