import React, { useContext } from "react";
import PropTypes from "prop-types";
import UserPrefsContext from "../../../context/UserPrefs/UserPrefsContext"

import Select from "../../shared/InputElements/Select"
import {useIntl} from 'react-intl';

const SelectVehicleStatusFilter = ({
  id = "select-vehicle-status-filter",
  vehicles,
  ...rest
}) => {
  const intl = useIntl()
  const userPrefs = useContext(UserPrefsContext);
  const label = intl.formatMessage ? intl.formatMessage({ id: "vehicle.status", defaultMessage: "Vehicle Status"}) : "Vehicle Status"

  return (
    <Select
      id={id}
      value={userPrefs.get("vehicleStatusFilter")}
      label={label}
      optionNames={[
        intl.formatMessage ? intl.formatMessage({ id: "new", defaultMessage: "New"}) : "New",
        intl.formatMessage ? intl.formatMessage({ id: "pre-owned", defaultMessage: "Pre-Owned"}) : "Pre-Owned"
      ]}
      optionValues={["new", "used"]}
      handler={(e) => userPrefs.set({ vehicleStatusFilter: e.target.value })}
    />
  );
};

export default SelectVehicleStatusFilter;

SelectVehicleStatusFilter.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
};
