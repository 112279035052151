const trackers = [];

const hydrateTrackers = () => {
  if (trackers.length) {
    return;
  }

  switch (process.env.REACT_APP_ENVIRONMENT) {
    case "production":
      trackers.push({
        trackingId: process.env.REACT_APP_GA_CLIENT_TRACKING_ID,
        gaOptions: {
          name: process.env.REACT_APP_GA_CLIENT_TRACKING_OPTIONS_NAME,
        },
      });
      trackers.push({
        trackingId: process.env.REACT_APP_GA_ZAPPYRIDE_TRACKING_ID,
        gaOptions: {
          name: process.env.REACT_APP_GA_ZAPPYRIDE_TRACKING_OPTIONS_NAME,
        },
      });
      trackers.push({
        trackingId: process.env.REACT_APP_GA_CE_TRACKING_ID,
        gaOptions: {
          name: process.env.REACT_APP_GA_CE_TRACKING_OPTIONS_NAME,
        },
      });
      break;

    default:
      trackers.push({
        trackingId: process.env.REACT_APP_GA_CLIENT_STAGING_TRACKING_ID,
        gaOptions: {
          name: process.env.REACT_APP_GA_CLIENT_STAGING_TRACKING_OPTIONS_NAME,
        },
      });
      trackers.push({
        trackingId: process.env.REACT_APP_GA_ZAPPYRIDE_TRACKING_ID,
        gaOptions: {
          name: process.env.REACT_APP_GA_ZAPPYRIDE_TRACKING_OPTIONS_NAME,
        },
      });
      trackers.push({
        trackingId: process.env.REACT_APP_GA_CE_TRACKING_ID,
        gaOptions: {
          name: process.env.REACT_APP_GA_CE_TRACKING_OPTIONS_NAME,
        },
      });
  }
};

hydrateTrackers();

const GaTracker = {
  initialize: () => {
    if (!trackers.length) return;

    // Initialize dataLayer
    window.dataLayer = window.dataLayer || [];
    function gtag() {
      window.dataLayer.push(arguments);
    }
    window.gtag = gtag;

    // Initialize GA4
    gtag("js", new Date());

    // Configure trackers
    trackers.forEach((tracker) => {
      gtag("config", tracker.trackingId, {
        name: tracker.gaOptions.name,
      });
    });

    // Initialize GTM
    const gtmId =
      process.env.REACT_APP_ENVIRONMENT === "production"
        ? process.env.REACT_APP_GA_CLIENT_TRACKING_GTM_ID
        : process.env.REACT_APP_GA_CLIENT_STAGING_TRACKING_GTM_ID;

    if (gtmId) {
      window.dataLayer.push({
        "gtm.start": new Date().getTime(),
        event: "gtm.js",
      });
    }
  },

  getPageName: (page) => {
    const lastCharIndex = page.length - 1;
    return page.length > 1 && page.charAt(lastCharIndex) === "/"
      ? page.substring(0, lastCharIndex)
      : page;
  },

  doHydrate: () => {
    hydrateTrackers();
    GaTracker.initialize();
  },

  trackPage: (page, options = {}) => {
    try {
      page = GaTracker.getPageName(page);

      if (trackers.length && window.gtag) {
        trackers.forEach((tracker) => {
          window.gtag("event", "page_view", {
            page_location: page,
            ...options,
            send_to: tracker.trackingId,
          });
        });
      }
    } catch (error) {
      console.error("Failed to track page:", error);
    }
  },

  trackEvent: (category, action, label, value) => {
    try {
      if (trackers.length && window.gtag) {
        trackers.forEach((tracker) => {
          window.gtag("event", action, {
            event_category: category,
            event_label: label,
            value: value,
            send_to: tracker.trackingId,
          });
        });
      }
    } catch (error) {
      console.error("Failed to track event:", error);
    }
  },
};

export default GaTracker;
