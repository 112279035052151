import React from "react";
import { FormattedMessage } from "react-intl"

const LoadingSpinner = () => {
  return (
    <div className="spinner-border text-primary m-3" role="status">
      <span className="sr-only">
        <FormattedMessage
          id="loading"
          defaultMessage="Loading"
          description="Loading"
        />...</span>
    </div>
  );
};

export default LoadingSpinner;
