import coastalLoadProfile from "./coastalLoadProfile.json";
import mountainLoadProfile from "./mountainLoadProfile.json";
import desertLoadProfile from "./desertLoadProfile.json";
import inlandLoadProfile from "./inlandLoadProfile.json";

import {
  COASTAL,
  MOUNTAIN,
  DESERT,
  INLAND,
} from "../../constants/chargingClimateZones";

const loadProfiles = {
  COASTAL: {
    rateGroup: "TIERED",
    climateZone: COASTAL,
    nemStatus: "NON-NEM",
    year: 2021,
    data: coastalLoadProfile,
  },
  MOUNTAIN: {
    rateGroup: "TIERED",
    climateZone: MOUNTAIN,
    nemStatus: "NON-NEM",
    year: 2021,
    data: mountainLoadProfile,
  },
  DESERT: {
    rateGroup: "TIERED",
    climateZone: DESERT,
    nemStatus: "NON-NEM",
    year: 2021,
    data: desertLoadProfile,
  },
  INLAND: {
    rateGroup: "TIERED",
    climateZone: INLAND,
    nemStatus: "NON-NEM",
    year: 2021,
    data: inlandLoadProfile,
  },
};

const getLoadProfileData = ({ rateGroup, climateZone, hasSolar = false }) => {
  return loadProfiles[climateZone];
};

export default getLoadProfileData;
