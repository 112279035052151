import React from "react";
import { FormattedMessage } from 'react-intl';

const DR = () => {
  return (
    <div>
      <h2 className="title">DR</h2>
      <p className="text">
         <FormattedMessage
            id="DRParagraph1"
            defaultMessage="The standard DR pricing plan is based on the amount of electricity you
            use, not when you use it. The less electricity you use, the less you pay
            – any time, day or night."
            description="DRParagraph1"
          />
      </p>
      <p className="text">
        <FormattedMessage
            id="DRParagraph2"
            defaultMessage="This plan can be a good fit for lower-use households and those that
            prefer to save by conserving without thinking about the time of day."
            description="DRParagraph2"
          />
      </p>
    </div>
  );
};

export default DR;