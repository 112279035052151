const ElectricityCost = {
  perHundredElectricMiles: function(
    vehicle,
    inputElectricityCostInDollarsPerKwh,
    costPublicCharging,
    publicChargingPercentage
  ) {
    let fuelType = vehicle && vehicle.fuel ? vehicle.fuel : "";

    if (!["BEV", "PHEV"].includes(fuelType.toUpperCase())) return 0;

    const publicCharingPercent = Number(publicChargingPercentage) / 100

    const electricityCostInDollarsPerKwh = publicCharingPercent > 0 ? (Number(inputElectricityCostInDollarsPerKwh) * (1 - publicCharingPercent)) + Number(costPublicCharging) * publicCharingPercent : inputElectricityCostInDollarsPerKwh

    const vehicleKwhPerMile =
      vehicle.electric_efficiency && parseFloat(vehicle.electric_efficiency) > 0
        ? vehicle.electric_efficiency
        : 0;

    let cost = electricityCostInDollarsPerKwh * vehicleKwhPerMile;

    return isFinite(cost) ? cost : 0;
  },

  perMile: function(
    vehicle,
    inputElectricityCostInDollarsPerKwh,
    electricMilesPortionForPhev,
    costPublicCharging,
    publicChargingPercentage
  ) {
    let fuelType = vehicle && vehicle.fuel ? vehicle.fuel : "";

    if (!["BEV", "PHEV"].includes(fuelType.toUpperCase())) return 0;

    const PHEVFuelSplitAdjustment =
      ["PHEV"].includes(fuelType.toUpperCase()) &&
      parseFloat(electricMilesPortionForPhev) >= 0
        ? electricMilesPortionForPhev / 100
        : 1;

    let cost =
      (this.perHundredElectricMiles(
        vehicle,
        inputElectricityCostInDollarsPerKwh,
        costPublicCharging,
        publicChargingPercentage
      ) *
        PHEVFuelSplitAdjustment) /
      100;

    return isFinite(cost) ? cost : 0;
  }
};

export default ElectricityCost;
