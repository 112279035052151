import React from "react";
import PropTypes from "prop-types";
import "./RenderItem.scss";

import MatchScorePopover from "./MatchScorePopover/MatchScorePopover";
import ToolTip from "../shared/ToolTip/ToolTip";

const RenderItem = ({ matchScore, title, tooltipText, value }) => {
  let renderValue;

  const renderTooltip = tooltipText ? (
    <ToolTip
      message={tooltipText}
      id={title.toLowerCase().replace(/\s+/g, "_") + "_label_tooltip"}
    />
  ) : null;

  if (matchScore) {
    renderValue = <MatchScorePopover score={value} />;
  } else {
    renderValue = <p className="RenderItemValue">{value}</p>;
  }

  return (
    <>
      <p className="RenderItemTitle">
        <div>
          {title}
        </div>
        {renderTooltip}
        <div>
        {renderValue}
        </div>
      </p>
    </>
  );
};

export default RenderItem;

RenderItem.propTypes = {
  matchScore: PropTypes.bool,
  title: PropTypes.string,
  tooltipText: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object
  ])
};
