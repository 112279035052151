import React from "react";
import PropTypes from "prop-types";
import "./RateButtonGroup.scss";
import { ALL_HOURS } from "../../../client_customizations/constants";
import { useIntl } from 'react-intl';

const RateButtonGroup = ({ id, value, handler, label, chargingPatterns }) => {
       const intl = useIntl();

  const renderButtons = () => {
    let top = [];
    let bottom = [];

    chargingPatterns.map((pattern, i) => {
      if (pattern.id === ALL_HOURS) {
        return top.push(
          <button
            key={i}
            className={`btn ${value === pattern.id ? "active" : ""} large`}
            onClick={(e) => handler(e, pattern.id)}
          >
            <p className="title">{intl.formatMessage({ id: "modal13", defaultMessage: "All Hours" })}</p>
          </button>
        );
      }

      return bottom.push(
        <div className="wrapper">
          <button
            key={i}
            className={`btn ${value === pattern.id ? "active" : ""}`}
            onClick={(e) => handler(e, pattern.id)}
          >
            <p className="title">{pattern.title}</p>
          </button>
        </div>
      );
    });

    return (
      <>
        {top}
        <div className="d-flex justify-content-between">{bottom}</div>
      </>
    );
  };

  return (
    <div className="form-group RateButtonGroup" id={id}>
      <span className="label-style">{label}</span>
      {renderButtons()}
    </div>
  );
};

export default RateButtonGroup;

RateButtonGroup.propTypes = {
  id: PropTypes.string,
  value: PropTypes.string,
  handler: PropTypes.func,
  optionNames: PropTypes.array,
  optionValues: PropTypes.array,
  label: PropTypes.string,
};
