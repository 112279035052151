import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import fetchElectricians from "../../services/fetchElectricians";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import GenericInputZipcode from "../../components/InputComponents/InputZipcode/GenericInputZipcode";
import SelectedCard from "../../components/SelectedCard/SelectedCard";
import PreferredMark from "../../client_customizations/assets/images/icons/PreferredMark.svg";
import BaseGoogleMapReact from "./BaseGoogleMapReact/BaseGoogleMapReact";
import ElectricianChargingStationsMapLegend from "./ElectricianChargingStationsMapLegend/ElectricianChargingStationsMapLegend";

const ElectricianMarker = ({
  isSelected,
  onClick,
  onMouseOver,
  onMouseLeave,
  electrician,
  electricians,
  selectedElectricianName,
}) => {
  const findElectrician = () =>
    (electricians || []).find(
      (electrician) => electrician.name === selectedElectricianName
    );

  const styles = {
    width: "25px",
    ...(isSelected && {
      filter: "brightness(85%)",
    }),
  };

  const placeStyle = {
    display: "flex",
    position: "absolute",
    transform: "translate(-50%, -100%)",
    justifyContent: "center",
    alignItems: "flex-end",
    zIndex: isSelected ? "10" : "0",
  };

  return (
    <span
      style={placeStyle}
      onClick={onClick}
      onMouseOver={onMouseOver}
      onMouseLeave={onMouseLeave}
    >
      {isSelected && <SelectedCard {...findElectrician()} />}
      <img src={PreferredMark} style={styles} alt="" />
    </span>
  );
};

const ElectriciansMap = ({ userLocation, zipcode, ip, uuid, isVisible }) => {
  const [selectedElectricianName, setSelectedElectricianName] =
    useState(undefined);
  const [selectedSuperElectricianName, setSelectedSuperElectricianName] =
    useState(undefined);
  const [electricianType] = useState("All");
  const [electricians, setElectricians] = useState([]);
  const [center, setCenter] = useState();

  useEffect(() => {
    let ignore = false;

    async function fetchData() {
      const params = {
        postcode: zipcode,
        distance: 200,
      };
      try {
        const electricians = await fetchElectricians(params);
        if (!ignore) setElectricians(electricians);
      } catch (e) {
        console.log("Failed to load electricians");
      }
    }

    fetchData();

    return () => {
      ignore = true;
    };
  }, [zipcode]);

  useEffect(() => {
    if (!userLocation) return;
    setCenter({
      lat: parseFloat(userLocation.latitude),
      lng: parseFloat(userLocation.longitude),
    });
  }, [userLocation]);

  const filteredElectricians = (electricians || []).filter((electrician) => {
    if (electricianType === "All") {
      return true;
    }
    return electrician[electricianType.toLowerCase()] === 1;
  });

  const body = !userLocation ? (
    <div className="spinner-container">
      <LoadingSpinner />
    </div>
  ) : (
    <>
      <div
        style={{ display: "flex", flexDirection: "column" }}
        className="col-sm-3 left-column"
      >
        <div className="controls">
          <GenericInputZipcode buttonText="Update zip code" />
        </div>
        <ElectricianChargingStationsMapLegend />
      </div>
      <div className="col-sm-9">
        <div
          className="map-container"
          onClick={() => setSelectedSuperElectricianName(false)}
        >
          {isVisible && (
            <BaseGoogleMapReact center={center} zoom={9}>
              {filteredElectricians.map((electrician, i) => (
                <ElectricianMarker
                  selectedElectricianName={
                    selectedSuperElectricianName
                      ? selectedSuperElectricianName
                      : selectedElectricianName
                  }
                  electricians={electricians}
                  electrician={electrician}
                  key={electrician.name + i}
                  lat={electrician.latitude}
                  lng={electrician.longitude}
                  isSelected={
                    selectedSuperElectricianName
                      ? electrician.name === selectedSuperElectricianName
                      : electrician.name === selectedElectricianName
                  }
                  onMouseOver={() => {
                    setSelectedElectricianName(electrician.name);
                  }}
                  onMouseLeave={() => {
                    setSelectedElectricianName(false);
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    setSelectedSuperElectricianName(electrician.name);
                  }}
                />
              ))}
            </BaseGoogleMapReact>
          )}
        </div>
      </div>
    </>
  );

  return (
    <div className="DealerMap">
      <div style={{ height: "100%" }} className="row dealerMap-content">
        {body}
      </div>
    </div>
  );
};

export default ElectriciansMap;

ElectriciansMap.propTypes = {
  userLocation: PropTypes.object,
  ip: PropTypes.string,
  uuid: PropTypes.string,
};
