import React, { useContext } from "react";
import PropTypes from "prop-types";
import UserPrefsContext from "../../../../context/UserPrefs/UserPrefsContext";

import Select from "../../../../components/shared/InputElements/Select";
import { useIntl } from "react-intl";

const SelectHouseholdSize = ({ id = "household-size", ...rest }) => {
  const intl = useIntl();
  const userPrefs = useContext(UserPrefsContext);

  return (
    <Select
      id={id}
      ariaLabel="Select household size"
      value={userPrefs.get("householdSize").toString()}
      optionNames={["1", "2", "3", "4", "5", "6", "7", "8"]}
      optionValues={[1, 2, 3, 4, 5, 6, 7, 8]}
      tooltip={
        intl.formatMessage
          ? intl.formatMessage({
              id: "incentives.householdIncomeTooltip",
              defaultMessage:
                "Used to determine incentive eligibility. Include all members of your household (spouse, dependents, etc.). Few to no incentives mention households larger than 8 members, so select 8 if your household size is 8 or larger.",
            })
          : "Used to determine incentive eligibility. Include all members of your household (spouse, dependents, etc.). Few to no incentives mention households larger than 8 members, so select 8 if your household size is 8 or larger."
      }
      handler={(e) => userPrefs.set({ householdSize: e.target.value })}
    />
  );
};

export default SelectHouseholdSize;

SelectHouseholdSize.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  tooltip: PropTypes.string,
};
