import "chartjs-plugin-datalabels";
import { FormatAsDollars } from "../../../utils/Helpers/Format";

const chartOptions = ({
  opts,
  rateValue,
  isLowest,
  yAxesLabel,
  savedLabel,
}) => {
  return {
    maintainAspectRatio: true,
    responsive: true,
    tooltips: {
      mode: "index",
      callbacks: {
        label: function (tooltipItem, data) {
          const value = FormatAsDollars(
            data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index]
          );
          const label = data.datasets[tooltipItem.datasetIndex].label || "";
          return ` ${label}: ${value}`;
        },
        title: function (tooltipItems, data) {
          const total = tooltipItems.reduce(function (acc, item) {
            return acc + item.yLabel;
          }, 0);
          return `${tooltipItems[0].xLabel.join(" ")}: ${FormatAsDollars(
            total
          )}`;
        },
      },
    },
    legend: {
      reverse: true,
      position: "bottom",
      onClick: function (e) {
        e.stopPropagation();
      },
      labels: {
        fontSize: 14,
        fontColor: "#262626",
        padding: 20,
        fontFamily: "Interstate, 'Open Sans'",
        boxWidth: 20,
      },
    },
    layout: {
      padding: 10,
    },
    scales: {
      yAxes: [
        {
          display: true,
          stacked: true,
          gridLines: {
            display: true,
            color: "rgba(102, 99, 102, 0.33)",
          },
          ticks: {
            callback: function (value) {
              return `$${value.toFixed(0)}`;
            },
            fontSize: 14,
            fontColor: "#000000",
            fontFamily: "Interstate, 'Open Sans'",
            padding: 15,
            maxTicksLimit: 6,
          },
          scaleLabel: {
            display: true,
            labelString: yAxesLabel,
            fontSize: 14,
            fontWeight: 800,
            fontColor: "#262626",
            fontFamily: "Interstate, 'Open Sans'",
          },
        },
      ],
      xAxes: [
        {
          gridLines: {
            display: false,
            color: "rgba(102, 99, 102, 0.33)",
          },
          stacked: true,
          barThickness: 60,
          categorySpacing: 10,
          ticks: {
            fontSize: 14,
            fontWeight: 800,
            fontColor: "#262626",
            fontFamily: "Interstate, 'Open Sans'",
          },
        },
      ],
    },
    plugins: {
      datalabels: {
        formatter: (value, ctx) => {
          if (ctx.datasetIndex !== ctx.chart.data.datasets.length - 1)
            return "";

            return ctx.dataIndex === 0
            ? isLowest ? `${FormatAsDollars(rateValue.toFixed(0))} ${savedLabel}` : ""
            : opts[ctx.dataIndex].savings && opts[ctx.dataIndex].savings > 0
              ? `${FormatAsDollars(opts[ctx.dataIndex].savings.toFixed(0))} ${savedLabel}`
              : "";
        },
        align: "end",
        anchor: "end",
        color: (ctx) => {
          return "#000000";
        },
        font: {
          weight: 800,
          size: 12,
        },
      },
    },
  };
};

export default chartOptions;
