import React from "react";
import { FormattedMessage } from 'react-intl';
import "./styles.scss";

const DRSESDetails = () => {
  return (
    <div className="ScheduleDDetails">
      <h2 className="title">DR-SES</h2>
      <p className="text">
      <FormattedMessage
            id="rateDRSESParagraph1"
            defaultMessage="DR-SES is the plan for solar and other renewable energy customers.
            Pricing is based on the time and day of the week, along with how much
            electricity you use. You'll earn bill credits by sending your excess
            power to the energy grid."
            description="rateDRSESParagraph1"
          />
      </p>
      <p className="text">
         <FormattedMessage
            id="rateDRSESParagraph2"
            defaultMessage="This plan could be a good fit for you if you generate renewable energy,
            if you can shift your energy use activities to before 4 p.m. and after 9
            p.m., and if you can take advantage of lower-priced electricity during
            off-peak times."
            description="rateDRSESParagraph2"
          />
      </p>
    </div>
  );
};

export default DRSESDetails;