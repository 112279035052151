// import calcMatchScore from "../MatchScore/calcMatchScore";
import cloneDeep from "lodash/cloneDeep";

const Private = {
  priceSort: function(chargers, direction) {
    return chargers.sort((a, b) => {
      let price = charger => charger.price;
      switch (direction) {
        case "asc":
          return price(a) - price(b);
        case "desc":
          return price(b) - price(a);
        default:
          return chargers;
      }
    });
  },
  cordLengthSort: function(chargers, direction) {
    return chargers.sort((a, b) => {
      let range = charger => charger.cord_length;
      switch (direction) {
        case "asc":
          return range(a) - range(b);
        case "desc":
          return range(b) - range(a);
        default:
          return chargers;
      }
    });
  },
  chargerPowerSort: function(chargers, direction) {
       return chargers.sort((a, b) => {
         let amps = charger => charger.amps;
         switch (direction) {
           case "asc":
             return amps(a) - amps(b);
           case "desc":
             return amps(b) - amps(a);
           default:
             return chargers;
         }
       });
     }
};

const sortChargers = (
  chargers,
  sortType,
  sortDirection,
) => {
  const clonedChargers = cloneDeep(chargers);

  switch (sortType) {
    case "price":
      return Private.priceSort(clonedChargers, sortDirection);
    case "cord_length":
      return Private.cordLengthSort(clonedChargers, sortDirection);
    case "charger_power":
       return Private.chargerPowerSort(clonedChargers, sortDirection);
    default:
      return Private.priceSort(clonedChargers, sortDirection);
  }
};

export default sortChargers;
