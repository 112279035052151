import chargingPatterns from "../../client_customizations/data/chargingPatterns";
import energyCostTypes from "../../client_customizations/data/energyCostTypes";

import USER_PREF_PRESETS_CLIENT_CUSTOM from "../../client_customizations/context/UserPrefs/USER_PREF_PRESETS_CLIENT_CUSTOM";

const USER_PREF_PRESETS = {
  canTurnInClunker: false,
  chargerInstallAllowed: null,
  chargerLevel: "level_2",
  electricMilesPortionForPhev: 50,
  hasOvernightCharger: null,
  gasolinePriceInCentsPerGal: 295,
  hasRegularPowerOutlet: null,
  householdIncome: 75000,
  householdSize: 1,
  electricityProvider: "All",
  includeResaleValue: true,
  interestRateAsBasisPoints: 350,
  locallyAvailableVehicleFilter: false,
  maxBudget: 30000,
  milesDrivenAnnually: 12000,
  milesDrivenDaily: 30,
  minSeats: 2,
  monthsOfOwnership: 60,
  parkingLocation: "",
  purchaseMethod: "cash",
  showAllRates: false,
  showGasolineCost: true,
  showHomeElectricityCost: true,
  showRateDetails: true,
  showDetailsForRate: "EV2-A",
  taxFilingStatus: "single",
  vehicleChargingPattern: "After midnight; Before 3pm",
  vehicleIdForIncentives: "",
  vehicleHandleForIncentives: "",
  vehicleSortDirection: "desc",
  vehicleSortType: "match_score",
  workingZipcode: `92123`,
  zipcode: `92123`,
  chargerBudget: 2000,
  chargerCordLength: 25,
  chargerSortType: "price",
  chargerSortDirection: "asc",
  vehicleMakeFilter: "All",
  vehicleModelFilter: "",
  vehicleTrimFilter: "",
  vehicleYearFilter: "",
  vehicleAgeFilter: "All",
  vehicleStatusFilter: "New",
  eventDistance: "25",
  eventLocation: "In Person",
  eventsSortType: "date-asc",
  salesTax: "0.0775",
  costPublicCharging: "0.2",
  publicChargingPercentage: "20",
  electricityRate: ".16611",
  municipality: "",
  country: "United States" || null,
  currentRateId: "TOU-DR1",
  currentRateClimateZone: "COASTAL",
  vehicleIdForRateComparison: "",
  equivalentGasVehicleRateComparison: "",
  chargingPatternId: chargingPatterns[0].id,
  currentMonthlyBill: 200,
  hasSolarAtHome: false,
  equivalentMilesPerGallon: 25,
  publicChargingCostInCentsPerKwh: 20,
  selectedEnergyCostIds: energyCostTypes.map((costType) => costType.id),
  isViewingRateDetails: false,
  selectedRateDetailsId: "TOU-DR1",
  isComparingLowestRateOnly: false,
  incentiveSortType: "price",
  incentiveSortDirection: "desc",

  vehicleFuelTypeFilters: {
    bev: false,
    phev: false,
  },

  vehicleFormFactorFilters: {
    sedan: false,
    hatchback: false,
    coupe: false,
    crossover: false,
    minivan: false,
    suv: false,
    wagon: false,
    truck: false,
  },

  chargerWifiFilter: {
    yes: false,
    no: false,
  },

  chargerTypeFilters: {
    mounted: false,
    portable: false,
  },

  chargerFormFactorFilters: {
    "6-20": false,
    "6-30": false,
    "10-30": false,
    "14-30": false,
    "6-50": false,
    "14-50": false,
    "14-60": false,
    Hardwired: false,
  },
};

export default { ...USER_PREF_PRESETS, ...USER_PREF_PRESETS_CLIENT_CUSTOM };
