import React from "react";
import PropTypes from "prop-types";
import IncentiveCatalog from "./../../components/IncentiveCatalog/IncentiveCatalog";
import LoadingSpinner from "./../../components/LoadingSpinner/LoadingSpinner";
import { FormattedMessage } from "react-intl";
import IncentiveScoreOptions from "../../client_customizations/components/IncentiveScoreOptions/IncentiveScoreOptions";
import IncentiveVehicleOptions from "../../client_customizations/components/IncentiveVehicleOptions/IncentiveVehicleOptions";
import IncentiveSortControls from "../../client_customizations/components/IncentiveSortControls/IncentiveSortControls";

import "./Incentives.scss";
const Incentives = ({ electricVehicles, usedElectricVehicles, incentives, location }) => {
  const renderIncentives = incentives ? (
    <IncentiveCatalog
      location={location}
      incentives={incentives}
      category="all"
    />
  ) : (
    <LoadingSpinner />
  );

  const renderOptions = (
    <>
      <IncentiveScoreOptions />
      <br />
    </>
  );

  const renderVehicleOptions = (electricVehicles && usedElectricVehicles) ? (
    <IncentiveVehicleOptions electricVehicles={[...electricVehicles, ...usedElectricVehicles]} />
  ) : null;

  return (
    <>
      <section className="container IncentivesPage">
        <div className="row">
          <div className="col-sm-12">
            <h1 className="page-title">
              <FormattedMessage
                id="incentives.title"
                defaultMessage="Electric Vehicle Incentives"
                description="Electric Vehicle Incentives"
              />
            </h1>
            <p className="lead subtitle">
              <FormattedMessage
                id="incentives.subTitle"
                defaultMessage="
              You may be eligible for incentives that reduce the cost to purchase or lease an EV. Adjust the filters to personalize your results and click each tile to learn more."
                description="
              You may be eligible for incentives that reduce the cost to purchase or lease an EV. Adjust the filters to personalize your results and click each tile to learn more."
              />
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div className="pull-right">
              <IncentiveSortControls />
            </div>
          </div>
        </div>
        <div className="row main-container">
          <div className="col-xl-3 col-lg-3 d-block">
            {renderOptions}
            {renderVehicleOptions}
          </div>
          <div
            className="col-xl-9 col-lg-9 col-sm-12"
            style={{ paddingLeft: 10 }}
          >
            {renderIncentives}
          </div>
        </div>
      </section>
    </>
  );
};

export default Incentives;

Incentives.propTypes = {
  incentives: PropTypes.object,
  electricVehicles: PropTypes.array,
  location: PropTypes.object,
  zip: PropTypes.string,
  ip: PropTypes.string,
  uuid: PropTypes.string,
};
