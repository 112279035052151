import messages_en from "./en";
import messages_esp from "./esp";
import messages_fr from "./fr";
import messages_zhhant from "./zhhant";
import messages_vi from "./vi";

const MESSAGES = {
    'EN': messages_en,
    'ES': messages_esp,
    'FR': messages_fr,
    'VI': messages_vi,
    'ZH-HANT': messages_zhhant,
};

export default MESSAGES;