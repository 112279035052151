import VehicleCost from "./CostOfOwnership/calcs/VehicleCost";
import capitalizedInPurchase from "../incentives/capitalization/capitalizedInPurchase";
import capitalizedInLease from "../incentives/capitalization/capitalizedInLease";
import nonCapitalizedInLease from "../incentives/capitalization/nonCapitalizedInLease";
import verifyIncentiveType from "../incentives/capitalization/verifyIncentiveType";



const getPaymentDetails = (
  vehicle,
  userPrefs
) => {
  const salesTax = userPrefs.get("salesTax");
  const filteredIncentives = verifyIncentiveType(vehicle.incentives);
  const incentivesForPurchase = capitalizedInPurchase(filteredIncentives);
  const incentivesForLease =
    capitalizedInLease(filteredIncentives) +
    nonCapitalizedInLease(filteredIncentives);

  const afterIncentives = VehicleCost.afterIncentives(vehicle);
  const loanDownPayment = VehicleCost.downPayment(vehicle, salesTax);
  const loanAmountPerMonth = VehicleCost.monthlyLoanPayment(
    vehicle,
    userPrefs.get("monthsOfOwnership"),
    userPrefs.get("interestRateAsBasisPoints"),
    salesTax
  );
  const leaseFirstPayment = VehicleCost.firstLeasePayment(
    vehicle,
    userPrefs.get("milesDrivenAnnually"),
    userPrefs.get("monthsOfOwnership"),
    userPrefs.get("interestRateAsBasisPoints"),
    salesTax
  );
  const leaseAmountPerMonth = VehicleCost.monthlyLeasePayment(
    vehicle,
    userPrefs.get("milesDrivenAnnually"),
    userPrefs.get("monthsOfOwnership"),
    userPrefs.get("interestRateAsBasisPoints"),
    salesTax
  );

  const yearsOfOwnership = userPrefs.get("monthsOfOwnership") / 12;

  return {
    afterIncentives: afterIncentives,
    loanAmountPerMonth: loanAmountPerMonth,
    downPayment: loanDownPayment,
    leaseAmountPerMonth: leaseAmountPerMonth,
    leaseFirstPayment: leaseFirstPayment,
    msrp: vehicle.msrp,
    interestRateAsBasisPoints: userPrefs.get("interestRateAsBasisPoints"),
    yearsOfOwnership: yearsOfOwnership,
    totalIncentivesForPurchase: incentivesForPurchase,
    totalIncentivesForLease: incentivesForLease
  };
};

export default getPaymentDetails;
