import React, { useMemo } from "react";
import PropTypes from "prop-types";
import GoogleMapReact from "google-map-react";
import MapStyles from "./MapStyles";
import ChargingStationsMapMarker from "../ChargingStationsMapMarker/ChargingStationsMapMarker";

const INITIAL_ZOOM = 15;
const K_HOVER_DISTANCE = 30;
const API_KEY = process.env.REACT_APP_GOOGLE_MAP_API_KEY;

const BaseGoogleMapReact = ({
  center,
  children,
  onHoverOnStation,
  onHoverOffStation,
  onClickMarker,
  chargingStations,
  selectedStation,
  ...rest
}) => {
  const hoverProps = {
    ...(onHoverOnStation && {
      onChildMouseEnter: (key, childProps) => {
        onHoverOnStation(childProps.station.id);
      },
    }),
    ...(onHoverOffStation && {
      onChildMouseLeave: onHoverOffStation,
    }),
  };

  const renderMap = useMemo(() => {
    if (!center) {
      return <></>;
    }

    return (
      <GoogleMapReact
        bootstrapURLKeys={{
          key: API_KEY,
          libraries: "places",
        }}
        center={center}
        defaultZoom={INITIAL_ZOOM}
        hoverDistance={K_HOVER_DISTANCE}
        options={{ styles: MapStyles }}
        yesIWantToUseGoogleMapApiInternals
        {...hoverProps}
        {...rest}
      >
        {(chargingStations || []).map((station, i) => (
          <ChargingStationsMapMarker
            key={station.id}
            station={station}
            selectedStation={selectedStation}
            lat={station.latitude}
            lng={station.longitude}
            onClick={onClickMarker}
          />
        ))}
        {children}
      </GoogleMapReact>
    );
  }, [
    center,
    hoverProps,
    rest,
    chargingStations,
    selectedStation,
    onClickMarker,
    children,
  ]);

  return renderMap;
};

BaseGoogleMapReact.propTypes = {
  center: PropTypes.shape({
    lat: PropTypes.number,
    lng: PropTypes.number,
  }),
  children: PropTypes.node,
  onClickMarker: PropTypes.func,
  onHoverOnStation: PropTypes.func,
  onHoverOffStation: PropTypes.func,
  chargingStations: PropTypes.array,
  selectedStation: PropTypes.object,
};

BaseGoogleMapReact.defaultProps = {
  chargingStations: [],
  onClickMarker: () => {},
};

export default BaseGoogleMapReact;
