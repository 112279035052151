import React from "react";
import PropTypes from "prop-types";

import ChargingPinHigh from "./../../../client_customizations/assets/images/icons/PreferredMark.svg";
import { useIntl } from "react-intl";
import "./ElectricianChargingStationsMapLegend.scss";

const ElectricianChargingStationsMapLegend = () => {
  const intl = useIntl();

  return (
    <div className="ElectricianChargingStationsMapLegend">
      <div className="charger-type">
        <img src={ChargingPinHigh} alt="" />
        <b>{intl.formatMessage({ id: "certifiedElectrician", defaultMessage: "Certified electrician" })}</b>
      </div>
    </div>
  );
};

ElectricianChargingStationsMapLegend.propTypes = {
  chargingStations: PropTypes.array,
};

ElectricianChargingStationsMapLegend.defaultProps = {
  chargingStations: [],
};

export default ElectricianChargingStationsMapLegend;
