import React from "react";
import { FormattedMessage } from 'react-intl';

import "./styles.scss";

const touDr1Details = () => {
  return (
    <div>
      <h2 className="title">TOU-DR1</h2>

      <p className="text">
       <FormattedMessage
            id="rateTOUDR1Paragraph1"
            defaultMessage="TOU-DR1 pricing is based on the amount of electricity you use, as well
            as the time and day of the week you use it. With three pricing periods,
            there’s an opportunity to save the most on nights and weekends."
            description="rateTOUDR1Paragraph1"
          />
      </p>

      <p className="text">
      <FormattedMessage
            id="rateTOUDR1Paragraph2"
            defaultMessage="This plan could be a good fit if you can shift some of your electricity
            use away from the on-peak hours of 4-9 p.m. and you can take advantage
            of lower-priced electricity during the off-peak times during nights and
            weekends."
            description="rateTOUDR1Paragraph2"
          />
      </p>
    </div>
  );
};

export default touDr1Details;