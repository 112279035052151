import React, { useContext } from "react";
import Select from "../../../../components/shared/InputElements/Select";
import UserPrefsContext from "../../../../context/UserPrefs/UserPrefsContext";
import { useIntl } from 'react-intl';

const SelectCurrentRate = ({ rates }) => {
  const intl = useIntl();
  const userPrefs = useContext(UserPrefsContext);



  return (
    <Select
      id="rate-comparison-current-rate-id"
      value={userPrefs.get("currentRateId")}
      handler={(e) => userPrefs.set({ currentRateId: e.target.value })}
      optionNames={rates.map((rate) => rate.title.join(" "))}
      optionValues={rates.map((rate) => rate.id)}
      label={intl.formatMessage
       ? intl.formatMessage({ id: "currentPricingPlan", defaultMessage: "CURRENT PRICING PLAN" })
       : "Yes"}
      isLarge
      isSpecial
    />
  );
};

export default SelectCurrentRate;
