import React, { useState, useEffect, useContext } from "react";
import fetchEvents from "../../services/fetchEvents";
import EventSortControls from "../../components/EventSortControls/EventSortControls";
import EventCatalog from "../../components/EventCatalog/EventCatalog";
import EventsFilterControls from "../../components/EventsFilterControls/EventsFilterControls";
import ModalComponent from "../../components/ModalUIComponent/ModalUIComponent";
import { FormattedMessage } from "react-intl";
import UserPrefsContext from "../../context/UserPrefs/UserPrefsContext";

const Events = () => {
  const [events, setEvents] = useState([]);
  const [distance, setDistance] = useState("25");
  const [startDate, setStartDate] = useState(
    new Date().toISOString().substring(0, 10)
  );
  const [endDate, setEndDate] = useState("");
  const [eventCategories, setEventCategories] = useState({});
  const userPrefs = useContext(UserPrefsContext);
  const zipcode = userPrefs.get("zipcode");

  useEffect(() => {
    let ignore = false;

    async function fetchData() {
      const params = {
        postcode: zipcode,
        distance: distance,
        date_start: startDate,
        date_end: endDate,
      };
      try {
        const events = await fetchEvents(params);
        if (!ignore) {
          setEvents(events);
          setEventCategories(setEventCategoriesObject(events));
        }
      } catch (e) {
        console.log("Failed to load Events");
      }
    }

    fetchData();

    return () => {
      ignore = true;
    };
  }, [zipcode, distance, startDate, endDate]);

  const setEventCategoriesObject = (events) => {
    const typesObject = {};

    events.forEach((event) => {
      const type = event.event_category ? event.event_category : null;
      if (type && typesObject[type] === undefined) {
        typesObject[type] = false;
      }
    });
    return typesObject;
  };
  const renderFilters = (
    <>
      <EventsFilterControls
        eventCategories={eventCategories}
        setEventCategories={setEventCategories}
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
        distance={distance}
        setDistance={setDistance}
      />
    </>
  );

  return (
    <section className="container-fluid" id="events">
      <div className="container">
        <div className="row mb-3">
          <div className="col-sm-12 text-center">
            <h1>
              <FormattedMessage
                id="events"
                defaultMessage="Events"
                description="Events"
              />
            </h1>
            <p className="lead">
              <FormattedMessage
                id="events.subHeader"
                defaultMessage="See, touch and feel an electric vehicle for yourself at an upcoming event."
                description="Events Subheader"
              />
            </p>
          </div>
        </div>

        <div className="row">
          <div className="col-sm-12 d-block d-lg-none text-center">
            <ModalComponent buttonText="Filters" titleText="Refine Filters">
              {renderFilters}
            </ModalComponent>
            <br />
          </div>
          <div className="col-xl-3 col-lg-4 d-none d-lg-block">
            {renderFilters}
          </div>
          <div
            className="col-xl-9 col-lg-8 col-sm-12"
            style={{ paddingLeft: 10 }}
          >
            <div className="row">
              <div className="col">
                <div className="pull-right">
                  <EventSortControls />
                </div>
              </div>
            </div>
            <EventCatalog events={events} eventCategories={eventCategories} />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Events;
