import React from "react";
import PropTypes from "prop-types";
import "./EventCard.scss";

import calendar from "../../client_customizations/assets/images/icons/calendar.svg"
import pin from "../../client_customizations/assets/images/icons/pin.svg"
import rideAndDrive from "../../client_customizations/assets/images/ride_and_drive.png"
import training from "../../client_customizations/assets/images/training.png"
import webinar from "../../client_customizations/assets/images/webinar.png"
import conference from "../../client_customizations/assets/images/conference.png"
import educational from "../../client_customizations/assets/images/educational.png"
import autoshow from "../../client_customizations/assets/images/autoshow.png"
import miscEvent from "../../client_customizations/assets/images/misc_event.png"

import { FormattedMessage, useIntl } from 'react-intl';

const EventCard = ({
  event,
}) => {
  const intl = useIntl() 
  if (!event) return null;

  const name = event.name ? event.name : ""
  const inPerson = event.is_in_person ? event.is_in_person : false
  const virtual = event.is_virtual ? event.is_virtual : false
  const organizer = event.organizer ? event.organizer : ""
  const startDateTime = event.date_time_start ? event.date_time_start : null
  const endDateTime = event.date_time_end ? event.date_time_end : null 
  const venue = event.location_venue ? event.location_venue : ""
  const address = event.location_address ? event.location_address : ""
  const municipality = event.location_municipality ? event.location_municipality : ""
  const region = event.location_region ? event.location_region : ""
  const postcode = event.location_postcode ? event.location_postcode : ""
  const eventLink = event.details_url ? event.details_url : ""
  const category = event.event_category ? event.event_category : "default"

  const categoryImageObject = {"Ride and Drive" : rideAndDrive, "Autoshow" : autoshow, "Education Event" : educational, "Conference" : conference, "Training" : training, "Webinar" : webinar, "default" : miscEvent}

  const categoryImage = categoryImageObject[category]

  const formatDate = (date, monthLength) => {
    const newDate = new Date(date)
    const options = { year: 'numeric', month: monthLength, day: 'numeric' };

    return newDate.toLocaleDateString("en-US", options)
  }

  const formatTime = (date) => {
    const newDate = new Date(date)

    return newDate.toLocaleTimeString("en-US", {hour: '2-digit', minute: '2-digit'})
  }

  const renderTimeRows = () => {

    let renderDate = formatDate(startDateTime, "long")
    if (formatDate(startDateTime, "short") !== formatDate(endDateTime, "short")) {
      renderDate = `${formatDate(startDateTime, "short")} - ${formatDate(endDateTime, "short")}`
    }

    let renderTime = formatTime(startDateTime) === formatTime(endDateTime) ? "" : `${formatTime(startDateTime)} - ${formatTime(endDateTime)}`

    return (
      <div className="date-time-row">
        <div className="date-row row">
          <div className="col-1">
            <img src={calendar} alt="calendar" />
          </div>
          <div className="col-11">
            {renderDate}
          </div>
        </div>
        <div className="time-row row">
          <div className="col-1">
            <img src={calendar} alt="calendar" className="d-none"/>
          </div>
          <div className="col-11">
            {renderTime}
          </div>
        </div>
      </div>
    )
  }

  const renderLocationRow = () => {
    let locationText

    if (inPerson && virtual) {
      locationText = intl.formatMessage ? intl.formatMessage({ id: "eventCard.inPersonAndVirtual", defaultMessage: "In Person + Virtual Event"}): "In Person + Virtual Event"
    } else if (inPerson) {
      locationText = intl.formatMessage ? intl.formatMessage({ id: "eventCard.inPerson", defaultMessage: "In Person Event"}): "In Person Event"
    } else {
      locationText = intl.formatMessage ? intl.formatMessage({ id: "eventCard.virtualEvent", defaultMessage: "Virtual Event"}): "Virtual Event"
    }
    return (
      <div className="location-row row">
        <div className="col-1">
          <img src={pin} alt="pin" />
        </div>
        <div className="col-11">
          {locationText}
        </div>
      </div>
    )
  }

  const renderAddress = () => {

    return (
      <div className="address-container row">
        <div className="col-1">
          <img src={calendar} alt="calendar" className="d-none"/>
        </div>
        <div className="col-11">
          {venue}
          <br />
          {address}
          <br />
          {`${municipality}, ${region} ${postcode}`}
        </div>
      </div>
    )
  }

  const renderLinkout = () => {

    return (
      <div className="event-link">
        <a 
            href={eventLink}
            target="_blank"
            rel="noopener noreferrer"
        >
            <button className="btn btn-ae">
              <FormattedMessage
                id="eventCard.learnMore"
                defaultMessage="Learn More"
                description="Learn More"
              />    
            </button>
        </a>
      </div>
    )
  }

  return (
    <div className="EventCard evc-card">
      <div className="event-image-caontainer">
        <img src={categoryImage} alt={category} className="event-image"/>
      </div>
      <div className="EventCardTop">
        <div className="h2">
          {name}
        </div>
        <h3 className="hosted-by">
          {`${intl.formatMessage ? intl.formatMessage({ id: "eventCard.hostedBy", defaultMessage: "Hosted by"}): "Hosted by"} ${organizer}`}   
        </h3>
        {renderTimeRows()}
        {renderLocationRow()}
        {renderAddress()}
        {renderLinkout()}
      </div>
    </div>
  );
};

export default EventCard;

EventCard.propTypes = {
    event: PropTypes.object,
};