import React, { useContext } from "react";
import PropTypes from "prop-types";

import UserPrefsContext from "../../../../context/UserPrefs/UserPrefsContext";
import { FormatAsPercentRounded } from "../../../../utils/Helpers/Format";
import Range from "../../../../components/shared/InputElements/Range";
import { useIntl } from 'react-intl';

const descriptionFn = (val) => FormatAsPercentRounded(val);

const SlidePublicChargingPercentage = ({
  id = "public-charging-percentage-range",
  label,
  description = descriptionFn,
  ...rest
}) => {
  const intl = useIntl();
  const userPrefs = useContext(UserPrefsContext);

  return (
    <Range
      id={id}
      value={userPrefs.get("publicChargingPercentage")}
      label={intl.formatMessage
       ? intl.formatMessage({ id: "portionOfPublicCharging", defaultMessage: "Portion of Public Charging" })
       : "Portion of Public Charging"}
      rangeMin={0}
      rangeMax={100}
      rangeStep={10}
      description={description}
      tooltip={
       intl.formatMessage({ id: "portionOfPublicChargingTooltip", defaultMessage: "If you can’t charge at home, you will need to charge at paid public charging stations. Estimate what percentage of EV charging time would be at paid public charging stations instead of at home." })
      }
      handler={(e) =>
        userPrefs.set({ publicChargingPercentage: e.target.value })
      }
      {...rest}
    />
  );
};

export default SlidePublicChargingPercentage;

SlidePublicChargingPercentage.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  description: PropTypes.func,
};
