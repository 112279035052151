import React from "react";
import PropTypes from "prop-types";
import "./EVs.scss";
import { Link } from "react-router-dom";
import EVFilterControls from "./../../components/EVFilterControls/EVFilterControls";
import RangeVsCostGraph from "../../components/CostsCharts/CostVsRangeGraph";
import BlueTiles from "../../client_customizations/assets/images/blue_tiles.svg";
import WhiteTiles from "../../client_customizations/assets/images/white_tiles.svg";
import ActiveGraphIcon from "../../client_customizations/assets/images/Active_Graph_Icon.svg";
import { FormattedMessage } from "react-intl";

const EVsGraph = ({ electricVehicles, ip, uuid }) => {
  const time = new Date().toLocaleString();

  const renderOptions = (
    <>
      <EVFilterControls vehicles={electricVehicles} />
      <br />
      <p className="legal-disclaimer">
        <FormattedMessage
          id="evs.graph.disclaimer"
          defaultMessage="Vehicles displayed may not reflect actual availability. {companyName} does not endorse or recommend any specific vehicle or car manufacturer."
          description="Disclaimer title"
          values={{
            companyName: process.env.REACT_APP_FULL_COMPANY_NAME,
          }}
        />
      </p>
    </>
  );

  return (
    <section className="container">
      <div className="row mb-3">
        <div className="col-sm-12">
          <h1>
            <FormattedMessage
              id="evs.graph.title"
              defaultMessage="Electric Vehicles"
              description="Graph Title"
            />
          </h1>
          <p className="lead">
            <FormattedMessage
              id="evs.graph.compare"
              defaultMessage="Compare electric cars by EV range or price. Hover on the EV for more details."
              description="Graph comparison text"
            />
          </p>
        </div>
        <div className="d-block d-md-none mobileText">
          <div className="input-well">
            <p>
              <FormattedMessage
                id="evs.graph.mobile"
                defaultMessage="To see the Range vs. Cost Graph, please view this page on a desktop."
                description="Graph mobile option"
              />
            </p>
          </div>
        </div>
      </div>
      <div className="row justify-content-center evsToggle d-none d-lg-block">
        <Link to={`/vehicles`}>
          <button type="button" className="toggle-btn">
            <img
              className="evs-toggle-image evs-toggle-image-passive"
              alt="Blue Tile Icon"
              src={BlueTiles}
            ></img>
            <img
              className="evs-toggle-image evs-toggle-image-hover"
              alt="White Tile Icon"
              src={WhiteTiles}
            ></img>
            <FormattedMessage
              id="evs.graph.tiles"
              defaultMessage="Vehicle Tiles"
              description="Vehicle Tiles title"
            />
          </button>
        </Link>

        <Link to={`/comparison-graph`}>
          <button type="button" className="toggle-btn-active">
            <img
              className="evs-toggle-image"
              alt="Graph Icon"
              src={ActiveGraphIcon}
            ></img>
            <FormattedMessage
              id="evs.graph.rangeVsCost"
              defaultMessage="Range vs. Cost"
              description="Range vs. Cost title"
            />
          </button>
        </Link>
      </div>
      <div className="row">
        <div className="col-xl-3 col-lg-4 d-none d-lg-block">
          {renderOptions}
        </div>
        <div
          id="electric-vehicles-catalog"
          aria-live="polite"
          aria-atomic="true"
          role="region"
          style={{ position: "absolute", top: "-9999px" }}
        >
          <span>
            <FormattedMessage
              id="results.updated"
              defaultMessage="Results have been updated as of "
              description="Results have been updated as of "
            />
            {time}.
          </span>
        </div>
        <div
          className="col-xl-9 col-lg-8 col-sm-12 d-none d-lg-block"
          style={{ paddingLeft: 10 }}
        >
          <div className="row">
            <div className="col">
              <div className="pull-right"></div>
            </div>
          </div>
          <RangeVsCostGraph electricVehicles={electricVehicles} />
        </div>
      </div>
    </section>
  );
};

export default EVsGraph;

EVsGraph.propTypes = {
  electricVehicles: PropTypes.array,
  ip: PropTypes.string,
  uuid: PropTypes.string,
};
