import {
  COASTAL,
  MOUNTAIN,
  INLAND,
  DESERT,
} from "../../constants/chargingClimateZones";

export const getSummerLimit = (currentRateClimateZone) => {
  switch (currentRateClimateZone) {
    case COASTAL:
      return 351;

    case INLAND:
      return 406;

    case MOUNTAIN:
      return 530;

    case DESERT:
      return 620;

    default:
      return 351;
  }
};

export const getWinterLimit = (currentRateClimateZone) => {
  switch (currentRateClimateZone) {
    case COASTAL:
      return 359;

    case INLAND:
      return 374;

    case MOUNTAIN:
      return 503;

    case DESERT:
      return 425;

    default:
      return 359;
  }
};
