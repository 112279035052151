import calcMatchScore from "../MatchScore/calcMatchScore";
import cloneDeep from "lodash/cloneDeep";

const Private = {
  alphabeticalSort: function(evs, direction) {
    return evs.sort((a, b) => {
      let name = ev => `${ev.make} ${ev.model}  ${ev.trim} || ""}`;
      // let name = ev => `${ev.make} ${ev.model} ${ev.model_variant || ""}`;
      switch (direction) {
        case "asc":
          return name(a).localeCompare(name(b));
        case "desc":
          return name(b).localeCompare(name(a));
        default:
          return evs;
      }
    });
  },
  priceSort: function(evs, direction) {
    return evs.sort((a, b) => {
      let price = ev => parseInt(ev.msrp, 10);
      switch (direction) {
        case "asc":
          return price(a) - price(b);
        case "desc":
          return price(b) - price(a);
        default:
          return evs;
      }
    });
  },
  electricRangeSort: function(evs, direction) {
    return evs.sort((a, b) => {
      let range = ev => parseInt(ev.electric_range, 10);
      switch (direction) {
        case "asc":
          return range(a) - range(b);
        case "desc":
          return range(b) - range(a);
        default:
          return evs;
      }
    });
  },
  matchScoreSort: function(
    evs,
    direction,
    userPrefs
  ) {
    return evs.sort((a, b) => {
      let matchScoreA = calcMatchScore(
        a,
        userPrefs
      ).total;
      let matchScoreB = calcMatchScore(
        b,
        userPrefs
      ).total;

      let name = ev => `${ev.make} ${ev.model}  ${ev.trim} || ""}`;
      // let name = ev => `${ev.make} ${ev.model} ${ev.model_variant || ""}`;

      switch (direction) {
        case "asc":
          return matchScoreA - matchScoreB || name(a).localeCompare(name(b));
        case "desc":
          return matchScoreB - matchScoreA || name(a).localeCompare(name(b));
        default:
          return 1;
      }
    });
  }
};

const sortEVs = (
  evs,
  sortType,
  sortDirection,
  userPrefs
) => {
  const clonedEvs = cloneDeep(evs);

  switch (sortType) {
    case "alphabetical":
      return Private.alphabeticalSort(clonedEvs, sortDirection);
    case "price":
      return Private.priceSort(clonedEvs, sortDirection);
    case "electric_range":
      return Private.electricRangeSort(clonedEvs, sortDirection);
    case "match_score":
    default:
      return Private.matchScoreSort(
        clonedEvs,
        sortDirection,
        userPrefs
      );
  }
};

export default sortEVs;
