import DRSES from "./rates/DRSES";
import touDr1 from "./rates/touDr1";
import evTou5 from "./rates/evTou5";
import DR from "./rates/DR";
import DRSESDetails from "./rates/DRSESDetails";
import touDr1Details from "./rates/touDr1Details";
import evTou5Details from "./rates/evTou5Details";
import DRDetails from "./rates/DRDetails";

const rates = {
  "TOU-DR1": {
    id: "TOU-DR1",
    title: ["TOU-DR1"],
    subTitle: [""],
    data: touDr1,
    detailsComponent: touDr1Details,
  },
  DR: {
    id: "DR",
    title: ["DR"],
    data: DR,
    detailsComponent: DRDetails,
  },
  "DR-SES": {
    id: "DR-SES",
    title: ["DR-SES"],
    data: DRSES,
    detailsComponent: DRSESDetails,
  },
  "EV-TOU-5": {
    id: "EV-TOU-5",
    title: ["EV-TOU-5"],
    subTitle: [""],
    data: evTou5,
    detailsComponent: evTou5Details,
  },
};

export default rates;
