import React, { useState } from "react";
import PropTypes from "prop-types";

import ComparedVehicle from "./ComparedVehicle";
import CostOfOwnership from "../../components/CostOfOwnership/CostOfOwnership";

import SpeedOfCharge from "../../components/SpeedOfCharge/SpeedOfCharge";
import ElectricRange from "../../components/ElectricRange/ElectricRange";

import "./CompareVehicles.scss";

import ComparedCarDetails from "./ComparedCarDetails";
import ComparedVehicleToggle from "./ComparedVehicleToggle";

import { FormattedMessage, useIntl } from "react-intl";

const CompareVehicles = ({
  ip,
  uuid,
  props,
  electricVehicles,
  gasolineVehicles,
}) => {
  const [comparedVehicle1, setComparedVehicle1] = useState(null);
  const [comparedVehicle2, setComparedVehicle2] = useState(null);
  const [comparedVehicle3, setComparedVehicle3] = useState(null);

  const [selectedVehicleNumber, setSelectedVehicleNumber] = useState(0);

  const cars = [comparedVehicle1, comparedVehicle2, comparedVehicle3].filter(
    (car) => car !== null
  );

  const intl = useIntl();

  if (!electricVehicles || !gasolineVehicles) {
    return null;
  }

  gasolineVehicles = gasolineVehicles.filter((vehicle) => {
    return vehicle.images.length !== 0;
  });

  const title = {
    costAnalysis: intl.formatMessage({
      id: "graph.title.costAnalysis",
      defaultMessage: "Cost Analysis",
    }),
    speedOfCharge: intl.formatMessage({
      id: "graph.title.speedOfCharge",
      defaultMessage: "Speed of Charge",
    }),
    electricRange: intl.formatMessage({
      id: "graph.title.electricRange",
      defaultMessage: "Electric Range",
    }),
  };

  let vehicle1ContainerClasses = ["col-sm-4", "d-sm-block"];
  if (selectedVehicleNumber !== 0) vehicle1ContainerClasses.push("d-none");

  let vehicle2ContainerClasses = ["col-sm-4", "d-sm-block"];
  if (selectedVehicleNumber !== 1) vehicle2ContainerClasses.push("d-none");

  let vehicle3ContainerClasses = ["col-sm-4", "d-sm-block"];
  if (selectedVehicleNumber !== 2) vehicle3ContainerClasses.push("d-none");

  return (
    <div className="CompareVehicles">
      <div className="bgHolder" />
      <section>
        <div className="container titleContainer">
          <div className="row">
            <div className="col-sm-12">
              <ComparedVehicleToggle
                selectedVehicleNumber={selectedVehicleNumber}
                onChangeVehicleNumber={(number) =>
                  setSelectedVehicleNumber(number)
                }
                comparedVehicles={[
                  comparedVehicle1,
                  comparedVehicle2,
                  comparedVehicle3,
                ]}
              />

              <div className="text-center">
                <h2>
                  <FormattedMessage
                    id="compareVehicles"
                    defaultMessage="Compare New Vehicles"
                    description="Compare Vehicles Title"
                  />
                </h2>
                <p><FormattedMessage
                    id="compareVehicles.subTitle"
                    defaultMessage="Pick at least two vehicles to begin the comparison."
                    description="Pick at least two vehicles to begin the comparison."
                  /></p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <>
        <section className="section-white-background compared-vehicle-container">
          <div className="container">
            <div className="row">
              <div className={vehicle1ContainerClasses.join(" ")}>
                <ComparedVehicle
                  vehicle={comparedVehicle1}
                  setVehicle={setComparedVehicle1}
                  electricVehicles={electricVehicles}
                  gasolineVehicles={gasolineVehicles}
                />
                {comparedVehicle1 && (
                  <ComparedCarDetails vehicle={comparedVehicle1} index={1} />
                )}
              </div>

              <div className={vehicle2ContainerClasses.join(" ")}>
                <ComparedVehicle
                  vehicle={comparedVehicle2}
                  setVehicle={setComparedVehicle2}
                  electricVehicles={electricVehicles}
                  gasolineVehicles={gasolineVehicles}
                />
                {comparedVehicle2 && (
                  <ComparedCarDetails vehicle={comparedVehicle2} index={2} />
                )}
              </div>

              <div className={vehicle3ContainerClasses.join(" ")}>
                <ComparedVehicle
                  vehicle={comparedVehicle3}
                  setVehicle={setComparedVehicle3}
                  electricVehicles={electricVehicles}
                  gasolineVehicles={gasolineVehicles}
                />
                {comparedVehicle3 && (
                  <ComparedCarDetails vehicle={comparedVehicle3} index={3} />
                )}
              </div>
            </div>
          </div>
        </section>

        {cars.length > 1 ? (
          <>
            <section className="container gray">
              <div className="container">
                <CostOfOwnership
                  cars={cars}
                  title={title.costAnalysis}
                  comparison={"comparison"}
                />
              </div>
            </section>

            <section className="container gray">
              <div className="container">
                <SpeedOfCharge
                  cars={cars}
                  title={title.speedOfCharge}
                  comparison={"comparison"}
                />
              </div>
            </section>

            <section className="container gray">
              <div className="container">
                <ElectricRange
                  cars={cars}
                  title={title.electricRange}
                  comparison={"comparison"}
                />
              </div>
            </section>
          </>
        ) : null}
      </>
    </div>
  );
};

export default CompareVehicles;

CompareVehicles.propTypes = {
  electricVehicles: PropTypes.array,
};
