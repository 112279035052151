const setupGoogleMapsAutocomplete = (ref, id, callback) => {
  /* eslint-disable no-undef */
  if (!google) {
    return;
  }

  const input = document.getElementById(id);
  ref.current = new google.maps.places.Autocomplete(input, {
    types: ["geocode"],
  });
  ref.current.setComponentRestrictions({
    country: ["us"],
  });

  // From https://stackoverflow.com/a/33357188
  // Don't respond to enter keypress if the user is interacting with the
  // autocomplete dropdown menu
  google.maps.event.addDomListener(input, "keydown", (e) => {
    // If it's Enter
    if (e.keyCode === 13) {
      // Select all Google's dropdown DOM nodes (can be multiple)
      const googleDOMNodes = document.getElementsByClassName("pac-container");

      // Check if any of them are visible (using ES6 here for conciseness)
      const googleDOMNodeIsVisible = Array.from(googleDOMNodes).some(
        (node) => node.offsetParent !== null
      );

      // If one is visible - preventDefault
      if (googleDOMNodeIsVisible) e.preventDefault();
    }
  });
  /* eslint-enable no-undef */

  ref.current.setFields(["formatted_address"]);
  ref.current.addListener("place_changed", callback);
};

export default setupGoogleMapsAutocomplete;
