import React, { useContext } from 'react';
import ButtonGroup from '../../../../components/shared/InputElements/ButtonGroup';
import UserPrefsContext from '../../../../context/UserPrefs/UserPrefsContext';

const ButtonGroupRates = ({
  id = "btn-group-rates",
  label = "",
  rates,
  ...rest
}) => {
  const userPrefs = useContext(UserPrefsContext);

  const optionNames = [
    <span>{rates[0].title}<br/>(Default)</span>,
    ...rates.filter((rate, idx) => idx !== 0).map(rate => rate.title)
  ];
  return (
    <div className="ButtonGroupRates">
      <ButtonGroup
        id={id}
        value={userPrefs.get("currentRateId")}
        label={label}
        optionNames={optionNames}
        optionValues={rates.map(rate => rate.id)}
        handler={(e, selected) => userPrefs.set({ currentRateId: selected })}
        {...rest}
      />
    </div>
  )
};

export default ButtonGroupRates;
