import React from "react";
import PropTypes from "prop-types";
import "./ChargerCard.scss";

import typeIcon from "../../client_customizations/assets/images/icons/typeIcon.svg";
import cordLengthIcon from "../../client_customizations/assets/images/icons/cordLengthIcon.svg";
import socketIcon from "../../client_customizations/assets/images/icons/socketIcon.svg";
import timeIcon from "../../client_customizations/assets/images/icons/timeIcon.svg";

import { FormatAsTime } from "../../utils/Helpers/Format";
import { FormattedMessage, useIntl } from "react-intl";
import getTimeToFullCharge from "../../functions/chargers/ChargingData/getTimeToFullCharge";

const ChargerCard = ({ charger, selectedVehicle }) => {
  const intl = useIntl();
  if (!charger) return null;

  const make = charger.make ? charger.make : "";
  const model = charger.model ? charger.model : "";
  const price = charger.price ? charger.price : 0;
  const variant = charger.model_variant ? charger.model_variant : "";
  const type = charger.form_factor ? charger.form_factor : "";
  const length = charger.cord_length ? charger.cord_length : 0;
  const socket = charger.home_outlet ? charger.home_outlet : "";
  const details_link = charger.details_link ? charger.details_link : "";

  const image = charger.img ? charger.img.replace(/ /g, "%20") : "";
  const imgSrc = image;

  const chargerTypes = {
    Portable:
      intl.formatMessage &&
      intl.formatMessage({
        id: "homeChargers.portable",
        defaultMessage: "Portable",
      }),
    "Wall mounted":
      intl.formatMessage &&
      intl.formatMessage({
        id: "homeChargers.wallMounted",
        defaultMessage: "Mounted",
      }),
  };

  let altText = `${make} ${model}`;

  const renderChargingTimeWithSelectedVehicle = () => {
    if (!selectedVehicle) {
      return;
    }

    const timeToFullCharge = getTimeToFullCharge(selectedVehicle, charger);

    return (
      <div className="charger-row">
        <img src={timeIcon} alt="clock" />

        {typeof timeToFullCharge === "number" ? (
          <p>
            {FormatAsTime(timeToFullCharge)}
            &nbsp;
            <FormattedMessage
              id="homeChargers.toFullCharge"
              defaultMessage="to full chargeadsf"
              description="to full charge"
            />
          </p>
        ) : (
          <p>
            <FormattedMessage
              id={timeToFullCharge}
              defaultMessage="NA"
              description="error message for time to charge"
            />
          </p>
        )}
      </div>
    );
  };

  const renderTeslaWarning = (make) => {
    if (make !== "Tesla") {
      return <div style={{ height: "34px" }} />;
    }

    return (
      <div className="charger-compatibility-warning">
        <FormattedMessage
          id="homeChargersCard.teslaWarning"
          description="Warning for Tesla chargers"
        />
      </div>
    );
  };

  const renderChargerDetails = (
    <div className="renderRowOfData">
      <div className="dataRow">
        <div className="dataColumn">
          <div className="charger-row">
            <img src={cordLengthIcon} alt="double-sided arrow" />
            <p>
              {process.env.REACT_APP_METRIC_SYSTEM ? (
                <FormattedMessage
                  id="homeChargers.metercable"
                  defaultMessage="{length} m Cable"
                  description="cable"
                  values={{
                    length: length,
                  }}
                />
              ) : (
                <FormattedMessage
                  id="homeChargers.cable"
                  defaultMessage="{length} ft. Cable"
                  description="cable"
                  values={{
                    length: length,
                  }}
                />
              )}
            </p>
          </div>
          <div className="charger-row">
            <img src={socketIcon} alt="plug" />
            <p>
              {socket === "Hardwired" ? (
                intl.formatMessage &&
                intl.formatMessage({
                  id: "homeChargers.hardwired",
                  defaultMessage: "Hardwired",
                })
              ) : (
                <FormattedMessage
                  id="homeChargersCard.socket"
                  defaultMessage="{socket} Socket"
                  description="Socket"
                  values={{
                    socket: socket,
                  }}
                />
              )}
            </p>
          </div>
          <div className="charger-row">
            <img src={typeIcon} alt="wrench" />
            <p>{chargerTypes ? chargerTypes[type] : ""}</p>
          </div>
        </div>

        <img src={imgSrc} alt={altText} className="img-charger" />
      </div>

      {renderChargingTimeWithSelectedVehicle()}
      {renderTeslaWarning(make)}
    </div>
  );

  const renderCardBottom = (
    <div className="ChargerCardBottom">
      <div>
        <p className="charger-price-container">
          <span className="charger-price">${price} </span>
          <FormattedMessage
            id="homeChargersCard.beforeIncentives"
            defaultMessage="Before Incentives"
            description="Before Incentives"
          />
        </p>
      </div>
      <div className="buy-charger">
        {" "}
        {intl.formatMessage &&
          intl.formatMessage({
            id: "homeChargersCard.buy",
            defaultMessage: "View Details",
          })}{" "}
      </div>
    </div>
  );

  return (
    <>
      <a href={details_link} target="_blank" rel="noopener noreferrer">
        <div className="ChargerCardTop">
          <p className="make" style={{ maxWidth: "70%" }}>
            {make}
          </p>
          <p className="model">
            {model} {variant}
          </p>
          <div className="text-center"></div>
          {renderChargerDetails}
        </div>
        {renderCardBottom}
      </a>
    </>
  );
};

export default ChargerCard;

ChargerCard.propTypes = {
  charger: PropTypes.object,
  selectedVehicle: PropTypes.object,
};
