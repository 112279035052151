import React, { useContext } from "react";
import UserPrefsContext from "../../context/UserPrefs/UserPrefsContext";
import { FormattedMessage, useIntl } from 'react-intl';

const EventSortControls = () => {
  const intl = useIntl()
  const userPrefs = useContext(UserPrefsContext);
  const updateSort = value => {
    userPrefs.set({eventsSortType: value});
  };

  return (
    <div className="EVsSort">
      <form className="form-inline">
        <label htmlFor="vehicle-sort-dropdown" className="mr-2">
          <FormattedMessage
            id="evSort.title"
            defaultMessage="Sort By"
            description="EV Sort By Title"
          />        
        </label>
        <select
          id="vehicle-sort-dropdown"
          className="form-control"
          value={userPrefs.get("eventsSortType")}
          onChange={e => updateSort(e.target.value)}
        >
          <option value="date-asc">
            {intl.formatMessage ? intl.formatMessage({ id: "eventSort.dateLtH", defaultMessage: "Date (Soonest first)"}): "Date (Soonest first)"}
          </option>
          <option value="alphabetical-asc">
            {intl.formatMessage ? intl.formatMessage({ id: "evSort.alphabetical", defaultMessage: "Alphabetical"}) : "Alphabetical"}
          </option>
        </select>
      </form>
    </div>
  );
};

export default EventSortControls;