const fetchIncentives = async (params) => {
     // Remove null params
     Object.keys(params).forEach((key) => params[key] == null && delete params[key]);
     let url = new URL(`${process.env.REACT_APP_EV_INFO_API_HOST}/incentives`);
     let searchParams = new URLSearchParams(params);

     url.search = searchParams;
     const response = await window.fetch(url, {
          method: "GET",
          headers: {
               "Content-Type": "application/json",
               Accept: "application/json",
               Authorization: `Bearer ${process.env.REACT_APP_EV_INFO_TKN}`,
          },
     });

     const json = await response.json();
     const incentives = json.incentives;
     return incentives;
};

export default fetchIncentives;
