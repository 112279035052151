const ALL_STATIONS_TAB_NAME = "Charging Stations";
const ALL_STATIONS_TAB_URL = "/charging-stations";
export const ALL_STATIONS_TAB_ID = "tabbed-maps-all-stations-tab";

const ROUTE_MAP_TAB_NAME = "Route Planner";
const ROUTE_MAP_TAB_URL = "/map-a-route";
export const ROUTE_MAP_TAB_ID = "tabbed-maps-route-tab";

// const CHARGERS_CATALOG_TAB_NAME = "Shop Chargers";
// const CHARGERS_CATALOG_TAB_URL = "/home-chargers";
export const CHARGERS_CATALOG_TAB_ID = "tabbed-maps-dealer-catalog-tab";

// const ELECTRICIANS_TAB_NAME = "Electrician Finder";
// const ELECTRICIANS_TAB_URL = "/electricians";
export const ELECTRICIANS_TAB_ID = "tabbed-maps-electricians-tab";

export const MAP_TABS = [
  {
    id: ALL_STATIONS_TAB_ID,
    name: ALL_STATIONS_TAB_NAME,
    url: ALL_STATIONS_TAB_URL,
    title: process.env.REACT_APP_PAGES_CHARGING_TITLE,
    description: "Find local public charging stations",
    isEnabled: process.env.REACT_APP_PAGES_CHARGING_ENABLED,
  },
  {
    id: ROUTE_MAP_TAB_ID,
    name: ROUTE_MAP_TAB_NAME,
    url: ROUTE_MAP_TAB_URL,
    title: process.env.REACT_APP_PAGES_MAP_ROUTE_TITLE,
    description: "Map a route",
    isEnabled: process.env.REACT_APP_PAGES_MAP_ROUTE_ENABLED,
  },
];
