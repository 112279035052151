import { getSummerLimit, getWinterLimit } from "./getLimits";

const DR = (territory, currentRateClimateZone) => {
  const winterLimit = getWinterLimit(currentRateClimateZone);
  const summerLimit = getSummerLimit(currentRateClimateZone);
  return {
    name: "DR",
    title: "DR",
    rateElements: [
      {
        name: "Energy Charges",
        rateElementType: "BlockedTiersInMonths",
        rateComponents: [
          {
            charge: 0.45245,
            months: [0, 1, 2, 3, 4, 10, 11],
            hourStarts: [
              0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18,
              19, 20, 21, 22, 23,
            ],
            daysOfWeek: [0, 1, 2, 3, 4, 5, 6],
            min: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            max: [
              winterLimit,
              winterLimit,
              winterLimit,
              winterLimit,
              winterLimit,
              0,
              0,
              0,
              0,
              0,
              winterLimit,
              winterLimit,
            ],
            name: "Winter Tier 1",
          },
          {
            charge: 0.56969,
            months: [0, 1, 2, 3, 4, 10, 11],
            hourStarts: [
              0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18,
              19, 20, 21, 22, 23,
            ],
            min: [
              winterLimit,
              winterLimit,
              winterLimit,
              winterLimit,
              winterLimit,
              0,
              0,
              0,
              0,
              0,
              winterLimit,
              winterLimit,
            ],
            max: [
              "Infinity",
              "Infinity",
              "Infinity",
              "Infinity",
              "Infinity",
              0,
              0,
              0,
              0,
              0,
              "Infinity",
              "Infinity",
            ],
            daysOfWeek: [0, 1, 2, 3, 4, 5, 6],
            name: "Winter Tier 2",
          },
          {
            charge: 0.45245,
            months: [5, 6, 7, 8, 9],
            hourStarts: [
              0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18,
              19, 20, 21, 22, 23,
            ],
            daysOfWeek: [0, 1, 2, 3, 4, 5, 6],
            min: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            max: [
              0,
              0,
              0,
              0,
              0,
              summerLimit,
              summerLimit,
              summerLimit,
              summerLimit,
              summerLimit,
              0,
              0,
            ],
            name: "Summer Tier 1",
          },
          {
            charge: 0.56969,
            months: [5, 6, 7, 8, 9],
            hourStarts: [
              0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18,
              19, 20, 21, 22, 23,
            ],
            daysOfWeek: [0, 1, 2, 3, 4, 5, 6],
            min: [
              0,
              0,
              0,
              0,
              0,
              summerLimit,
              summerLimit,
              summerLimit,
              summerLimit,
              summerLimit,
              0,
              0,
            ],
            max: [
              0,
              0,
              0,
              0,
              0,
              "Infinity",
              "Infinity",
              "Infinity",
              "Infinity",
              "Infinity",
              0,
              0,
            ],
            name: "Summer Tier 2",
          },
        ],
      },
    ],
  };
};

export default DR;
