import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import ChargerCatalog from "../../components/ChargerCatalog/ChargerCatalog";
import ChargerSortControls from "./../../components/ChargerSortControls/ChargerSortControls";
import HomeChargersFilterControls from "./../../components/HomeChargersFilterControls/HomeChargersFilterControls";
import { FormattedMessage } from "react-intl";

import "./HomeChargers.scss";

const HomeChargers = ({ electricVehicles, homeChargers, ip, uuid }) => {
  const [makeOption, setMakeOption] = useState("");
  const [modelOption, setModelOption] = useState("");
  const [ev, setEv] = useState(null);
  const [useHomeChargers, setUseHomeCharges] = useState(homeChargers);

  useEffect(() => {
    setUseHomeCharges(homeChargers);
  }, [homeChargers]);

  const onChangeMake = (e) => {
    const newMakeType = e.target.value;

    setMakeOption(newMakeType);

    let arr = [];

    if (newMakeType !== "Tesla") {
      arr = [...homeChargers.filter((charger) => charger.make !== "Tesla")];
    } else {
      arr = [...homeChargers];
    }

    setUseHomeCharges(arr);
  };

  const onChangeModelType = (e) => {
    const newModelType = e.target.value;
    const vehicleHandle =
      e.target.options[e.target.selectedIndex].dataset.handle;

    if (newModelType === "Select a Model") {
      setModelOption(newModelType);
      return;
    }

    const selectedVehicle = electricVehicles.find((vehicle) => {
      return vehicle.handle === vehicleHandle;
    });

    setModelOption(newModelType);
    setEv(selectedVehicle);
  };

  const renderOptions = (
    <>
      <HomeChargersFilterControls
        homeChargers={useHomeChargers}
        makeOption={makeOption}
        onChangeMake={onChangeMake}
        modelOption={modelOption}
        onChangeModelType={onChangeModelType}
        electricVehicles={electricVehicles}
      />
    </>
  );

  if (!electricVehicles) {
    return null;
  }

  return (
    <section className="container HomeChargers">
      <div className="row mb-3">
        <div className="col-sm-12 text-center text-container">
          <h2>
            <FormattedMessage
              id="homeChargers.title"
              defaultMessage="Home Chargers"
              description="Home Chargers Title"
            />
          </h2>
          <p className="subtitle">
            <FormattedMessage
              id="homeChargers.subtitle"
              defaultMessage="Find options for Level 2 EV chargers that fit your home needs and EV preferences. Adjust the filters to personalize your results."
              description="Home Chargers Subtitle"
            />
          </p>
        </div>
      </div>

      <div className="row">
        {/* <div className="col-sm-12 d-block d-lg-none text-center">
          <ModalComponent buttonText="Filters" titleText="Refine Filters">
            {renderOptions}
          </ModalComponent>
          <br />
        </div> */}
        <div className="col-xl-3 col-lg-4 d-lg-block">
          {renderOptions}
        </div>

        <div
          className="col-xl-9 col-lg-8 col-sm-12"
          style={{ paddingLeft: 10, paddingRight: 10 }}
        >
          <div className="row">
            <div className="col">
              <div className="pull-right">
                <ChargerSortControls />
              </div>
            </div>
          </div>
          <ChargerCatalog homeChargers={useHomeChargers} selectedVehicle={ev} />
          <p className="homechargersCaption">
            <FormattedMessage
              id="homechargers.caption"
              defaultMessage="The home chargers listed on this site are not manufactured or endorsed by SDG&E."
              description="The home chargers listed on this site are not manufactured or endorsed by SDG&E."
            />
          </p>
        </div>
      </div>
    </section>
  );
};

export default HomeChargers;

HomeChargers.propTypes = {
  electricVehicles: PropTypes.array,
  homechargers: PropTypes.array,
  ip: PropTypes.string,
  uuid: PropTypes.string,
};
