import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import { Collapse, Navbar, NavbarToggler, Nav, NavItem } from "reactstrap";
import globeIcon from "../../../assets/images/icons/globe.svg";

import logo from "../../../assets/images/logo.svg";
import "./Header.scss";
import { FormattedMessage } from "react-intl";

const Header = ({ page, language, changeLanguage }) => {
  const [collapse, setCollapse] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
    const toggleDropdown = () => setDropdownOpen(!dropdownOpen);
    const dropdownRef = useRef();

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpen(false);
      }
    };

    document.addEventListener('click', handleOutsideClick);

    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, []);

  return (
    <div className="Header">
      <div className="container" style={{ padding: 0 }}>
        <Navbar expand="lg">
          <div>
            <a
              href="https://www.sdge.com/"
              target="_blank"
              rel="noopener noreferrer"
              className="navbar-brand"
            >
              <img
                className="header-logo"
                src={logo}
                alt="{process.env.REACT_APP_FULL_COMPANY_NAME}"
              />
            </a>
          </div>

          <NavbarToggler onClick={() => setCollapse(!collapse)} aria-label={collapse ? 'Close navigation' : 'Open navigation'} />
          <Collapse isOpen={collapse} navbar>
            <Nav className="ml-auto" navbar>
              <NavItem>
                <NavLink
                     title={page === "home" ? "Active Page" : null}
                  to="/"
                  active={false}
                  className={page === "home" ? "is-home" : "is-not-home"}
                >
                  <span>
                    <FormattedMessage
                      id="header.home"
                      defaultMessage="Home"
                      description="Home"
                    />
                  </span>
                  </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  title={page === "vehicles" ? "Active Page" : null}
                  to="/vehicles"
                >
                  <span>
                    <FormattedMessage
                      id="header.electricVehicles.v2"
                      defaultMessage="Explore EVs"
                      description="Electric Vehicles Header"
                    />
                  </span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  title={page === "incentives" ? "Active Page" : null}
                  to="/incentives"
                >
                  <span>
                    <FormattedMessage
                      id="header.incentives"
                      defaultMessage="Incentives"
                      description="Incentives"
                    />
                  </span>
                </NavLink>
              </NavItem>

              <NavItem>
                <NavLink
                  title={page === "electricians" ? "Active Page" : null}
                  to="/electricians"
                >
                  <span>
                    <FormattedMessage
                      id="header.electricians"
                      defaultMessage="Electricians"
                      description="Electricians"
                    />
                  </span>
                </NavLink>
              </NavItem>

              <NavItem>
                <NavLink
                  title={page === "home-chargers" ? "Active Page" : null}
                  to="/home-chargers"
                >
                  <span>
                    <FormattedMessage
                      id="header.homeChargers"
                      defaultMessage="Home Chargers"
                      description="Home Chargers"
                    />
                  </span>
                </NavLink>
              </NavItem>

              <NavItem>
                <NavLink
                  title={page === "rates" ? "Active Page" : null}
                  to="/savingscalculator"
                >
                  <span>
                    <FormattedMessage
                      id="header.rates"
                      defaultMessage="Savings Calculator"
                      description="Savings Calculator"
                    />
                  </span>
                </NavLink>
              </NavItem>
              <li className="language-toggle-container" ref={dropdownRef}>
                <div className="language-toggle" onClick={toggleDropdown}>
                  {globeIcon && <img src={globeIcon} alt="globe" />}
                  <span
                    className={`caret ${
                      dropdownOpen ? "caret-down" : "caret-up"
                    }`}
                  />
                </div>
                {dropdownOpen && (
                  <div className="language-dropdown">
                    <button
                      className={
                        language === "EN" ? "btn-lang active" : "btn-lang"
                      }
                      onClick={() => changeLanguage("EN")}
                    >
                      EN
                    </button>
                    <button
                      className={
                        language === "ES" ? "btn-lang active" : "btn-lang"
                      }
                      onClick={() => changeLanguage("ES")}
                    >
                      ES
                    </button>
                  </div>
                )}
              </li>
            </Nav>
          </Collapse>
        </Navbar>
      </div>
    </div>
  );
};

export default Header;

Header.propTypes = {
  page: PropTypes.string,
};
