import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import green from "../../assets/images/chargeway/green.png";
import blue from "../../assets/images/chargeway/blue.png";
import red from "../../assets/images/chargeway/red.png";

const createId = (string) =>
  string.toLowerCase().split(" ").join("_").replace("?", "");

const QuestionLink = ({ q }) => {
  return (
    <li>
      <a className="d-block" href={`#` + createId(q)}>
        {q}
      </a>
    </li>
  );
};

const RenderQuestion = ({ q, children }) => {
  return (
    <div>
      <h3 className="pt-4" id={createId(q)}>
        {q}
      </h3>
      {children}
    </div>
  );
};

const FAQ = ({ ip, uuid }) => {
  return (
    <section className="container">
      <div className="row">
        <div className="col text-center">
          <h1>{process.env.REACT_APP_FULL_COMPANY_NAME} EV FAQ</h1>
        </div>
      </div>
      <div className="row">
        <div className="col-md-4 d-none d-md-block">
          <div className="sticky mt-3">
            <div>
              <p className="h2">
                <a href="#vehicles">Vehicles</a>
              </p>
              <ul>
                <QuestionLink q="What is an Electric Vehicle?" />
                <QuestionLink q="Why should I drive an EV?" />
                <QuestionLink q="What should I consider before purchasing an Electric Vehicle?" />
                <QuestionLink q="How fast are EVs?" />
                <QuestionLink q="What maintenance do electric vehicles require?" />
                <QuestionLink q="Are EV batteries better than the use of gasoline in cars?" />
                <QuestionLink q="Are EVs actually better for the environment?" />
                <QuestionLink q="Why should I drive an EV?" />
                <QuestionLink q="What is an ICE car?" />
              </ul>
              <br />
              <p className="h2">
                <a href="#charging">Charging</a>
              </p>
              <ul>
                <QuestionLink q="Where do I charge my EV?" />
                <QuestionLink q="What are the different ways to charge your EV?" />
                <QuestionLink q="How long does charging take?" />
                <QuestionLink q="Do I need to buy a home charging station? If so, which one?" />
                <QuestionLink q="Wouldn't that increase the electric bill?" />
                <QuestionLink q="Does it take a long time to charge?" />
                <QuestionLink q="How would I charge my electric car on the go?" />
              </ul>
            </div>
          </div>
        </div>
        <div className="col-md-7">
          <div className="SimpleCopyPageBody">
            <h2 id="vehicles" className="mt-3">
              Vehicles
            </h2>
            <RenderQuestion q="What is an Electric Vehicle?">
              <p className="mb-0">
                An electric vehicle is any vehicle that can drive on
                electricity. If you can plug it in to fuel it, it is an electric
                vehicle. An all-electric vehicle (sometimes called a battery
                electric vehicle or a “BEV”) drives solely on electricity and
                does not use gasoline. What types of{" "}
                <Link to="/vehicles">electric vehicles are available</Link>?
                <br />
                Two kinds of electric vehicles are available:
              </p>
              <ul>
                <li>
                  Battery Electric Vehicle (BEV) - relies exclusively on a
                  battery to power the car. Say goodbye to the gas pump!
                </li>
                <li>
                  Plug-In Hybrid Electric Vehicles (PHEV) - uses both
                  electricity and gasoline. When driving, they use battery power
                  and switch to gasoline when the battery is empty.
                </li>
              </ul>
            </RenderQuestion>

            <RenderQuestion q="Why should I drive an EV?">
              <p>
                Easy to use, easy to maintain and easy to charge, electric
                vehicles are fun to drive and can save you money while helping
                the environment. EVs are cheaper, faster, and cleaner to operate
                plus they have much lower maintenance costs. If you are like
                most people commuting about 30 miles a day, then an EV can mean
                more money in your bank account and more time to enjoy your
                life.
              </p>
            </RenderQuestion>

            <RenderQuestion q="What should I consider before purchasing an Electric Vehicle?">
              <p className="mb-0">
                Similar to selecting a gasoline-powered car, choosing the
                electric vehicle that’s best for you depends on a number of
                different factors including your driving habits and personal
                preference. Here are some EV-specific factors to consider:
              </p>
              <ul className="mt-0">
                <li>
                  <strong>Total Range</strong>: How far will you travel? The
                  total range of current electric vehicles vary greatly—anywhere
                  from 40-350 miles.
                </li>
                <li>
                  <strong>Charging</strong>: Where will you charge? Where you
                  drive and how you'll charge your vehicle can help you decide
                  which electric vehicle will meet your needs.
                </li>

                <li>
                  <strong>Savings</strong>: How often does your gas-powered car
                  require maintenance? How often are you filling your gas tank?
                  EV drivers save on electric fuel and car maintenance, which
                  also saves time and helps the environment.
                </li>
              </ul>
              <p>
                If your daily commute is less than 40 miles, virtually every
                electric vehicle will be able to handle your daily driving
                without the need for fuel. If you want the ability to drive much
                farther, several all-electric vehicles can travel 100 to 300+
                miles on a charge. For the rare road trip, the drive can be done
                stopping at charging stations on the way to your destination.
              </p>
            </RenderQuestion>

            <RenderQuestion q="How fast are EVs?">
              <p>
                Fast! When you hit the accelerator EVs GO! EVs provide instant
                power (torque), providing faster acceleration than any
                gas-powered car you have had before, making EVs very fun to
                drive. With an electric motor, the driving experience is also
                quieter – and often more enjoyable – than a traditional
                gasoline-fueled car.
              </p>
            </RenderQuestion>

            <RenderQuestion q="What maintenance do electric vehicles require?">
              <p>
                With an EV, there are no oil changes, no spark plugs, no timing
                belts. No more expensive fixes over time.
              </p>
              <p>
                Since there are significantly fewer moving parts in an EV
                compared to a traditional vehicle, less ongoing preventative
                maintenance is needed (just tire rotations). Since there’s no
                exhaust, there’s also no muffler or catalytic converter. That
                means more money in your pocket.
              </p>
              <p>
                Brakes last longer on EVs too. When you take your foot off of
                the accelerator the electric motor slows the car down. It even
                uses the extra energy and stores it back in the battery giving
                your EV a little more range.
              </p>
              <p>
                All automakers also offer at least 8-year warranties on the
                batteries, if not longer.
              </p>
            </RenderQuestion>

            <RenderQuestion q="Are EV batteries better than the use of gasoline in cars?">
              <p>
                In many ways, yes. Unlike gasoline, EV batteries do not emit
                harmful carbon emissions into the atmosphere to pollute air and
                water. Also, once the batteries cannot be used for cars anymore,
                they can be reused for energy storage. Some car companies are
                already using blocks of old EV batteries to power buildings. At
                the end of their life, they are also recyclable.
              </p>
            </RenderQuestion>

            <RenderQuestion q="Are EVs actually better for the environment?">
              <p>
                Electric vehicles produce zero exhaust – no nasty chemicals that
                contribute to smog or other forms of air or water pollution.
                Switching cars to electric will dramatically improve local air
                quality, save millions in health costs from respiratory
                diseases, and reduce the long-term effects of climate change.
              </p>
            </RenderQuestion>

            <RenderQuestion q="What is an ICE car?">
              <p>
                You may hear or see the term “ICE” when referring to a
                gas-powered vehicle. “ICE” stands for Internal Combustion
                Engine. In addition to contributing to harmful greenhouse gases,
                these cars require more maintenance and have more moving parts
                than electric vehicles.
              </p>
            </RenderQuestion>

            <h2 className="mt-5" id="charging">
              Charging
            </h2>

            <RenderQuestion q="Where do I charge my EV?">
              <p>
                Do you have a house? If you have a garage or driveway, you can
                charge at home, so you can leave every morning with a “full
                tank”. With the range of most EVs, you don’t even need to charge
                every night! You can say goodbye to the gas pump and stop going
                to the gas station during the week, which saves you time and
                money. And unlike gasoline that needs a dedicated station,
                “electric fuel” is available in many more places. Your home,
                hotels, movie theatres, malls, grocery stores, vacation resorts,
                and more! You can even plug into a regular outlet.
              </p>
              <p>
                Even when you are not home, you can also charge at hundreds of
                charging ports available to the public, both in{" "}
                {process.env.REACT_APP_COMPANY_REGION} and beyond the city.
              </p>
              <p>
                Finding charging stations is easy. Simply download the{" "}
                <a
                  href="http://www.chargeway.net"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Chargeway app
                </a>
                , choose your EV and all of the charging stations for your car
                automatically appear.
              </p>
            </RenderQuestion>

            <RenderQuestion q="What are the different ways to charge your EV?">
              <p>
                Similar to gas cars that can use “Regular” or “Premium”, EVs can
                use different plug types. With the Chargeway app your EVs
                plug(s) are color coded to stations you can use for your
                selected vehicle. There are three plug colors:
              </p>
              <div className="row">
                <div className="col-sm-4">
                  <div className="text-center px-5 mb-2">
                    <img className="img-fluid" alt="green" src={green} />
                  </div>
                  <ul>
                    <li>
                      For Chevrolet, BMW, VW and other US / European brands
                    </li>
                    <li>Both Standard and Fast Charging</li>
                    <li>Often referred to as J1772 and CCS Combo</li>
                  </ul>
                </div>
                <div className="col-sm-4">
                  <div className="text-center px-5 mb-2">
                    <img className="img-fluid" alt="blue" src={blue} />
                  </div>
                  <ul>
                    <li>
                      For Nissan, Mitsubishi, Kia and some Asia market brands
                    </li>
                    <li>Fast Charging ONLY</li>
                    <li>Often referred to “CHAdeMO”</li>
                  </ul>
                </div>
                <div className="col-sm-4">
                  <div className="text-center px-5 mb-2">
                    <img className="img-fluid" alt="red" src={red} />
                  </div>
                  <ul>
                    <li>For Tesla vehicles ONLY</li>
                    <li>Both Standard and Fast Charging</li>
                    <li>
                      Often referred to as “Destination Chargers” and
                      “Superchargers”
                    </li>
                  </ul>
                </div>
              </div>
            </RenderQuestion>

            <RenderQuestion q="How long does charging take?">
              <p>
                There are seven different power levels for charging including
                both Standard and Fast Charging. Some EVs can only use standard
                charging, while others can use standard as well as fast
                charging.
              </p>
              <p className="lead">Standard Charging:</p>
              <p>Level 1</p>
              <ul>
                <li>
                  This level uses the charging cable provided with every EV
                </li>
                <li>Provides 3-5 miles per hour of charge</li>
              </ul>
              <p>Level 2</p>
              <ul>
                <li>
                  This level offers faster charging at home and is appropriate
                  for workplace charging
                </li>
                <li>Provides 20 to 60 miles per hour of charge</li>
              </ul>
              <p className="lead">Fast Charging:</p>
              <p>
                EVs capable of fast charging can use levels 3 through 7. Some
                EVs can faster charge at higher levels than other EVs. Download
                the{" "}
                <a
                  href="http://www.chargeway.net"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Chargeway app
                </a>{" "}
                to see which stations your vehicle can use and how fast you can
                charge. Typical fast charging speeds are:
              </p>
              <p>Level 3: Provides 80-100 miles per hour of charge</p>
              <p>Level 4: Provides 101-175 miles per hour of charge</p>
              <p>Level 5: Provides 200-350 miles per hour of charge</p>
              <p>Level 6: Provides 400-700 miles per hour of charge</p>
              <p>Level 7: Provides 1200-1400 miles per hour of charge</p>
            </RenderQuestion>

            <RenderQuestion q="Do I need to buy a home charging station? If so, which one?">
              <p>
                New plug-in vehicles come with Green 1 connectors that plug into
                standard 120-volt household outlets. So, you do not need to buy
                a home charging station as long as you have access to a standard
                outlet. However, for faster charging, many drivers buy a Green 2
                charging station to plug into a 240-volt outlet. All Green 2
                charging stations come with the standard Green connector that
                allow any plug-in vehicle to connect.
              </p>
              <p>
                Tesla vehicles comes with a Red 1 connector. A Tesla
                high-powered wall connector (Red 2) can be purchased through
                Tesla. Tesla owners can also use the standard charging Green
                adapter to connect to any standard level Green station.
              </p>
            </RenderQuestion>

            <RenderQuestion q="Wouldn't that increase the electric bill?">
              <p>
                Yes, but not by much. Electricity costs far less than gasoline
                for fuel. Compare and calculate your savings{" "}
                <Link to="/">here</Link>.
              </p>
            </RenderQuestion>

            <RenderQuestion q="Does it take a long time to charge?">
              <p>
                Green Level 1 and 2 stations are available at workplaces, retail
                businesses and other locations. In addition, Fast Charging
                stations are available for charging on the go within
                {process.env.REACT_APP_COMPANY_REGION} and beyond. To see where
                you can travel with your EV, download the{" "}
                <a
                  href="http://www.chargeway.net"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Chargeway app
                </a>{" "}
                and use the Trip Planner.
              </p>
            </RenderQuestion>

            <RenderQuestion q="How would I charge my electric car on the go?">
              <p>
                Level 1 and 2 are available at workplaces, retail businesses and
                other locations. In addition, a faster option called DC Fast
                Charge is available for charging on the go.
              </p>
            </RenderQuestion>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FAQ;

FAQ.propTypes = {
  ip: PropTypes.string,
  uuid: PropTypes.string,
};
