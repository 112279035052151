import React, { useContext } from "react";
import UserPrefsContext from "../../context/UserPrefs/UserPrefsContext";
import { FormattedMessage, useIntl } from 'react-intl';

const ChargerSortControls = () => {
  const intl = useIntl()
  const userPrefs = useContext(UserPrefsContext);
  const updateSort = value => {
    let newPrefs = {
      chargerSortType: value.split("-")[0],
      chargerSortDirection: value.split("-")[1]
    };
    userPrefs.set(newPrefs);
  };

  let sortOptionValue = [
    userPrefs.get("chargerSortType"),
    userPrefs.get("chargerSortDirection")
  ].join("-");

  return (
    <div className="EVsSort">
      <form className="form-inline">
        <label htmlFor="vehicle-sort-dropdown" className="mr-2">
          <FormattedMessage
            id="evSort.title"
            defaultMessage="Sort By"
            description="EV Sort By Title"
          />
        </label>
        <select
          id="vehicle-sort-dropdown"
          className="form-control-special"
          value={sortOptionValue}
          onChange={e => updateSort(e.target.value)}
        >
          <option value="price-asc">
            {intl.formatMessage ? intl.formatMessage({ id: "evSort.priceLtH", defaultMessage: "Price: Low to High"}): "Price: Low to High"}
          </option>
          <option value="price-desc">
            {intl.formatMessage ? intl.formatMessage({ id: "evSort.priceHtL", defaultMessage: "Price: High to Low"}): "Price: High to Low"}
          </option>
          <option value="charger_power-desc">
            {intl.formatMessage ? intl.formatMessage({ id: "evSort.chargerPower", defaultMessage: "Charger Power"}): "Charger Power"}
          </option>
          <option value="cord_length-asc">
            {intl.formatMessage ? intl.formatMessage({ id: "homeChargers.cordLengthLtH", defaultMessage: "Cord Length: Low to High"}) : "Cord Length: Low to High"}
          </option>
          <option value="cord_length-desc">
            {intl.formatMessage ? intl.formatMessage({ id: "homeChargers.cordLengthHtL", defaultMessage: "Cord Length: High to Low"}) : "Cord Length: High to Low"}
          </option>
        </select>
      </form>
    </div>
  );
};

export default ChargerSortControls;
