import React from "react";
import { FormattedMessage } from 'react-intl';
import "./styles.scss";

const evTou5Details = () => {
  return (
    <div>
      <h2 className="title">EV-TOU-5</h2>

      <p className="text">
      <FormattedMessage
            id="rateEVTOU5Paragraph1"
            defaultMessage="The EV-TOU-5 plan is for customers to charge electric vehicles at home.
            Pricing is based on how much electricity you use and when, with three
            pricing periods. In exchange for a basic service fee, we offer our
            lowest rates for overnight EV charging at home."
            description="rateEVTOU5Paragraph1"
          />
      </p>

      <p className="text">
      <FormattedMessage
            id="rateEVTOU5Paragraph2"
            defaultMessage="This plan could be a good fit if you can shift some of your electricity
            use away from the on-peak hours of 4-9 p.m. and you can regularly charge
            an electric vehicle during the super off-peak period late at night."
            description="rateEVTOU5Paragraph2"
          />
      </p>
    </div>
  );
};

export default evTou5Details;