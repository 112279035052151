import { getSummerLimit, getWinterLimit } from "./getLimits";

const touDr1 = (territory, currentRateClimateZone) => {
  const winterLimit = getWinterLimit(currentRateClimateZone);
  const summerLimit = getSummerLimit(currentRateClimateZone);
  return {
    name: "TOU-DR1",
    title: "TOU-DR1",
    rateElements: [
      {
        name: "Energy Charges",
        rateElementType: "BlockedTiersInMonths",
        rateComponents: [
          {
            charge: 0.63646,
            months: [0, 1, 2, 3, 4, 10, 11],
            hourStarts: [16, 17, 18, 19, 20],
            daysOfWeek: [0, 1, 2, 3, 4, 5, 6],
            min: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            max: [
              winterLimit,
              winterLimit,
              winterLimit,
              winterLimit,
              winterLimit,
              0,
              0,
              0,
              0,
              0,
              winterLimit,
              winterLimit,
            ],
            name: "Winter On Peak Tier 1",
          },
          {
            charge: 0.51922,
            months: [0, 1, 2, 3, 4, 10, 11],
            hourStarts: [16, 17, 18, 19, 20],
            daysOfWeek: [0, 1, 2, 3, 4, 5, 6],
            min: [
              winterLimit,
              winterLimit,
              winterLimit,
              winterLimit,
              winterLimit,
              0,
              0,
              0,
              0,
              0,
              winterLimit,
              winterLimit,
            ],
            max: [
              "Infinity",
              "Infinity",
              "Infinity",
              "Infinity",
              "Infinity",
              0,
              0,
              0,
              0,
              0,
              "Infinity",
              "Infinity",
            ],
            name: "Winter On Peak Tier 2",
          },
          {
            charge: 0.55194,
            months: [0, 1, 4, 10, 11],
            hourStarts: [6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 21, 22, 23],
            daysOfWeek: [1, 2, 3, 4, 5],
            min: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            max: [
              winterLimit,
              winterLimit,
              0,
              0,
              winterLimit,
              0,
              0,
              0,
              0,
              0,
              winterLimit,
              winterLimit,
            ],
            name: "Winter Off Peak Weekdays Tier 1",
          },
          {
            charge: 0.51922,
            months: [0, 1, 4, 10, 11],
            hourStarts: [6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 21, 22, 23],
            daysOfWeek: [1, 2, 3, 4, 5],
            min: [
              winterLimit,
              winterLimit,
              0,
              0,
              winterLimit,
              0,
              0,
              0,
              0,
              0,
              winterLimit,
              winterLimit,
            ],
            max: [
              "Infinity",
              "Infinity",
              0,
              0,
              "Infinity",
              0,
              0,
              0,
              0,
              0,
              "Infinity",
              "Infinity",
            ],
            name: "Winter Off Peak Weekdays Tier 2",
          },
          {
            charge: 0.55194,
            months: [2, 3],
            hourStarts: [6, 7, 8, 9, 14, 15, 21, 22, 23],
            daysOfWeek: [1, 2, 3, 4, 5],
            min: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            max: [0, 0, winterLimit, winterLimit, 0, 0, 0, 0, 0, 0, 0, 0],
            name: "Winter Off Peak Weekdays Part 2 Tier 1",
          },
          {
            charge: 0.51922,
            months: [2, 3],
            hourStarts: [6, 7, 8, 9, 14, 15, 21, 22, 23],
            daysOfWeek: [1, 2, 3, 4, 5],
            min: [0, 0, winterLimit, winterLimit, 0, 0, 0, 0, 0, 0, 0, 0],
            max: [0, 0, "Infinity", "Infinity", 0, 0, 0, 0, 0, 0, 0, 0],
            name: "Winter Off Peak Weekdays Part 2 Tier 2",
          },
          {
            charge: 0.55194,
            months: [0, 1, 2, 3, 4, 10, 11],
            hourStarts: [14, 15, 21, 22, 23],
            daysOfWeek: [0, 6],
            min: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            max: [
              winterLimit,
              winterLimit,
              winterLimit,
              winterLimit,
              winterLimit,
              0,
              0,
              0,
              0,
              0,
              winterLimit,
              winterLimit,
            ],
            name: "Winter Off Peak Weekend Tier 1",
          },
          {
            charge: 0.51922,
            months: [0, 1, 2, 3, 4, 10, 11],
            hourStarts: [14, 15, 21, 22, 23],
            daysOfWeek: [0, 6],
            min: [
              winterLimit,
              winterLimit,
              winterLimit,
              winterLimit,
              winterLimit,
              0,
              0,
              0,
              0,
              0,
              winterLimit,
              winterLimit,
            ],
            max: [
              "Infinity",
              "Infinity",
              "Infinity",
              "Infinity",
              "Infinity",
              0,
              0,
              0,
              0,
              0,
              "Infinity",
              "Infinity",
            ],
            name: "Winter Off Peak Weekend Tier 2",
          },
          {
            charge: 0.52741,
            months: [0, 1, 4, 10, 11],
            hourStarts: [0, 1, 2, 3, 4, 5],
            daysOfWeek: [1, 2, 3, 4, 5],
            min: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            max: [
              winterLimit,
              winterLimit,
              0,
              0,
              winterLimit,
              0,
              0,
              0,
              0,
              0,
              winterLimit,
              winterLimit,
            ],
            name: "Winter Super Off Peak Weekdays Tier 1",
          },
          {
            charge: 0.51922,
            months: [0, 1, 4, 10, 11],
            hourStarts: [0, 1, 2, 3, 4, 5],
            daysOfWeek: [1, 2, 3, 4, 5],
            min: [
              winterLimit,
              winterLimit,
              0,
              0,
              winterLimit,
              0,
              0,
              0,
              0,
              0,
              winterLimit,
              winterLimit,
            ],
            max: [
              "Infinity",
              "Infinity",
              0,
              0,
              "Infinity",
              0,
              0,
              0,
              0,
              0,
              "Infinity",
              "Infinity",
            ],
            name: "Winter Super Off Peak Weekdays Tier 2",
          },
          {
            charge: 0.52741,
            months: [2, 3],
            hourStarts: [0, 1, 2, 3, 4, 5, 10, 11, 12, 13],
            daysOfWeek: [1, 2, 3, 4, 5],
            min: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            max: [0, 0, winterLimit, winterLimit, 0, 0, 0, 0, 0, 0, 0, 0],
            name: "Winter Super Off Peak Weekdays Part 2 Tier 1",
          },
          {
            charge: 0.51922,
            months: [2, 3],
            hourStarts: [0, 1, 2, 3, 4, 5, 10, 11, 12, 13],
            daysOfWeek: [1, 2, 3, 4, 5],
            min: [0, 0, winterLimit, winterLimit, 0, 0, 0, 0, 0, 0, 0, 0],
            max: [0, 0, "Infinity", "Infinity", 0, 0, 0, 0, 0, 0, 0, 0],
            name: "Winter Super Off Peak Weekdays Part 2 Tier 2",
          },
          {
            charge: 0.52741,
            months: [0, 1, 2, 3, 4, 10, 11],
            hourStarts: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13],
            daysOfWeek: [0, 6],
            min: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            max: [
              winterLimit,
              winterLimit,
              winterLimit,
              winterLimit,
              winterLimit,
              0,
              0,
              0,
              0,
              0,
              winterLimit,
              winterLimit,
            ],
            name: "Winter Super Off Peak Weekend Tier 1",
          },
          {
            charge: 0.51922,
            months: [0, 1, 2, 3, 4, 10, 11],
            hourStarts: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13],
            daysOfWeek: [0, 6],
            min: [
              winterLimit,
              winterLimit,
              winterLimit,
              winterLimit,
              winterLimit,
              0,
              0,
              0,
              0,
              0,
              winterLimit,
              winterLimit,
            ],
            max: [
              "Infinity",
              "Infinity",
              "Infinity",
              "Infinity",
              "Infinity",
              0,
              0,
              0,
              0,
              0,
              "Infinity",
              "Infinity",
            ],
            name: "Winter Super Off Peak Weekend Tier 2",
          },
          {
            charge: 0.83325,
            months: [5, 6, 7, 8, 9],
            hourStarts: [16, 17, 18, 19, 20],
            daysOfWeek: [0, 1, 2, 3, 4, 5, 6],
            min: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            max: [
              0,
              0,
              0,
              0,
              0,
              summerLimit,
              summerLimit,
              summerLimit,
              summerLimit,
              summerLimit,
              0,
              0,
            ],
            name: "Summer On Peak Tier 1",
          },
          {
            charge: 0.51922,
            months: [5, 6, 7, 8, 9],
            hourStarts: [16, 17, 18, 19, 20],
            daysOfWeek: [0, 1, 2, 3, 4, 5, 6],
            min: [
              0,
              0,
              0,
              0,
              0,
              summerLimit,
              summerLimit,
              summerLimit,
              summerLimit,
              summerLimit,
              0,
              0,
            ],
            max: [
              0,
              0,
              0,
              0,
              0,
              "Infinity",
              "Infinity",
              "Infinity",
              "Infinity",
              "Infinity",
              0,
              0,
            ],
            name: "Summer On Peak Tier 2",
          },
          {
            charge: 0.51979,
            months: [5, 6, 7, 8, 9],
            hourStarts: [6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 21, 22, 23],
            daysOfWeek: [1, 2, 3, 4, 5],
            min: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            max: [
              0,
              0,
              0,
              0,
              0,
              summerLimit,
              summerLimit,
              summerLimit,
              summerLimit,
              summerLimit,
              0,
              0,
            ],
            name: "Summer Off Peak Weekdays Tier 1",
          },
          {
            charge: 0.51922,
            months: [5, 6, 7, 8, 9],
            hourStarts: [6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 21, 22, 23],
            daysOfWeek: [1, 2, 3, 4, 5],
            min: [
              0,
              0,
              0,
              0,
              0,
              summerLimit,
              summerLimit,
              summerLimit,
              summerLimit,
              summerLimit,
              0,
              0,
            ],
            max: [
              0,
              0,
              0,
              0,
              0,
              "Infinity",
              "Infinity",
              "Infinity",
              "Infinity",
              "Infinity",
              0,
              0,
            ],
            name: "Summer Off Peak Weekdays Tier 2",
          },
          {
            charge: 0.51979,
            months: [5, 6, 7, 8, 9],
            hourStarts: [14, 15, 21, 22, 23],
            daysOfWeek: [0, 6],
            min: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            max: [
              0,
              0,
              0,
              0,
              0,
              summerLimit,
              summerLimit,
              summerLimit,
              summerLimit,
              summerLimit,
              0,
              0,
            ],
            name: "Summer Off Peak Weekend Tier 1",
          },
          {
            charge: 0.51922,
            months: [5, 6, 7, 8, 9],
            hourStarts: [14, 15, 21, 22, 23],
            daysOfWeek: [0, 6],
            min: [
              0,
              0,
              0,
              0,
              0,
              summerLimit,
              summerLimit,
              summerLimit,
              summerLimit,
              summerLimit,
              0,
              0,
            ],
            max: [
              0,
              0,
              0,
              0,
              0,
              "Infinity",
              "Infinity",
              "Infinity",
              "Infinity",
              "Infinity",
              0,
              0,
            ],
            name: "Summer Off Peak Weekend Tier 2",
          },
          {
            charge: 0.35515,
            months: [5, 6, 7, 8, 9],
            hourStarts: [0, 1, 2, 3, 4, 5],
            daysOfWeek: [1, 2, 3, 4, 5],
            min: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            max: [
              0,
              0,
              0,
              0,
              0,
              summerLimit,
              summerLimit,
              summerLimit,
              summerLimit,
              summerLimit,
              0,
              0,
            ],
            name: "Summer Super Off Peak Weekdays Tier 1",
          },
          {
            charge: 0.51922,
            months: [5, 6, 7, 8, 9],
            hourStarts: [0, 1, 2, 3, 4, 5],
            daysOfWeek: [1, 2, 3, 4, 5],
            min: [
              0,
              0,
              0,
              0,
              0,
              summerLimit,
              summerLimit,
              summerLimit,
              summerLimit,
              summerLimit,
              0,
              0,
            ],
            max: [
              0,
              0,
              0,
              0,
              0,
              "Infinity",
              "Infinity",
              "Infinity",
              "Infinity",
              "Infinity",
              0,
              0,
            ],
            name: "Summer Super Off Peak Weekdays Tier 2",
          },
          {
            charge: 0.35515,
            months: [5, 6, 7, 8, 9],
            hourStarts: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13],
            daysOfWeek: [0, 6],
            min: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            max: [
              0,
              0,
              0,
              0,
              0,
              summerLimit,
              summerLimit,
              summerLimit,
              summerLimit,
              summerLimit,
              0,
              0,
            ],
            name: "Summer Super Off Peak Weekend Tier 1",
          },
          {
            charge: 0.51922,
            months: [5, 6, 7, 8, 9],
            hourStarts: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13],
            daysOfWeek: [0, 6],
            min: [
              0,
              0,
              0,
              0,
              0,
              summerLimit,
              summerLimit,
              summerLimit,
              summerLimit,
              summerLimit,
              0,
              0,
            ],
            max: [
              0,
              0,
              0,
              0,
              0,
              "Infinity",
              "Infinity",
              "Infinity",
              "Infinity",
              "Infinity",
              0,
              0,
            ],
            name: "Summer Super Off Peak Weekend Tier 2",
          },
        ],
      },
    ],
  };
};

export default touDr1;
