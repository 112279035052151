const filterEvents = (events, filterParams) => {
  const {
      eventLocation,
      eventCategories,
  } = filterParams || {};

  const allowedCategories = Object.keys(eventCategories).filter(
    (category) => eventCategories[category]
  );

  return events
    .filter(
      (event) => {
        if (eventLocation === "All Locations") {
          return true
        }
        if (eventLocation === "In Person") {
          return event.is_in_person
        }
        if (eventLocation === "Virtual") {
          return event.is_virtual
        }
        return true
      }
    )
    .filter(
      ({event_category}) => allowedCategories.length === 0 || eventCategories[event_category]
    )

};

export default filterEvents;
