const getEquivalent = async (handle, zipCode) => {
    let url = new URL(
        `${process.env.REACT_APP_EV_INFO_API_HOST}/vehicles/${handle}?postcode=${zipCode}`
      );

      const response = await window.fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${process.env.REACT_APP_EV_INFO_TKN}`
        }
      });

      const json = await response.json();
      const equivalentGasVehicle = json?.vehicle?.equivalent_gas_vehicle
      
      return equivalentGasVehicle

}

export default getEquivalent