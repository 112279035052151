import { RESIDUALVALUES } from "../../data/residualValues/RESIDUALVALUES"

export default (purchaseMethod, vehicle) => {
    let resaleFactor = .6

    if (purchaseMethod === "lease") {
        if (RESIDUALVALUES[vehicle.handle] !== undefined) {
          resaleFactor = RESIDUALVALUES[vehicle.handle]
        } else if (RESIDUALVALUES[vehicle.make] !== undefined) {
          resaleFactor = RESIDUALVALUES[vehicle.make]
        }
    }

    return resaleFactor
}