import React from "react";
import PropTypes from "prop-types";

import "./Homepage.scss";
import HomepageBigPromise from "./components/HomepageBigPromise";
import HomepageCards from "./components/HomepageCards";
import VehicleCarousel from "../../../components/VehicleCarousel/VehicleCarousel";
import HomepageIncentives from "./components/HomepageIncentives";
import HomepageZipCode from "./components/HomepageZipCode";
import LoadingSpinner from "../../../components/LoadingSpinner/LoadingSpinner";

const Homepage = ({ homePageBannerImage, electricVehicles, incentives }) => {

       const renderDetails = electricVehicles && incentives ?
       (
         <>
           <VehicleCarousel
             electricVehicles={electricVehicles}
           />
           <HomepageZipCode />
           <HomepageIncentives incentives={incentives} />
         </>
       ) : (
         <section className="container">
           <LoadingSpinner />
         </section>
       );

  return (
    <>
      <HomepageBigPromise homePageBannerImage={homePageBannerImage} />
      <HomepageCards />
      {renderDetails}
    </>
  );
};

export default Homepage;

Homepage.propTypes = {
  homePageBannerImage: PropTypes.string,
  electricVehicles: PropTypes.array,
  incentives: PropTypes.array,
  ip: PropTypes.string,
  uuid: PropTypes.string,
};
