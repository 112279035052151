import React, { useContext } from "react";
import PropTypes from "prop-types";
import UserPrefsContext from "../../../context/UserPrefs/UserPrefsContext";

import { FormatAsPercentRounded } from "../../../utils/Helpers/Format";

import Range from "../../shared/InputElements/Range";
import { useIntl } from "react-intl";

const descriptionFn = (val) => FormatAsPercentRounded(val);

const SlideElectricMilesPortionForPhev = ({
  id = "portion-electric-for-phev-range",
  description = descriptionFn,
  ...rest
}) => {
  const intl = useIntl();
  const userPrefs = useContext(UserPrefsContext);
  const label = intl.formatMessage({
    id: "ev.electricPortion",
    defaultMessage: "Portion of Electric Miles",
  });
  const tooltip = intl.formatMessage({
    id: "ev.electricPortionTooltip",
    defaultMessage:
      "Estimate what percentage of your driving miles will be completely electric. If you recharge daily and drive less per day than your preferred EV’s all-electric range, your percentage will be near 100%. If you’re not sure of your electric portion percentage, select 50% .",
  });

  return (
    <Range
      id={id}
      value={userPrefs.get("electricMilesPortionForPhev")}
      label={label}
      rangeMin={0}
      rangeMax={100}
      rangeStep={10}
      description={description}
      tooltip={tooltip}
      handler={(e) =>
        userPrefs.set({ electricMilesPortionForPhev: e.target.value })
      }
      {...rest}
    />
  );
};

export default SlideElectricMilesPortionForPhev;

SlideElectricMilesPortionForPhev.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  description: PropTypes.func,
  tooltip: PropTypes.string,
};
