import React, { useContext } from "react";
import UserPrefsContext from "../../context/UserPrefs/UserPrefsContext";
import Uuid from "../../utils/Uuid/Uuid";

import ToolTip from "../shared/ToolTip/ToolTip";
import "./HomeChargersFilterControls.scss";

import SlideHomeChargerPrice from "../InputComponents/SlideHomeChargerPrice/SlideHomeChargerPrice";
import SlideHomeChargerCordLength from "../InputComponents/SlideHomeChargerCordLength/SlideHomeChargerCordLength";
import { FormattedMessage, useIntl } from "react-intl";
import sixTwenty from "../../client_customizations/assets/images/icons/6-20.svg";
import sixThirty from "../../client_customizations/assets/images/icons/6-30.svg";
import tenThirty from "../../client_customizations/assets/images/icons/10-30.svg";
import fourteenThirty from "../../client_customizations/assets/images/icons/14-30.svg";
import sixFifty from "../../client_customizations/assets/images/icons/6-50.svg";
import fourteenFifty from "../../client_customizations/assets/images/icons/14-50.svg";
import fourteenSixty from "../../client_customizations/assets/images/icons/14-60.svg";

const HomeChargersFilterControls = ({
  makeOption,
  onChangeMake,
  modelOption,
  onChangeModelType,
  electricVehicles,
  homeChargers,
}) => {
  const intl = useIntl();
  const userPrefs = useContext(UserPrefsContext);

  const formFactorFilterData = userPrefs.get("chargerFormFactorFilters");
  const chargerWifi = userPrefs.get("chargerWifiFilter");
  const chargerTypeFilterData = userPrefs.get("chargerTypeFilters");

  const socketTypeWords = {
    "6-20": sixTwenty,
    "6-30": sixThirty,
    "10-30": tenThirty,
    "14-30": fourteenThirty,
    "6-50": sixFifty,
    "14-50": fourteenFifty,
    "14-60": fourteenSixty,
  };

  const updateChargerWifiFilter = (selectedKey) => {
    const newFilters = Object.keys(chargerWifi).reduce(function (acc, key) {
      // Toggle selected key, set all others to false
      acc[key] = key === selectedKey ? !chargerWifi[key] : false;
      return acc;
    }, {});

    userPrefs.set({
      chargerWifiFilter: newFilters,
    });
  };

  const renderChargerWifiFilter = Object.keys(chargerWifi).map(
    (filterKey, i) => {
      let filterName = "";
      switch (filterKey) {
        case "yes":
          filterName = intl.formatMessage({ id: "yes", defaultMessage: "Yes" });
          break;
        case "no":
          filterName = intl.formatMessage({ id: "no", defaultMessage: "No" });
          break;
        default:
      }

      return (
        <button
          className={
            chargerWifi[filterKey]
              ? "btn active text-center"
              : "btn text-center"
          }
          key={i}
          type="button"
          onClick={() => updateChargerWifiFilter(filterKey)}
        >
          {filterName}
        </button>
      );
    }
  );

  const updateFormFactorFilter = (key) => {
    let newFilters = Object.assign({}, formFactorFilterData);
    newFilters[key] = !newFilters[key];

    userPrefs.set({
      chargerFormFactorFilters: newFilters,
    });
  };

  const renderedFormFactorFilters = Object.keys(formFactorFilterData).map(
    (filterKey, i) => {
      const filterName = `${filterKey
        .charAt(0)
        .toUpperCase()}${filterKey.substr(1)}`;

      return (
        <button
          className={formFactorFilterData[filterKey] ? "btn active" : "btn"}
          key={i}
          type="button"
          aria-pressed={!!formFactorFilterData[filterKey]}
          onClick={() => updateFormFactorFilter(filterKey)}
        >
          <span className="socket-text">
            {filterName === "Hardwired"
              ? intl.formatMessage({
                  id: "homeChargers.hardwired",
                  defaultMessage: "Hardwired",
                })
              : filterName}
          </span>
          {filterName === "Hardwired" ? null : (
            <img
              className="socket-image"
              src={socketTypeWords[filterName]}
              alt={
                filterName === "Hardwired"
                  ? intl.formatMessage({
                      id: "homeChargers.hardwired",
                      defaultMessage: "Hardwired",
                    })
                  : filterName
              }
            />
          )}
        </button>
      );
    }
  );

  const updateChargerTypeFilter = (selectedKey) => {
    const newFilters = Object.keys(chargerTypeFilterData).reduce(function (
      acc,
      key
    ) {
      // Toggle selected key, set all others to false
      acc[key] = key === selectedKey ? !chargerTypeFilterData[key] : false;
      return acc;
    },
    {});

    userPrefs.set({
      chargerTypeFilters: newFilters,
    });
  };

  const renderedChargerTypeFilters = Object.keys(chargerTypeFilterData).map(
    (filterKey, i) => {
      let filterName = "";
      switch (filterKey) {
        case "mounted":
          filterName = intl.formatMessage({
            id: "homeChargers.wallMounted",
            defaultMessage: "Mounted",
          });
          break;
        case "portable":
          filterName = intl.formatMessage({
            id: "homeChargers.portable",
            defaultMessage: "Portable",
          });
          break;
        default:
      }

      return (
        <button
          className={
            chargerTypeFilterData[filterKey]
              ? "btn active text-center"
              : "btn text-center"
          }
          key={i}
          type="button"
          onClick={() => updateChargerTypeFilter(filterKey)}
        >
          {filterName}
        </button>
      );
    }
  );

  const vehicleMakeOptions = (vehicles) => {
    let vehicleMakes = new Set();
    vehicles.forEach((vehicle) => {
      if (vehicle) {
        vehicleMakes.add(vehicle.make);
      }
    });

    vehicleMakes = [...vehicleMakes];

    vehicleMakes.sort((a, b) => {
      return a.localeCompare(b);
    });

    return vehicleMakes.map((make) => {
      return (
        <option value={make} key={make}>
          {make}
        </option>
      );
    });
  };

  const vehicleModelOptions = (vehicles, makeOption) => {
    if (makeOption) {
      vehicles = vehicles.filter((vehicle) => {
        return vehicle.make === makeOption;
      });

      vehicles.sort((a, b) => {
        return a.model.localeCompare(b.model);
      });

      return vehicles.map((vehicle) => {
        const modelAndTrim = vehicle.trim
          ? `${vehicle.model} ${vehicle.trim}`
          : vehicle.model;
        return (
          <option
            value={modelAndTrim}
            key={Uuid()}
            data-handle={vehicle.handle}
          >
            {modelAndTrim}
          </option>
        );
      });
    }
  };

  return (
    <>
      <div className="input-well hcFilterControls">
        <p className="h2">
          <FormattedMessage
            id="homechargerEvfilter"
            defaultMessage="Basic Filters"
            description="Basic Filters"
          />
        </p>
        <form>
          <div className="form-group">
            <div className="btn-block-container">
              <SlideHomeChargerPrice />
            </div>
          </div>
          <div className="form-group">
            <div className="btn-block-container">
              <SlideHomeChargerCordLength />
            </div>
          </div>

          <label className="label-range mb">
            <FormattedMessage
              id="homechargerChargerType"
              defaultMessage="CHARGER TYPE"
              description="CHARGER TYPE"
            />
          </label>
          <div className="form-group">
            <span className="label-style">
              <FormattedMessage
                id="homeChargers.wifi"
                defaultMessage="WiFi Connectivity"
                description="WiFi Connectivity"
              />
            </span>
            <div className="btn-grid-container">{renderChargerWifiFilter}</div>
          </div>
          <div className="form-group">
            <span className="label-style">
              <FormattedMessage
                id="vehicle.type"
                defaultMessage="Type"
                description="Type"
              />
            </span>
            <div className="btn-grid-container">
              {renderedChargerTypeFilters}
            </div>
          </div>
          <div className="form-group">
            <span className="label-style">
              <FormattedMessage
                id="homeChargers.socket"
                defaultMessage="Socket"
                description="Socket"
              />
              <ToolTip
                message={intl.formatMessage({
                  id: "homeChargers.socketTooltip",
                  defaultMessage:
                    "Different model chargers have different plug patterns. These plug patterns correspond to the way prongs fit into your wall outlet. If you're not sure which is best for your home/garage, check with a qualified electrician.",
                })}
                id="socket_tooltip"
              />
            </span>
            <div className="btn-grid-container">
              {renderedFormFactorFilters}
            </div>
          </div>
        </form>
      </div>
      <div className="input-well hcFilterControls mt">
        <p className="h2">
          <FormattedMessage
            id="evfilter.carTitle"
            defaultMessage="EV Preference"
            description="EV Preference"
          />
        </p>
        <form>
          <div className="form-group">
            <label>{intl.formatMessage({
                  id: "make",
                  defaultMessage: "Make",
                })}</label>
            <select
              className="form-control select-iid form-control-special large"
              value={makeOption}
              onChange={onChangeMake}
              aria-label={intl.formatMessage({
                id: "homeChargers.selectMake",
                defaultMessage: "Select a Make",
              })}
            >
              <option defaultValue="">
                {intl.formatMessage({
                  id: "homeChargers.selectMake",
                  defaultMessage: "Select a Make",
                })}
              </option>
              {vehicleMakeOptions(electricVehicles)}
            </select>
          </div>
          <div className="form-group">
            <label>{intl.formatMessage({
                  id: "model",
                  defaultMessage: "Model",
                })}</label>
            <select
              className="form-control select-iid form-control-special large"
              value={modelOption}
              onChange={onChangeModelType}
              aria-label={intl.formatMessage({
                id: "homeChargers.selectModel",
                defaultMessage: "Select a Model",
              })}
            >
              <option defaultValue="">
                {intl.formatMessage({
                  id: "homeChargers.selectModel",
                  defaultMessage: "Select a Model",
                })}
              </option>
              {vehicleModelOptions(electricVehicles, makeOption)}
            </select>
          </div>
        </form>
      </div>
      <div className="filter-controls-disclaimer">
              <p>
                     <FormattedMessage
                            id="homeChargers.disclaimer"
                            defaultMessage="Charger manufacturers, want your charger featured here? Reach out to {email}."
                            description="Home Chargers Disclaimer"
                            values={{
                                   email: <a href="mailto:chargerdata@jdpa.com" className="disclaimer-link">chargerdata@jdpa.com</a>
                            }}
                     />
              </p>
       </div>
    </>
  );
};

export default HomeChargersFilterControls;
