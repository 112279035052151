import { RateCalculator, LoadProfile } from "@bellawatt/electric-rate-engine";

RateCalculator.shouldValidate = false;

class RateCostCalculator {
  constructor({
    typicalHomeLoadProfile,
    currentRate,
    currentMonthlyBill,
    homeChargingKwh,
    chargingPatternLoadProfile,
  }) {
    const { year, data: homeData } = typicalHomeLoadProfile;

    this._homeLoadProfile = new LoadProfile(homeData, { year })
      .scale()
      .toAverageMonthlyBill(currentMonthlyBill, currentRate.data);

    this._electricVehicleLoadProfile = new LoadProfile(
      chargingPatternLoadProfile.data(year),
      { year }
    )
      .scale()
      .toTotalKwh(homeChargingKwh);

    this._totalCustomerLoadProfile = this._homeLoadProfile.aggregate(
      this._electricVehicleLoadProfile
    );

    this._currentRate = currentRate;
  }

  annualCosts(rate) {
    return {
      home: this._homeCost(rate),
      ev: this._evCost(rate),
      total: this._totalCost(rate),
    };
  }

  _homeCost(rate) {
    return new RateCalculator({
      ...rate.data,
      loadProfile: this._homeLoadProfile,
    }).annualCost();
  }

  _evCost(rate) {
    return !rate.data.separatelyMetered
      ? this._totalCost(rate) - this._homeCost(rate)
      : new RateCalculator({
          ...rate.data,
          loadProfile: this._electricVehicleLoadProfile,
        }).annualCost();
  }

  _totalCost(rate) {
    // TODO: cache these for each rate to cut down on calculating twice
    if (rate.data.separatelyMetered)
      return this._homeCost(rate) + this._evCost(rate);

    return new RateCalculator({
      ...rate.data,
      loadProfile: this._totalCustomerLoadProfile,
    }).annualCost();
  }
}

export default RateCostCalculator;
