import FR_CUSTOM from "../../client_customizations/context/translations/fr_client_custom";

const FR = {
    "home": "Page d'accueil",
    "browseElectricVehicles": "Catalogue de véhicules électriques",
    "learnMore": "En savoir plus",
    "chargingMap.disclaimer": "Cette carte montre les bornes de recharge publiques de niveau 2 (en bleu) and 3 (en vert) au Canada. Pour en savoir plus sur les niveaux de charge, visitez {website}",
    "scheduleTestDrive": "Arranger un essai routier",
    "compareVehicles": "Comparaison de véhicules",
    "incentives": "Subventions",
    "chargingStations": "Bornes de recharge",
    "homepage.welcomeSub": "Trouvez le VÉ qui correspond à vos besoins!",
    "homepage.linkCard.browseElectricVehicles": "Consulter le catalogue des véhicules électriques",
    "homepage.linkCard.discoverIncentives": "Découvrir mes subventions",
    "homepage.linkCard.locateChargingStations": "Trouver des bornes de recharge",
    "homepage.vehicleCarousel.title": "Trouvez le VÉ qui correspond à vos besoins.",
    "homepage.vehicleCarousel.subTitle": "Comparez le coût du véhicule de votre choix à celui d’un véhicule à essence similaire. Un VÉ peut vous permettre d’économiser de l’argent, du fait qu’il a un coût d’entretien moins élevé qu’un véhicule qui fonctionne entièrement à l’essence.",
    "homepage.vehicleCarousel.findEVButton": "Trouvez le VÉ qui correspond à vos besoins",
    "homepage.incentives.title": "Explorez les possibilités d'incitatifs et de crédits d'impôt pour les VÉ",
    "homepage.incentives.subTitle": "Découvrez ce que vous pourriez économiser en choisissant un véhicule électrique (achat ou location). Les incitatifs sont personnalisés en fonction du lieu de résidence.",
    "footer.allRights": "Tous droits sont réservés.",
    "footer.poweredBy": "Propulsé par Zappyride",
    "vehicle.kilometers": "km",
    "vehicle.miles": "kilomètres",
    "personalizeIncentives": "Calculez l'incitatif personnalisé",
    "vehicle.plugInHybrid": "Hybride rechargeable",
    "vehicle.allElectric": "Tout électrique",
    "vehicle.type.sedan": "Sedan",
    "vehicle.type.hatchback": "Berline",
    "vehicle.type.coupe": "Coupé",
    "vehicle.type.crossover": "Crossover",
    "vehicle.type.minivan": "Monospace",
    "vehicle.type.suv": "SUV",
    "vehicle.type.wagon": "Wagon",
    "vehicle.type.truck": "Camion",
    "vehicle.gas": "Essence",
    "vehicle.afterIncentives": "APRÈS SUBVENTIONS",
    "vehicle.msrp": "PDSF",
    "vehicle.estimatedIncentives": "INCITATIFS ESTIMÉS",
    "vehicle.type": "TYPE",
    "vehicle.fuelType": "TYPE DE CARBURANT",
    "vehicle.batterySize": "TAILLE DE LA BATTERIE",
    "vehicle.electricRange": "AUTONOMIE électrique",
    "vehicle.timeToChargeLvlTwo": "TEMPS DE RECHARGE - NIVEAU 2",
    "vehicle.kilometersPerThirtyFastCharge": "KILOMÈTRES PAR 30 MIN DE CHARGE RAPIDE",
    "vehicle.coTwoEmissions": "RÉDUCTION DES ÉMISSIONS DE CO2",
    "vehicle.phev": "Électricité et essence",
    "vehicle.bev": "Électricité",
    "total": "Total",
    "description": "Description",
    "detailedCalculations": "calculs détaillés",
    "charging": "Recharge",
    "emissions": "Émissions",
    "source": "Source",
    "vehicles": "Véhicules",
    "make": "Marque",
    "model": "Modèle",
    "all": "Toutes marques",
    "age": "Âge",
    "zipcode": "Code postal",
    "show": "Voir",
    "hide": "Cacher",
    "electricVehicles": "Véhicules électriques",
    "evs.welcomeSub": "Comparez les VÉ en fonction de leur autonomie, de leur prix ou du score de correspondance personnalisé. {lineBreak} Cliquez sur un VÉ pour obtenir plus de détails, y compris le coût total par rapport à un véhicule à essence similaire.",
    "evs.buttonMatchScoreAndFilters": "Score de compatibilité et filtres",
    "evs.matchScoreAndFilters": "Affiner le score et les filtres",
    "evs.matchScoreOptions": "Recherche avancée",
    "evs.roundTripCommute": "Trajet aller-retour",
    "evs.budgetAfterIncentives": "Budget après incitatifs",
    "evs.minSeats": "Nombre de sièges",
    "evs.seats": "sièges",
    "evs.homeChargingAvailability": "Disponibilité de la recharge à domicile",
    "evs.homeChargingAvailabilityTooltip": "Plus de véhicules électriques vous conviendront si vous pouvez recharger rapidement à la maison.",
    "evs.levelOne": "Niveau 1",
    "evs.levelTwo": "Niveau 2",
    "evs.noCharging": "Pas de charge",
    "evs.helpMeChoose": "Aidez-moi à choisir",
    "evs.chargingAvailability": "Disponibilité de charge",
    "evs.disclaimer": "Les véhicules affichés peuvent ne pas refléter la disponibilité réelle. {company} ne cautionne ni ne recommande aucun constructeur de véhicule ou véhicule en particulier.",
    "evSort.title": "Trier par",
    "evSort.matchScore": "Score de compatibilité",
    "evSort.electricRange": "Autonomie électrique",
    "evSort.priceLtH": "Prix ​​croissant",
    "evSort.priceHtL": "Prix décroissant",
    "evSort.chargerPower": "Puissance du chargeur",
    "evSort.alphabetical": "Alphabétique",
    "chargingWizard.carOvernight": "Où garerez-vous votre voiture pendant la nuit?",
    "chargingWizard.parkingGarage": "Garage privé ou place de parking",
    "chargingWizard.onTheStreet": "Dans la rue",
    "chargingWizard.publicParking": "Parking public",
    "chargingWizard.electricianInstall": "Un électricien serait-il autorisé à installer une borne de recharge?",
    "chargingWizard.overnightParking": "Pourriez-vous garer la voiture pendant la nuit dans un endroit équipé d'une borne de recharge?",
    "chargingWizard.powerOutlet": "Y a-t-il une prise de courant ordinaire à proximité de l'endroit où vous garez votre voiture?",
    "chargingWizard.chargingAvailability": ".",
    "chargingWizard.noChargingAvailability": "pas de disponibilité de borne de recharge",
    "chargingWizard.youWouldHave": "Vous pourrez charger au {resultText}",
    "chargingWizard.useThisValue": "Utilisez cette valeur",
    "evfilter": "Filtre",
    "evfilter.fuel": "Carburant",
    "evfilter.fuelTooltip": "Les véhicules entièrement électriques utilisent uniquement l'électricité. Les hybrides rechargeables peuvent utiliser l'électricité et l'essence.",
    "evfilter.type": "Modèle",
    "evCard.totalRange": "Autonomie totale",
    "evCard.electricRange": "Autonomie electrique",
    "evCard.matchScore": "SCORE DE COMPATILIBITE",
    "evCard.seeDetails": "Voir les détails",
    "evCard.seeElectricVehicle": "Voir les détails",
    "evCard.estimatedIncentives": "Incitatifs estimés",
    "pricePanels.cash": "Espèces",
    "pricePanels.loan": "Prêt",
    "pricePanels.lease": "Location",
    "pricePanels.loanPayment": "Paiement du prêt",
    "pricePanels.downPayment": "Acompte",
    "pricePanels.leasePayment": "Paiement du leasing",
    "pricePanels.perMonth": "/ mois",
    "pricePanels.downPaymentDescription": "10% du PDSF plus impôts",
    "pricePanels.firstLeasePayment": "PREMIER PAIEMENT DE LEASING",
    "pricePanels.incentivesForLease": "Subventions à la location",
    "pricePanels.seeAllIncentives": "Voir tous les incitaitfs",
    "pricePanels.seeAllCosts": "Voir tous les coûts",
    "ev.jumbotron.viewAllCars": "Voir toutes les véhicules",
    "ev.carDetails.fastChargingTooltip": "Vitesse du chargeur rapide:",
    "ev.carDetails.levelTwoChargerSpeedTooltip": "Vitesse du chargeur de niveau 2:",
    "ev.carDetails.batterySizeTooltip": "Un kilowattheure (kWh) suffit pour parcourir 1 à 6 kilomètres, selon le véhicule. Une batterie d'ordinateur portable typique peut contenir moins de 0,1 kWh.",
    "ev.carDetails.treesPlanted": "Arbres plantés",
    "ev.carDetails.gasolineSaved": "Essence économisée",
    "ev.carDetails.compareText": "Nous avons sélectionné la {gasVehicle} en fonction de sa proximité avec la {vehicle} en fonction de sa marque, de sa taille et de son prix.",
    "ev.cardetails.calc.dcRate": "Vitesse de charge rapide",
    "ev.cardetails.calc.EPA": "Évaluation d'efficacité NRC",
    "ev.cardetails.calc.EPAMetric": "Évaluation d'efficacité NRC",
    "ev.cardetails.calc.chargingRate": "Vitesse de recharge",
    "ev.cardetails.calc.vehicleBatteryCapacity": "Capacité de la batterie du véhicule",
    "ev.cardetails.calc.maxACIntake": "Prise de courant maximale du véhicule",
    "ev.cardetails.calc.lvlTwoChargingRate": "Vitesse de recharge de niveau 2",
    "ev.cardetails.calc.minOflvl2AC": "Vitesse de recharge minimum de niveau 2 et prise CA maximale du véhicule",
    "ev.cardetails.calc.lvl2FullCharge": "Temps de recharge complète pour le niveau 2",
    "ev.cardetails.calc.kmDrivenPerYr": "Kilomètres parcourus par an",
    "ev.cardetails.calc.userInput": "Entrée utilisateur",
    "ev.cardetails.calc.lPerKm": "Litres au 100 kilomètres",
    "ev.cardetails.calc.portionElectric": "Portion de conduite électrique",
    "ev.cardetails.calc.portionElectricSource": "Entrée utilisateur. Pour un véhicule hybride, estime la portion de kilomètres parcourus avec la batterie électrique au lieu de l'essence.",
    "ev.cardetails.calc.litersUsed": "Litres utilisés",
    "ev.cardetails.calc.litersUsedFormula": "Kilomètres parcourus * (1 - Portion de la conduite électrique) * L / 100 km",
    "ev.cardetails.calc.litersSaved": "Litres économisés",
    "ev.cardetails.calc.litersSavedFormula": "Litres utilisés par un véhicule à essence équivalent - Litres utilisés par VE",
    "ev.cardetails.calc.electricEmissionsMetric": "Émissions électriques en kg",
    "ev.cardetails.calc.electricEmissionsLbsMwhMetric": "Émissions électriques en Kg / MWh",
    "ev.cardetails.calc.CFCG": "Guide de consommation de carburant au Canada",
    "ev.cardetails.calc.gasolineEmissionsYrMetric": "Émissions d'essence en kg de CO2 / an",
    "ev.cardetails.calc.gasolineEmissionsMetric": "Émissions d'essence en kg par litre",
    "ev.cardetails.calc.emissionsReudctions": "Réductions d'émissions",
    "ev.cardetails.calc.kgCo2PerYr": "kg de CO2 par an",
    "ev.cardetails.calc.gasolineEmissionskg": "Émissions d'essence en kg",
    "ev.cardetails.calc.arborDayFoundation": "Fondation Arbor Day",
    "ev.cardetails.calc.emissionsPerTree": "Émissions économisées par arbre",
    "ev.cardetails.calc.electricityEmissionsMetric": "Émissions d'électricité en CO2 kg / MWh",
    "ev.compareGasVehicle": "Comparez la {car} à un véhicule à essence similaire, la {gasCar}",
    "ev.purchaseMethod": "Méthode d'achat",
    "ev.purchaseMethodToolTip": "Dans un leasing, vous devez rendre la voiture après la période de location. Dans le cas du financement par emprunt, vous gardez la voiture après le remboursement du prêt.",
    "ev.cash": "En espèces",
    "ev.loan": "Prêt",
    "ev.lease": "Leasing",
    "ev.kilometersDrivenAnnually": "Kilomètres parcourus par an",
    "ev.electricPortion": "Portion électrique pour hybride rechargeable",
    "ev.electricPortionTooltip": "Un véhicule électrique hybride rechargeable peut utiliser à la fois l'électricité et l'essence. Il est généralement plus avantageux d'utiliser l'électricité.",
    "ev.yearsOwnership": "Années de possession / location",
    "ev.gasolinePrice": "Prix de l'essence ($/L)",
    "ev.interestRate": "Taux d'intérêt",
    "ev.electricityRateCalc": "Certaines juridictions canadiennes offrent actuellement un allégement COVID des tarifs d'électricité. Les tarifs et prix publiés sur ce site Web sont basés sur des sources accessibles au public et adaptés à votre code postal. Veuillez consulter votre fournisseur d'électricité local pour confirmer les prix actuels dans votre région.",
    "assumption.salesTax": "TVA",
    "assumption.gasolinePrice": "Prix ​​de l'essence",
    "assumption.downPayment": "Acompte",
    "assumption.electricityRate": "Prix de l'électricité",
    "assumption.downPaymentValue": "10% du PDSF + taxes",
    "ev.incentives.title": "Jusqu'à {savings} de crédits d'impôt et de remises sont potentiellement disponibles pour la {car}",
    "ev.reviews": "Avis sur le Web",
    "chargingMap.updateZipcode": "Mettre à jour le code postal",
    "chargingMap.errorZipcode": "Erreur: {workingZipcode} n'est pas un code postal valide",
    "chargingMap.showFullListOfStations": "la liste complète des stations de recharge",
    "chargingMap.public": "Stations publiques",
    "chargingMap.businessGovernment": "Installé par une entreprise ou un gouvernement",
    "chargingMap.highPowerStations": "Stations de puissance élevée",
    "chargingMap.DCChargers": "Charge rapide ",
    "chargingMap.otherStations": "Autres types de stations",
    "chargingMap.privateStations": "Stations privées",
    "chargingMap.dataDisclaimer": "Données fournies par Alternative Fuel Data Center. La carte peut ne pas refléter la dernière disponibilité des bornes de recharge.",
    "graph.assumptions": "Hypothèses",
    "graph.yourEV": "Votre sélection VE",
    "graph.similarGasVehicle": "Véhicule à essence similaire",
    "graph.graphValues": "valeurs du graphique",
    "graph.moreExpensive": "plus",
    "graph.cheaper": "moins",
    "graph.toOwnOver": "sur une durée de ",
    "graph.years": " ans",
    "graph.year": "Année",
    "vehicle.trees": "arbres",
    "vehicle.view": "Détails de la ",
    "vehicle.more": "plus",
    "vehicle.fewer": "moins",
    "vehicle.greater": "plus élevée",
    "graph.costOfOwnership.title": "Coût de propriété",
    "graph.costOfOwnership.subTitle": "La {carName} est {style} {costDeltaText} {costDeltaTextEnding}",
    "graph.costOfOwnership.subTitleThreeCars": "Le {carName} est le moins cher à posséder depuis {yearsOfOwnership} ans",
    "graph.costOfOwnership.descriptionRow.vehicle": "Véhicule",
    "graph.costOfOwnership.descriptionRow.maintenance": "Maintenance",
    "graph.costOfOwnership.descriptionRow.insurance": "Assurance",
    "graph.costOfOwnership.descriptionRow.electricity": "Électrique",
    "graph.costOfOwnership.descriptionRow.gasoline": "Essence",
    "graph.costOfOwnership.totalRow": "Total",
    "graph.costOfOwnership.description": "La description",
    "graph.costOfOwnership.chart.vehicle": "Véhicle après subvention, revente",
    "graph.costOfOwnership.chart.electricity": "Électrique",
    "graph.costOfOwnership.chart.gasoline": "Essence",
    "graph.costOfOwnership.chart.maintenance": "Maintenance",
    "graph.costOfOwnership.chart.insurance": "Assurance",
    "graph.title.costAnalysis": "Analyse des coûts",
    "graph.title.speedOfCharge": "Vitesse de charge",
    "graph.title.electricRange": "Autonomie électrique",
    "graph.monthlyCostToFill.title": "Coût de remplissage mensuel",
    "compareVehicles.subTitle": "Voir tous les véhicule>",
    "compareVehicles.selectFuel": "Sélectionnez un type de carburant",
    "compareVehicles.selectMake": "Sélectionnez une marque",
    "compareVehicles.selectModel": "Sélectionnez un modèle",
    "compareVehicles.pickTwo": "Choisissez au moins 2 véhicules pour commencer la comparaison",
    "compareVehicles.disclaimer": "Toutes les marques et tous les modèles ne sont pas disponibles.",
    "graph.costToBreakdown": "Coût de la panne",
    "graph.costToBreakdown.subTitle": "Comparaison du coût cumulatif sur la durée de vie de la {car1} par rapport à la {car2}",
    "graph.costToBreakdown.subTitleThreeCars": "Comparaison du coût cumulatif sur la durée de vie de la {car1}, {car2} et {car3}",
    "graph.costToBreakdown.note": "Remarque: le tableau suppose que les véhicules sont achetés en espèces.",
    "graph.speedOfCharge.levelTwoChargingSpeed": "Vitesse du chargeur de niveau 2",
    "graph.speedOfCharge.fastChargingSpeed": "Vitesse de charge rapide",
    "graph.speedOfCharge.kmLevelTwoCharger": "de par heure avec un chargeur de niveau 2",
    "graph.speedOfCharge.thirtyFastChargeMetric": "par 30 minutes de charge rapide",
    "graph.speedOfCharge.subTitle": "La {car} accumule {number} {frenchMiles} {costDeltaText} {subTitleText}",
    "graph.speedOfCharge.kilometersLevelTwoChargerThreeCars": "charge le plus de kilomètres par heure avec un chargeur de niveau 2",
    "graph.speedOfCharge.thirtyFastChargeThreeCarsMetric": "charge le plus de kilomètres par 30 minutes de charge rapide",
    "graph.speedOfCharge.subTitleThreeCars": "La {car}, {subTitleText}",
    "graph.speedOfCharge.mphLevelTwoChargerTitle": "Quantité de la charge par heure de charge de niveau 2",
    "graph.speedOfCharge.fastChargerTitle": "Quantité de la charge pour 30 minutes de charge rapide",
    "graph.electricRange.subTitleThreeCars": "La {car} a la plus longue autonomie électrique",
    "graph.electricRange.subTitleMetric": "L'autonomie électrique de la {car} est de {costDeltaText} de {number} kilomètres",
    "graph.electricRange.gasolineRange": "Autonomie essence",
    "graph.title.gasolineRange": "Autonomie essence",
    "graph.electricRange.assumption": "Gamme basée sur les estimations de l'EPA",
    "graph.monthlyCostToFill.subTitle": "Le plein mensuel de la {carName} coûte {style}, {costDeltaText}",
    "graph.monthlyCostToFill.subTitleThreeCars": "Le {carName} est le moins cher à remplir tous les mois",
    "incentives.title": "Subventions pour les véhicules électriques",
    "incentives.subTitle": "Vous pourriez être éligible pour certaines subventions, y compris des remises, des crédits d'impôt et divers autres avantages. Les incitations sont personnalisées en fonction de votre lieu de résidence.",
    "incentives.locationTooltip": "De nombreuses subventions dépendent de l'endroit où vous vivez. Entrez votre code postal pour vous aider à déterminer les incitations disponibles là où vous vivez.",
    "incentives.location": "Emplacement",
    "incentives.vehicleType": "Type de véhicule",
    "incentives.vehicleTypeTooltip": "Certaines subventions dépendent de la voiture que vous achetez.",
    "incentives.whichVehicle": "Quel véhicule comptez-vous acheter?",
    "incentives.tradeIn": "Échange",
    "incentives.planClunker": "ENVISAGEZ-VOUS DE RENDRE UN VIEUX VÉHICULE?",
    "incentives.incentiveEligibility": "Mettre à jour la liste des incitatifs",
    "chargingStation.title": "Bornes de recharge publiques",
    "ev.showSources.portionElectricSource": "Entrée utilisateur. Pour un véhicule hybride, estime la portion de kilomètres parcourus avec la batterie électrique au lieu de l'essence.",
    "ev.showSources.resaleValue": "Valeur de revente",
    "ev.showSources.resaleValueSource": "Fonction du PDSF du véhicule et kilométrage à vie de 100 000 kilomètres, en supposant une dépréciation exponentielle négative avec la distance où la valeur de revente est de 60% après 36 000 kilomètres",
    "ev.showSources.totalVehicleCost": "Coût total du véhicule",
    "ev.showSources.cashFormula": "PDSF du véhicule * (1 + TVA) - Subventions - Valeur de revente",
    "ev.showSources.assumption": "Hypothèse:",
    "ev.showSources.loanAmount": "Montant du prêt",
    "ev.showSources.loanFormula": "PDSF * (1 + taxe de vente) - Acompte",
    "ev.showSources.monthlyPayments": "Mensualités",
    "ev.showSources.monthlyPaymentsSource": "Fonction financière du montant du prêt, du taux d'intérêt et des mois de détention",
    "ev.showSources.monthsOfOwnership": "Mois de possession",
    "ev.showSources.totalMonthlyPayments": "Paiements mensuels totaux",
    "ev.showSources.totalMonthlyPaymentsSource": "Paiement mensuel * Mois de possession",
    "ev.showSources.totalLoanFormula": "Acompte + Total des paiements mensuels - Subventions - Valeur de revente",
    "ev.showSources.capitalizedIncentives": "Subventions capitalisées",
    "ev.showSources.capitalizedIncentivesSource": "Subventions capitalisées dans le bail",
    "ev.showSources.capitalizedCost": "Coût capitalisé",
    "ev.showSources.capitalizedCostSource": "PDSF - Acompte - Subventions capitalisées",
    "ev.showSources.monthlyDepreciationCost": "Coût d'amortissement mensuel",
    "ev.showSources.monthlyDepreciationCostSource": "(Coût capitalisé - Valeur de revente) / Mois de possession",
    "ev.showSources.monthlyFinancingCost": "Coût de financement mensuel",
    "ev.showSources.monthlyFinancingCostSource": "(Coût capitalisé + valeur de revente) * Facteur monétaire",
    "ev.showSources.moneyFactor": "Facteur monétaire",
    "ev.showSources.moneyFactorSource": "Équivalent de l'entrée utilisateur de 3,50%",
    "ev.showSources.monthlyLeasePayment": "Paiement mensuel du leasing",
    "ev.showSources.monthlyLeasePaymentSource": "(Coût d'amortissement mensuel + Coût de financement mensuel) * (1 + Taxe de vente)",
    "ev.showSources.firstLeasePayment": "Premier paiement de leasing",
    "ev.showSources.firstLeasePaymentSource": "Acompte + Paiement mensuel du bail",
    "ev.showSources.nonCapLeaseIncentives": "Subventions non capitalisées",
    "ev.showSources.nonCapLeaseIncentivesSource": "Subventions non prises en compte dans le coût de location capitalisé",
    "ev.showSources.leaseForumla": "Premier paiement de location + (mois de propriété - 1) * (paiement de location mensuel) - Incitatifs à la location non capitalisés",
    "ev.showSources.EPAEfficiency": "Évaluation d'efficacité EPA",
    "ev.showSources.lifetimeMiles": "Kilomètres an total",
    "ev.showSources.totalElectricityCost": "Coût total d'électricité",
    "ev.showSources.gasolineCost": "Coût de l'essence",
    "ev.showSources.totalGasolineCost": "Coût total de l'essence",
    "ev.showSources.maintenancePerMile": "Entretien au kilomètre pour voitures à essence",
    "ev.showSources.maintenancePerMileSource": "Extrapolation du coût d'entretien moyen de 0,06 $ / kilomètre, calculé par le PDSF du véhicule par rapport à un PDSF moyen de 37 000 $",
    "ev.showSources.maintenanceCostReduction": "Réduction des coûts de maintenance des véhicules électriques",
    "ev.showSources.maintenanceCostReductionSource": "{site Internet}",
    "ev.showSources.maintenancePerMileElectricity": "Entretien par kilomètre pour voiture électrique",
    "ev.showSources.maintenancePerMileElectricitySource": "Entretien par kilomètre pour voiture à essence * Facteur de réduction de l'entretien des véhicules électriques",
    "ev.showSources.blendedMaintenanceCostPerMile": "Coût d'entretien combiné par kilomètre",
    "ev.showSources.blendedMaintenanceCostPerMileSource": "Part de la conduite électrique appliquée à l'entretien par kilomètre pour l'essence et l'électricité",
    "ev.showSources.totalMaintenanceCost": "Coût total de maintenance",
    "ev.showSources.totalMaintenanceCostSource": "Coût d'entretien par kilomètre * Kilomètres",
    "ev.showSources.averageInsurancePerYear": "Assurance moyenne par an",
    "ev.showSources.insurancePerYear": "Assurance par an",
    "ev.showSources.insurancePerYearSource": "Extrapolation du coût d'assurance moyen en {CA}, basé sur le PDSF du véhicule par rapport à un PDSF moyen de 37 000 $",
    "ev.showSources.yearsOfOwnership": "Années de propriété",
    "ev.showSources.totalInsuranceCost": "Coût total de l'assurance",
    "ev.showSources.totalInsuranceCostSource": "Coût de l'assurance par an * Années de propriété",
    "here": "ici",
    "sources": "Sources",
    "yes": "Oui",
    "no": "Non",
    "ev.cardetails.calc.nationalAverageElectricityEmissions": "Émissions nationales moyennes d'électricité",
    "ev.cardetails.calc.emissionPerTreePerYear": "22 kg de CO2 par arbre et par an",
    "n/a": "S.O.",
    "incentives.clunkerNone": "Certaines incitations vous compensent pour la retraite d'un véhicule existant, également connu sous le nom de clunker.",

    "title.home": "PlugNDrive Buyer's Guide",
    "title.vehicles": "Véhicules électriques | PlugNDrive Buyer's Guide",
    "title.compare": "Comparison de véhicles | PlugNDrive Buyer's Guide",
    "title.incentives": "Subventions | PlugNDrive Buyer's Guide",
    "title.chargingStations": "Bornes de recharge | PlugNDrive Buyer's Guide"
}
export default { ...FR, ...FR_CUSTOM }
