import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

import checkmark from "../../assets/images/circledCheckmark.svg";
import { FormatAsDollars, FormatCarName } from "../../../utils/Helpers/Format";
import SelectCurrentRate from "../InputComponents/SelectCurrentRate/SelectCurrentRate";
import SelectChargingPattern from "../InputComponents/SelectChargingPattern/SelectChargingPattern";
import SelectRateComparisonVehicle from "../InputComponents/SelectRateComparisonVehicle/SelectRateComparisonVehicle";
import SlideCurrentMonthlyBill from "../InputComponents/SlideCurrentMonthlyBill/SlideCurrentMonthlyBill";
import SlideMilesDrivenAnnually from "../../../components/InputComponents/SlideMilesDrivenAnnually/SlideMilesDrivenAnnually";
import chargingPatterns from "../../data/chargingPatterns";
import ButtonGroupRates from "../InputComponents/ButtonGroupRates/ButtonGroupRates";
import ButtonGroupChargingPatterns from "../InputComponents/ButtonGroupChargingPatterns/ButtonGroupChargingPatterns";
import { FormattedMessage } from "react-intl";
import "./RateOptionsWizard.scss";
import UserPrefsContext from "../../../context/UserPrefs/UserPrefsContext";
import GaTracker from "../../../utils/GaTracker/GaTracker";

const RateOptionsWizard = ({
  electricVehicles,
  rates,
  rateTotals,
  selectedVehicle,
  utilityName,
  mostCommonRateName,
  typicalDriverName = "person",
  bestSaving
}) => {
  // We don't want react-snap to pre-render this page with the modal open because
  // if it does, the pre-rendered version will be non-dismissable. This is a bit
  // hacky, but should set the initial value for isOpen to false in the CI environment
  // in which react-snap runs
  const [isOpen, setIsOpen] = useState(
    !window.location.host.includes("localhost")
  );
  const userPrefs = useContext(UserPrefsContext);
  const [step, setStep] = useState(1);

  const currentRateIdIsTOU5 = userPrefs.get("currentRateId") === "EV-TOU-5";;
  const savings = bestSaving?.selectedRate?.savings;
  const isLowest = bestSaving?.isLowest;

  const highlightText = (
    <span className="highlight">
      ~{savings && FormatAsDollars(savings.toFixed(0))}
    </span>
  );

  let bannerText;

  if (currentRateIdIsTOU5) {
    bannerText = isLowest ? (
      <p className="lead">
        <FormattedMessage
                     id="rateComparison.youAreAlreadySaving"
                     defaultMessage="You are already saving up to {highlightText} if you have an EV and use the {highlight} rate plan."
                     description="Footer Copyright"
                     values={{
                            highlightText: highlightText,
                            highlight: <span className="highlight">EV-TOU-5</span>
                     }}
              />
      </p>
    ) : (
      <p className="lead">
         <FormattedMessage
                     id="rateComparison.youAreSaving"
                     defaultMessage="You are saving up to {highlightText} if you have an EV and use the {highlight} rate plan."
                     description="Footer Copyright"
                     values={{
                            highlightText: highlightText,
                            highlight: <span className="highlight">EV-TOU-5</span>
                     }}
              />
      </p>
    );
  } else {
    bannerText = isLowest ? (
      <p className="lead">
        <FormattedMessage
                     id="rateComparison.youAreAlreadySavingVehicle"
                     defaultMessage="You are already saving up to {highlightText} per year on {highlight} with a {selectedVehicle}."
                     description="Footer Copyright"
                     values={{
                            highlightText: highlightText,
                            highlight: bestSaving?.selectedRate?.label,
                            selectedVehicle: FormatCarName(selectedVehicle)
                     }}
              />
      </p>
    ) : (
      <p className="lead">
         <FormattedMessage
                     id="rateComparison.youAreSavingVehicle"
                     defaultMessage="You can save {highlightText} per year on {highlight} with a {selectedVehicle}."
                     description="Footer Copyright"
                     values={{
                            highlightText: highlightText,
                            highlight: bestSaving?.selectedRate?.label,
                            selectedVehicle: FormatCarName(selectedVehicle)
                     }}
              />
      </p>
    );
  }


  return (
    <Modal
      isOpen={isOpen}
      toggle={(e) => setIsOpen(false)}
      size="lg"
      className="RateOptionsWizard"
    >
      <ModalHeader toggle={(e) => setIsOpen(false)} className="border-0">
        <small className="text-muted"><FormattedMessage
                            id="modal2"
                            defaultMessage="Step"
                            description="modal2"
                     /> {step} <FormattedMessage
                     id="modal3"
                     defaultMessage="of"
                     description="modal3"
              /> 6</small>
      </ModalHeader>
      <ModalBody className="text-center">
        <div className="row">
          {step === 1 && (
            <div className="col-sm-12 px-5">
              <p className="h1 mb-5">
                     <FormattedMessage
                            id="modal1"
                            defaultMessage="What is the average electric charge on your monthly bill?"
                            description="modal1"
                     />
              </p>
              <SlideCurrentMonthlyBill id="monthly-bill-slider-wizard" />
            </div>
          )}

          {step === 2 && (
            <div className="col-sm-12 px-5">
              <p className="h1"><FormattedMessage
                            id="modal5"
                            defaultMessage="Which EV do you already own or want to buy/lease?"
                            description="modal5"
                     /></p>
              <p className="text-muted mb-4 tip">
                     <FormattedMessage
                            id="modal6"
                            defaultMessage="Tip: You can adjust these filters after you view your results."
                            description="modal6"
                     />
              </p>
              <div className="form-group-half">
                <SelectRateComparisonVehicle
                  electricVehicles={electricVehicles}
                  selectedVehicle={selectedVehicle}
                />
              </div>
              <p className="text-muted tip" style={{textAlign: 'left'}}>
                     <FormattedMessage
                            id="modal7"
                            defaultMessage="Choose your current or preferred EV to view potential savings on different pricing plans. Vehicle selection affects potential electric bill savings due to differences in electric performance and efficiency across models."
                            description="modal7"
                     />
              </p>
            </div>
          )}

          {step === 3 && (
            <div className="col-sm-12 px-5">
              <p className="h1">
                     <FormattedMessage
                            id="modal9"
                            defaultMessage="On average, how many miles per year do you drive?"
                            description="modal9"
                     />
              </p>
              <p className="text-muted mb-4 tip">
                     <FormattedMessage
                            id="modal10"
                            defaultMessage="Tip: A typical driver logs about 12,000 miles per year."
                            description="modal10"
                     />
              </p>
              <div>
                <SlideMilesDrivenAnnually addAverage noTooltip />
              </div>
            </div>
          )}

          {step === 4 && (
            <div className="col-sm-12">
              <p className="h1"><FormattedMessage
                            id="modal11"
                            defaultMessage="Which charging timeframe suits you best?"
                            description="modal11"
                     /></p>
              <p className="text-muted mb-4 tip">
                     <FormattedMessage
                            id="modal12"
                            defaultMessage="Tip: Charging at night (from 12-6 a.m.) is most cost-effective. You can program your EV to charge during the timeframe that suits you best."
                            description="modal12"
                     />
              </p>
              <div className="d-none d-md-block">
                <ButtonGroupChargingPatterns
                  chargingPatterns={chargingPatterns}
                />
              </div>
              <div className="d-block d-md-none">
                <SelectChargingPattern chargingPatterns={chargingPatterns} />
              </div>
            </div>
          )}

          {step === 5 && (
            <div className="col-sm-12">
              <p className="h1"><FormattedMessage
                            id="modal14"
                            defaultMessage="What is your current pricing plan?"
                            description="modal14"
                     /></p>
              {mostCommonRateName && (
                <p className="text-muted mb-4  tip">
                     <FormattedMessage
                            id="modal15"
                            defaultMessage="Tip: Most customers are on TOU-DR1."
                            description="modal15"
                     />
                </p>
              )}
              <div className="d-none d-md-block">
                <ButtonGroupRates rates={rates} />
              </div>
              <div className="d-block d-md-none">
                <SelectCurrentRate
                  rates={Object.keys(rates).map((key) => rates[key])}
                />
              </div>

              <p className="text-muted tip" style={{textAlign: 'left'}}>
                     <FormattedMessage
                            id="modal16"
                            defaultMessage="Note: For customers on non-EV pricing plans (including DR, TOU-DR1 and DR-SES), we assume you currently drive a gas vehicle with a type, make and price similar to the EV selected  in Step 2 and show the savings from switching to this EV. For customers on the EV-TOU-5 pricing plan, we assume you already own the EV selected and show your current savings relative to non-EV pricing plans."
                            description="modal16"
                     />
              </p>
            </div>
          )}

          {step === 6 && (
            <div className="col-sm-12 px-5">
              <img
                className="mb-5"
                src={checkmark}
                alt="green checkmark signifying completeness"
              />
              <div>
                {bannerText}
              </div>
            </div>
          )}
        </div>
      </ModalBody>
      <ModalFooter className="justify-content-center mb-3 border-0">
        {step > 1 && (
          <Button
            className="btn btn-secondary"
            onClick={() => setStep(step - 1)}
          >
               <FormattedMessage
                     id="modal8"
                     defaultMessage="PREVIOUS"
                     description="modal8"
              />
          </Button>
        )}
        <button
          type="button"
          className="btn btn-ae"
          onClick={() => {
            if (step === 6) {
              setIsOpen(false);
              return;
            }
            setStep(step + 1);

            GaTracker.trackEvent({
              category: "Rate Advisor",
              action: "Clicked View Savings ",
              label: "Onboarding Wizard",
            });
          }}
        >
          {step === 6 ? <FormattedMessage
                            id="viewSavings"
                            defaultMessage="VIEW SAVINGS"
                            description="View Savings"
                     /> : <FormattedMessage
                            id="modal4"
                            defaultMessage="Next"
                            description="modal4"
                     />}
        </button>
      </ModalFooter>
    </Modal>
  );
};

export default RateOptionsWizard;

RateOptionsWizard.propTypes = {
  electricVehicles: PropTypes.array,
  totalBillSavings: PropTypes.number,
  lowestRate: PropTypes.string,
  vehicleName: PropTypes.string,
};
