import {
  EMISSIONS_PER_TREE_IN_LBS_OF_CO2,
  ELECTRICITY_EMISSIONS_IN_LBS_PER_MWH,
  GASOLINE_EMISSIONS_IN_LBS_PER_GAL,
  GASOLINE_EMISSIONS_IN_KG_PER_LITER,
  ELECTRICITY_EMISSIONS_IN_KG_PER_MWH,
  EMISSIONS_PER_TREE_IN_KG_OF_CO2

} from "../../../client_customizations/data/assumptions/ASSUMPTIONS"
import isBEV from "../../../functions/vehicle/isBEV";
import isPHEV from "../../../functions/vehicle/isPHEV";

const calcEmissions = {
  electricEmissions: function(consumptionRate, distance) {
    return process.env.REACT_APP_METRIC_SYSTEM ? 
    ELECTRICITY_EMISSIONS_IN_KG_PER_MWH.value * consumptionRate/1000/100 * distance : 
    (ELECTRICITY_EMISSIONS_IN_LBS_PER_MWH.value * consumptionRate * distance /
      1000 /
      100
    );
  },

  gasolineEmissions: function(consumptionRate, distance) {
    return process.env.REACT_APP_METRIC_SYSTEM ?
    (GASOLINE_EMISSIONS_IN_KG_PER_LITER.value * distance) * consumptionRate / 100 : 
    (GASOLINE_EMISSIONS_IN_LBS_PER_GAL.value * distance) / consumptionRate;
  },

  emissions: function(vehicle, milesDrivenAnnually, electricMilesPortionForPhev) {
    if (!vehicle) return 0;
    let emissions = 0;

    if (!isBEV(vehicle) && !isPHEV(vehicle)) {
      emissions = this.gasolineEmissions(
        vehicle.fossil_fuel_efficiency,
        milesDrivenAnnually
      );
    }

    if (isBEV(vehicle)) {
      emissions = this.electricEmissions(
        vehicle.electric_efficiency,
        milesDrivenAnnually
      );
    }

    if (isPHEV(vehicle)) {
      emissions =
        this.electricEmissions(
          vehicle.electric_efficiency,
          milesDrivenAnnually * (electricMilesPortionForPhev / 100)
        ) +
        this.gasolineEmissions(
          vehicle.fossil_fuel_efficiency,
          milesDrivenAnnually * (1 - electricMilesPortionForPhev / 100)
        );
    }
    return emissions;
  },

  emissionReduction: function(
    vehicle,
    milesDrivenAnnually,
    electricMilesPortionForPhev
  ) {
    return (
      this.emissions(
        vehicle.equivalent_gas_vehicle,
        milesDrivenAnnually,
        electricMilesPortionForPhev
      ) - this.emissions(vehicle, milesDrivenAnnually, electricMilesPortionForPhev)
    );
  },

  treesPlanted: function(
    vehicle,
    milesDrivenAnnually,
    electricMilesPortionForPhev
  ) {
    return (
      this.emissionReduction(
        vehicle,
        milesDrivenAnnually,
        electricMilesPortionForPhev
      ) / (process.env.REACT_APP_METRIC_SYSTEM ? EMISSIONS_PER_TREE_IN_KG_OF_CO2.value : EMISSIONS_PER_TREE_IN_LBS_OF_CO2.value)
    );
  },

  fuelUsed: function (
    vehicle,
    milesDrivenAnnually,
    electricMilesPortionForPhev
  ) {
    if (!vehicle) return 0;

    // All-electric --> no gasoline used
    if (isBEV(vehicle)) {
      return 0;
    }

    // Not electric --> all miles on gasoline
    if (!isBEV(vehicle) && !isPHEV(vehicle)) {
      return process.env.REACT_APP_METRIC_SYSTEM ? 
      milesDrivenAnnually * vehicle.fossil_fuel_efficiency / 100 :
      milesDrivenAnnually / vehicle.fossil_fuel_efficiency;
    }

    return (
      process.env.REACT_APP_METRIC_SYSTEM ? 
      ((1 - electricMilesPortionForPhev / 100) * milesDrivenAnnually) *
      vehicle.fossil_fuel_efficiency / 100
      :
      ((1 - electricMilesPortionForPhev / 100) * milesDrivenAnnually) /
      vehicle.fossil_fuel_efficiency
    );
  },

  fuelSaved: function(
    vehicle,
    milesDrivenAnnually,
    electricMilesPortionForPhev
  ) {
    return (
      this.fuelUsed(
        vehicle.equivalent_gas_vehicle,
        milesDrivenAnnually,
        electricMilesPortionForPhev
      ) -
      this.fuelUsed(
        vehicle,
        milesDrivenAnnually,
        electricMilesPortionForPhev
      )
    );
  }
};

export default calcEmissions;
