import React, { useState } from 'react';
import './InputPanel.scss';
import { FormattedMessage } from "react-intl";

const InputPanel = ({
  children,
  title,
  isCollapsed: externalIsCollapsed,
  onClickCollapse,
  defaultIsCollapsed,
  className,
}) => {
  const isCollapsible = externalIsCollapsed !== undefined || defaultIsCollapsed !== undefined;
  const [localIsCollapsed, setLocalIsCollapsed] = useState(defaultIsCollapsed);

  const isCurrentlyCollapsed = () => {
    if (!isCollapsible) return false;
    if (externalIsCollapsed !== undefined) return externalIsCollapsed;
    return localIsCollapsed;
  }

  return (
    <div className={['InputPanel', className].filter(c => c).join(' ')}>
      <div className="heading">
        <div className="title"><FormattedMessage
              id="basicFilters"
              defaultMessage="Basic Filters"
              description="Basic Filters"
            />
        </div>
        {isCollapsible && (
          <div className="collapser">
            <button
              type="button"
              className="btn btn-link"
              onClick={() => setLocalIsCollapsed(current => !current)}
            >
              {isCurrentlyCollapsed() ? "+" : <>&ndash;</>}
            </button>
          </div>
        )}
      </div>
      {!isCurrentlyCollapsed() && (
        children
      )}
    </div>
  );
};

export default InputPanel;
