import React from "react";
import PropTypes from "prop-types";

import IncentivePreferences from "../IncentivePreferences/IncentivePreferences";

import SlideMilesDrivenDaily from "../../../components/InputComponents/SlideMilesDrivenDaily/SlideMilesDrivenDaily";
import SlideMaxBudget from "../../../components/InputComponents/SlideMaxBudget/SlideMaxBudget";
import SlideMinSeats from "../../../components/InputComponents/SlideMinSeats/SlideMinSeats";
import SelectChargerLevel from "../../../components/InputComponents/SelectChargerLevel/SelectChargerLevel";
import { FormattedMessage, useIntl } from "react-intl";
import EVFilterControls from "../EVFilterControls/EVFilterControls";

const MatchScoreOptions = ({ electricVehicles, location }) => {
  const intl = useIntl();

  return (
    <>
      <div className="input-well MatchScoreOptions">
        <p className="h2 bottom-margin light">
          <FormattedMessage
            id="evs.matchScoreOptions"
            defaultMessage="Refine Match Score"
            description="Refine Match Score"
          />
        </p>
        <SlideMaxBudget />
        <IncentivePreferences
          electricVehicles={electricVehicles}
          location={location}
          titleText={
            intl.formatMessage
              ? intl.formatMessage({
                  id: "personalizeIncentives",
                  defaultMessage: "Personalize Incentives",
                })
              : "Personalize Incentives"
          }
          btnText={
            intl.formatMessage
              ? intl.formatMessage({
                  id: "personalizeIncentives",
                  defaultMessage: "Personalize Incentives",
                })
              : "Personalize Incentives"
          }
          btnSubtle
        />

        <SlideMilesDrivenDaily />

        <div style={{ height: "1rem" }} />

        <EVFilterControls vehicles={electricVehicles} />
        <SlideMinSeats />
        <SelectChargerLevel />
        <br />
        <br />
        <p className="legal-disclaimer">
          <FormattedMessage
            id="evs.disclaimer"
            defaultMessage="Vehicles displayed may not reflect actual availability. {company} does not endorse or recommend any specific vehicle or car manufacturer."
            description="Vehicles Disclaimer"
            values={{
              company: process.env.REACT_APP_FULL_COMPANY_NAME,
            }}
          />
        </p>
      </div>
      <p className="selected-subText">
        <FormattedMessage
          id="evs.thirdParty"
          defaultMessage="*This website contains links to third-part external websites. SDGE does not endorse or accept any liability for  content or products sold on any third-party websites. Please be aware that this vehicle may not be available in your area.  Contact your local dealer to ensure availability. "
          description="Vehicles Disclaimer"
          values={{
            company: process.env.REACT_APP_FULL_COMPANY_NAME,
          }}
        />
      </p>
    </>
  );
};

export default MatchScoreOptions;

MatchScoreOptions.propTypes = {
  electricVehicles: PropTypes.array,
};
