import React, { useContext } from "react";
import Select from "../../../../components/shared/InputElements/Select";
import UserPrefsContext from "../../../../context/UserPrefs/UserPrefsContext";
import { useIntl } from 'react-intl';

const SelectChargingPattern = ({ chargingPatterns }) => {
  const intl = useIntl();
  const userPrefs = useContext(UserPrefsContext);
  return (
    <Select
      id="rate-comparison-charging-pattern"
      value={userPrefs.get("chargingPatternId")}
      optionNames={chargingPatterns.map((p) => p.title)}
      optionValues={chargingPatterns.map((p) => p.id)}
      handler={(e) => userPrefs.set({ chargingPatternId: e.target.value })}
      label={intl.formatMessage
       ? intl.formatMessage({ id: "prefferedEvChargingTimeframe", defaultMessage: "PREFERRED EV CHARGING TIMEFRAME" })
       : "Yes"}
      tooltip={
       intl.formatMessage({ id: "prefferedEvChargingTimeframe_tooltip", defaultMessage: "Charging at night (12-6 a.m.) is most cost-effective. You can program your EV to charge during the timeframe that suits you best." })
      }
      isLarge
      isSpecial
    />
  );
};

export default SelectChargingPattern;
