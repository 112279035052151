import React from "react";
import PropTypes from "prop-types";
import { TabContent, TabPane } from "reactstrap";

import { CHARGERS_CATALOG_TAB_ID } from "../../constants/mapTabs";
import DealerMap from "../LocationMap/DealerMap";

import "./DealerTabbedMaps.scss";

const TabbedMaps = ({ dealerLocations, activeTabId, userLocation, zipcode }) => {

  return (
    <div className="DealerTabbedMaps">
      <div className="container">
        <div className="titleContainer">
          <p className="dealerTitle">Find a Dealer</p>
          <p className="dealerSubtitle">
            Discover electric vehicle dealers in your area, and click on each
            one to see location and contact information.
          </p>
        </div>
        <div className="tabbed-maps-panel">
          <TabContent activeTab={CHARGERS_CATALOG_TAB_ID}>
            <TabPane tabId={CHARGERS_CATALOG_TAB_ID}>
              <DealerMap
                dealerLocations={dealerLocations}
                activeTabId={activeTabId}
                userLocation={userLocation}
                zipcode={zipcode}
                isVisible={activeTabId === CHARGERS_CATALOG_TAB_ID}
              />
            </TabPane>
          </TabContent>
        </div>
      </div>
    </div>
  );
};

TabbedMaps.propTypes = {
  chargingStationsFilterFn: PropTypes.func,
  electricVehicles: PropTypes.array,
  oems: PropTypes.array,
  activeTabId: PropTypes.string,
  toggle: PropTypes.func,
  canIgnoreLowPowerStations: PropTypes.bool,
  dealerLocations: PropTypes.array,
};

export default TabbedMaps;
