import React, { useContext, useMemo } from "react";
import PropTypes from "prop-types";
import IncentiveCard from "./../IncentiveCard/IncentiveCard";
import LoadingSpinner from "./../LoadingSpinner/LoadingSpinner";
import UserPrefsContext from "../../context/UserPrefs/UserPrefsContext";

const IncentiveCatalog = ({ incentives, titleText }) => {
     const userPrefs = useContext(UserPrefsContext);
     let sortOptionValue = [userPrefs.get("incentiveSortType"), userPrefs.get("incentiveSortDirection")].join("-");

     const renderCards = useMemo(() => {
          switch (sortOptionValue) {
               case "alphabetical-asc":
                    incentives.sort((a, b) => a.name.localeCompare(b.name));
                    break;

               case "alphabetical-desc":
                    incentives.sort((a, b) => -1 * a.name.localeCompare(b.name));
                    break;

               case "price-desc":
                    incentives.sort((a, b) => a.typical_amount.localeCompare(b.typical_amount));
                    break;

               default:
                    incentives.sort((a, b) => a.typical_amount.localeCompare(b.typical_amount));
                    break;
          }

          return incentives.map((incentive, index) => {
               return <IncentiveCard incentive={incentive} key={index} />;
          });
     }, [incentives, sortOptionValue]);

     if (!incentives) return <LoadingSpinner />;

     return (
          <div className="row mt-3 mb-3">
               <div className="col-sm-12">
                    {titleText && <p className="h3 text-left">{titleText}</p>}
                    <div className="d-flex justify-content-start flex-wrap">{renderCards}</div>
               </div>
          </div>
     );
};

export default IncentiveCatalog;

IncentiveCatalog.propTypes = {
     incentives: PropTypes.array,
     titleText: PropTypes.element,
     includeTotal: PropTypes.bool,
};
