import { times } from "lodash";
import {
  FROM_12AM_TO_6AM,
  FROM_6AM_TO_4PM,
  FROM_4PM_TO_9PM,
  FROM_9PM_TO_12AM,
  ALL_HOURS,
} from "../constants/";
import allHoursImage from "./chargingPatterns/allhours.png";

import from12amto6am from "./chargingPatterns/12am-6am.png";
import from6amto4pm from "./chargingPatterns/6am-4pm.png";
import from4pmto9pm from "./chargingPatterns/4pm-9pm.png";
import from9pmto12am from "./chargingPatterns/9pm-12am.png";

const DST = {
  2021: {
    startHour: 1610,
    endHour: 7322,
  },
};

const dstAdjustedHour = (hour, year) => {
  const { startHour, endHour } = DST[year];
  const isWithinDST = hour >= startHour && hour <= endHour;
  return isWithinDST ? (hour + 1) % 24 : hour % 24;
};

// NOTE: this is not a complete list of leap years, but is sufficient for the data
// provided
const isLeapYear = (year) => [2016, 2020].includes(year);
const hoursInYear = (year) => (isLeapYear(year) ? 8784 : 8760);

const chargingPatterns = [
  {
    id: FROM_12AM_TO_6AM,
    title: "12-6 a.m.",
    value: "$",
    loadProfile: {
      data: (year) =>
        times(hoursInYear(year), (i) => {
          const hour = dstAdjustedHour(i, year);
          return hour >= 0 && hour < 6 ? 1.1 : 0;
        }),
    },
    image: from12amto6am,
    description: "Recommended!",
  },
  {
    id: FROM_6AM_TO_4PM,
    title: "6 a.m. to 4 p.m.",
    value: "$$",
    loadProfile: {
      data: (year) =>
        times(hoursInYear(year), (i) => {
          const hour = dstAdjustedHour(i, year);
          return hour >= 6 && hour < 16 ? 1.1 : 0;
        }),
    },
    image: from6amto4pm,
  },
  {
    id: FROM_4PM_TO_9PM,
    title: "4-9 p.m.",
    value: "$$",
    loadProfile: {
      data: (year) =>
        times(hoursInYear(year), (i) => {
          const hour = dstAdjustedHour(i, year);
          return hour >= 16 && hour < 21 ? 1.1 : 0;
        }),
    },
    image: from4pmto9pm,
  },
  {
    id: FROM_9PM_TO_12AM,
    title: "9 p.m. to 12 a.m.",
    value: "$$",
    loadProfile: {
      data: (year) =>
        times(hoursInYear(year), (i) => {
          const hour = dstAdjustedHour(i, year);
          return hour >= 21 && hour < 24 ? 1.1 : 0;
        }),
    },
    image: from9pmto12am,
  },
  {
    id: ALL_HOURS,
    title: "All Hours",
    value: "$?",
    loadProfile: {
      data: () => times(hoursInYear(2021), (_) => 1.1),
    },
    image: allHoursImage,
    description: "Unpredictable!",
  },
];

export default chargingPatterns;
