import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";

const HomepageBigPromise = ({ homePageBannerImage }) => {
  return (
    <section className="p-0">
      <div className="big-promise" style={{ position: "relative" }}>
        <div
          style={{
            backgroundImage: "url(" + homePageBannerImage + ")",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center center",
            WebkitBackgroundSize: "cover",
            MozBackgroundSize: "cover",
            OBackgroundSize: "cover",
            backgroundSize: "cover",
            padding: 0,
            textAlign: "center",
            height: "475px",
            boxSizing: "border-box",
          }}
        />
        <div className="container big-promise-text">
          <div>
            <h2> 
              <FormattedMessage
                id="header.promise.title"
                defaultMessage="Get the most out of your EV."
                description="Get the most out of your EV."
              />
            </h2>
            <p>
              <FormattedMessage
                id="header.promise.subtitle"
                defaultMessage="Estimate and compare EV costs, savings, incentives and more."
                description="Estimate and compare EV costs, savings, incentives and more."
              />
            </p>
            <Link to="/incentives">
              <button>
                <FormattedMessage
                  id="header.promise.cta"
                  defaultMessage="Start with Incentives"
                  description="Start with Incentives"
                />
              </button>
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HomepageBigPromise;

HomepageBigPromise.propTypes = {
  homePageBannerImage: PropTypes.string,
};
