import React, { useContext } from "react";
import PropTypes from "prop-types";
import UserPrefsContext from "../../context/UserPrefs/UserPrefsContext";
import { Link } from "react-router-dom";

import EVCard from "./../EVCard/EVCard";
import sortEVs from "./../../functions/vehicle/Sort/sortEVs";
import filterEVs from "./../../functions/vehicle/Filter/filterEVs";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";
import getLocalVehicles from "../../functions/vehicle/getLocalVehicles";

const EVCatalog = ({
  vehicles,
  hasIncentivesAndMatchScore,
  hasEvDetails,
  hasLinktoEv
}) => {
  const userPrefs = useContext(UserPrefsContext);

  if (!vehicles) return <LoadingSpinner />;
  const electricVehicles = userPrefs.get("locallyAvailableVehicleFilter")
    ? getLocalVehicles(vehicles)
    : vehicles;

  const sortedEvs = sortEVs(
    electricVehicles,
    userPrefs.get("vehicleSortType"),
    userPrefs.get("vehicleSortDirection"),
    userPrefs
  );

  const filteredAndSortedElectricVehicles = filterEVs(sortedEvs, {
    vehicleFormFactorFilters: userPrefs.get("vehicleFormFactorFilters"),
    vehicleFuelTypeFilters: userPrefs.get("vehicleFuelTypeFilters"),
    vehicleMakeFilter: userPrefs.get("vehicleMakeFilter"),
  });

  if (!filteredAndSortedElectricVehicles.length) {
    return (
      <div className="text-center">
        <p className="h4">No vehicles found</p>
      </div>
    );
  }

  const renderCards = filteredAndSortedElectricVehicles.map((ev, index) => {
    return (
      <Link
        to={`${"/vehicles/" + ev.handle}`}
        className="evc-card EVCard"
        key={index}
      >
        <EVCard
          ev={ev}
          hasEvDetails={hasEvDetails}
          hasIncentivesAndMatchScore={hasIncentivesAndMatchScore}
          hasLinktoEv={hasLinktoEv}
          hasLocallyAvailableHidden={true}
        />
      </Link>
    );
  });

  return <div className="render-cards-container">{renderCards}</div>;
};

export default EVCatalog;

EVCatalog.propTypes = {
  vehicles: PropTypes.array,
  hasEvDetails: PropTypes.bool,
  hasIncentivesAndMatchScore: PropTypes.bool,
  hasLinktoEv: PropTypes.bool
};
