const RESIDUALVALUES = 
    {
        "Ford_Mustang_Mach_E_Premium_BEV_2021" : .45,
        "Ford_Mustang_Mach_E_Select_BEV_2021" : .45, 
        "Ford_Mustang_Mach_E_First_Edition_BEV_2021" : .45, 
        "Ford_Mustang_Mach_E_California_Rt_1_BEV_2021" : .45,
        "Ford_Mustang_Mach_E_GT_BEV_2021": .339,
        "Kia_Niro__PHEV_2021" : .661,
        "Kia_Niro_EV__BEV_2020" : .720,
        "Tesla_Model_S_Plaid_BEV_2021" : .49,
        "Tesla_Model_3_Standard_Range_Plus__BEV_2021" : .59,
        "Tesla_Model_3_Performance_AWD_BEV_2021" : .53,
        "Tesla_Model_X_Long_Range_BEV_2021" : .59,
        "Tesla_Model_3_Long_Range_AWD_BEV_2021" : .59,
        "Tesla_Model_Y_Long_Range_AWD_BEV_2021" : .59,
        "Tesla_Model_Y_Performance_BEV_2021" : .53,
        "Tesla_Model_S_Long_Range_Plus_BEV_2021" : .49,
        "Tesla_Model_X_Plaid_BEV_2021" : .59,

        "Chevrolet" : .64,
        "Hyundai" : .788,
        "Volkswagen" : .59,
        "Polestar" : .59,
        "Nissan" : .4,
        "Toyota" : .4,
        "Chrysler" : .364,
    };
export {
    RESIDUALVALUES
};
