import React, { useContext } from "react";
import PropTypes from "prop-types";
import UserPrefsContext from "../../../context/UserPrefs/UserPrefsContext";

import Range from "../../shared/InputElements/Range";
import { useIntl } from 'react-intl';

const SlideMonthsOfOwnership = ({
  id = "years-of-ownership-slider",
  ...rest
}) => {
  const intl = useIntl()
  const userPrefs = useContext(UserPrefsContext);
  const label = intl.formatMessage({ id: "ev.yearsOwnership", defaultMessage: "Years of Ownership/Lease"})
  const description = val =>
  val >= 12 ? val / 12 + intl.formatMessage({ id: "graph.years", defaultMessage: " years"}) : val / 12 + intl.formatMessage({ id: "graph.year", defaultMessage: " year"});

  return (
    <Range
      id={id}
      value={userPrefs.get("monthsOfOwnership")}
      label={label}
      rangeMin={12}
      rangeMax={120}
      rangeStep={12}
      description={description}
      handler={e => userPrefs.set({ monthsOfOwnership: e.target.value })}
      {...rest}
    />
  );
};

export default SlideMonthsOfOwnership;

SlideMonthsOfOwnership.propTypes = {
  id: PropTypes.string,
  description: PropTypes.func,
  label: PropTypes.string
};
